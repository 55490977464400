<template>
  <div>
    <navbar
      position="fixed"
      class="navBarDash"
      menu-classes="ml-auto"
    >
    <div class="d-flex">
      <template>
      <router-link
        class="nav-item m-auto"
        to="/"
      >
        <img
          :src="$getImgUrlSite('LogoNoSpinOff.png')"
          style="width: 100%; max-width: 140px;"
          alt="LogoNCM"
          class="logo-ncm"
        >
        <!-- <img
          :src="$getImgUrlSite('LogoNoSpinOff-negative.png')"
          height="34;"
          width="200"
          alt="LogoNCM"
        > -->
      </router-link>
      </template>
      <!-- <template>
        <div :class="{'wallet-container': true, 'highlight-expiry': isNearExpiry}" style="margin-left: 50px; padding: 5px;">
          {{ $t('MemberUntil') }} ( {{ date_expired }} )
        </div>
      </template> -->
      <template>
        <b-button v-if="isNearExpiry" class="btn ncm-btn btn-danger text-white ml-2"><p>{{$t('Renewal')}}</p></b-button>
        <!-- <b-button class="btn ncm-btn btn-danger text-white ml-2" @click="openModalRenewal"><p>{{$t('Renewal')}}</p></b-button> -->
      </template>
    </div>

    <!-- <b-breadcrumb class="responsive-nav responsive-nav-2">
        <b-breadcrumb-item to="/home-user">
          <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
          Home
        </b-breadcrumb-item>
        <b-breadcrumb-item :key="index" v-for="(route, index) of routeArray" :to="route.route" :active="route.active">{{route.title}}</b-breadcrumb-item>
      </b-breadcrumb> -->
    <template slot="navbar-menu">
      <div :class="{'wallet-container': true, 'highlight-expiry': isNearExpiry}" id="membershipDate" style="margin-left: 50px; padding: 5px; max-height: 40px;">
          {{ $t('MemberUntil') }} ( {{ date_expired }} )
        </div>
      <div class="wallet-container" id="tokensValue" style="margin-right: 15px; padding: 5px;">
        <b-icon icon="coin" class="mr-2"></b-icon> {{ tokensValue }}
      </div>
      <div class="country-selector"><LocaleSwitcher /></div>
        <template>
          <!-- <div style="margin-top: 15px; margin-left: 15px;">
            <b-icon icon="coin"></b-icon>{{ refreshTokens }}
            
          </div> -->
            <!-- <li class="nav-item">
            <a
              class="nav-link"
            >
            <p class="nav-home"><b-icon icon="people"/> USERS MANAGEMENT</p>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
            >
            <p class="nav-home"><b-icon icon="kanban"/> PROJECTS MANAGEMENT</p>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
            >
            <p class="nav-home"><b-icon icon="file-earmark-medical"/> REPORTS MANAGEMENT</p>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
            >
            <p class="nav-home"><b-icon icon="wallet2"/> WALLET</p>
            </a>
          </li>  -->
          <!-- <div class="dropdown">
            <div @click="toggleDropdown">
              <img :src="userImage" alt="User" @click="toggleDropdown" style="width: 33px; ">  {{ $store.state.auth.user.data.email }}
            </div>
            <ul v-show="isOpen" @click="closeDropdown">
              <li class="dropdown-item" style="color: white !important; cursor:pointer;"><i class="now-ui-icons ui-1_settings-gear-63" /> Settings</li>
              <li class="dropdown-item" @click="logout()" style="color: white !important; cursor:pointer;"><i class="now-ui-icons media-1_button-power" /> Log Out</li>
            </ul>
          </div> -->
          <b-nav-item-dropdown class="user" right style="padding: 4px;">
              <template #button-content>
                <img :src="getImgUrl(userImage)" width="42" class="img-rounded">
              </template>
              <b-dropdown-item href="#"  @click="goSettings()" style="color: black !important; cursor:pointer;"><i class="now-ui-icons ui-1_settings-gear-63" />{{ $t('LogStaffNavBar.Settings') }}</b-dropdown-item>
              <b-dropdown-item href="#" @click="logout()" style="color: black !important; cursor:pointer;"><i class="now-ui-icons media-1_button-power" />{{ $t('LogStaffNavBar.LogOut') }}</b-dropdown-item>
          </b-nav-item-dropdown> 
          <!-- <div>
            <img :src="userImage" width="42">
            <drop-down
                  tag="li"
                  :title="$store.state.auth.user.data.email"
                  :src="userImage"
                  class="nav-item nav-dropdown"
                  style="font-weight: 600; margin-top: -40px"
                  
              >
                  <a 
                      class="dropdown-item"
                      @click="goSettings()"
                      style="color: black !important; cursor:pointer;"
                  >
                      <i class="now-ui-icons ui-1_settings-gear-63" /> Settings
                  </a>
                  <a 
                      class="dropdown-item" 
                      
                      @click="logout()"
                      style="color: black !important; cursor:pointer;"
                  >
                      <i class="now-ui-icons media-1_button-power" /> Log Out
                  </a>
              </drop-down>
            </div> -->
        </template>
        <!-- <template v-else>
          <drop-down
                tag="li"
                :title="$store.state.auth.user.data.email"
                icon="now-ui-icons users_circle-08"
                class="nav-item nav-dropdown"
                style="font-weight: 600;"
                
            >
                <a 
                    class="dropdown-item"
                    @click="goSettings()"
                    style="color: black !important;cursor:pointer;"
                >
                    <i class="now-ui-icons ui-1_settings-gear-63" /> Settings
                </a>
                <a 
                    class="dropdown-item" 
                    @click="logout()"
                    style="color: black !important; cursor:pointer;"
                >
                    <i class="now-ui-icons media-1_button-power" /> Log Out
                </a>
            </drop-down>
        </template> -->
      </template>
    </navbar>
    <modal v-show="modals.Renewal" :show.sync="modals.Renewal" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
            {{$t("Renewal")}}
        </h4>
        <card v-if="dataWallet.balance" style="background-color: #5D6176; border-radius: 10px;">
            <b-row style="color: white; font-weight: 600;">
                <b-col>
                    {{ $t('Wallet.WalletBalance') }}
                </b-col>
            </b-row>
            <b-row style="color: rgb(255, 255, 255);  font-weight: 600;">
                <b-col>
                    <h4 class="m-0" style="font-weight:500"><b-icon icon="coin"></b-icon>{{dataWallet.balance.toFixed(2)}}  → <span style=" font-weight: 400; color: #c5c5c5;"><b-icon icon="coin"></b-icon>{{ dataWallet.balance.toFixed(2) - 100}}</span> </h4>
                </b-col>
            </b-row>
        </card>
        <b-row class="mt-3">
            <b-col>
                <p>{{ $t("MsgRenewal") }}</p>
            </b-col>
        </b-row>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="renewalQuote">
              {{ $t("DashBoardStaff.Modals.DeleteRole.Footer.Save") }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.Renewal = false">
                {{ $t("Parameters.Modals.Create.Footer.Close") }}
            </n-button>
        </template>
    </modal>
  </div>
</template>

<script>
import ExternalUserDataService from '../services/ExternalUserDataService';
import { DropDown, Navbar, NavLink, Button, Modal, Card } from '@/components';
import Login from '../services/Login'
import LocaleSwitcher from "../components/LocaleSwitcher.vue";
import WalletDataServices from '../services/WalletDataServices';
    export default {
    name: 'MainNavbar',
    components: {
        Navbar,
        Card,
        Modal,
        DropDown,
        LocaleSwitcher,
        [Button.name]: Button,

    },
    props: {
        transparent: Boolean,
        colorOnScroll: Number,
    },
    watch: {
        $tokensValue(newValue){
            console.log('tokens value changed! desde el nav ', newValue)
            this.tokensValue = newValue
        },
        $profileImageGlobal(newValue){
            console.log('profile image changed!', newValue)
            this.userImage = newValue
        },
        $orgSelected(newValue){
            this.org_id = newValue.id
            this.getData()
        },
        $roleSelected(newValue){
            this.role_id = newValue
            this.getData()
        },
    },
    computed: {
      isNearExpiry() {
        let expiryDate = new Date(this.date_expired);
        let currentDate = new Date();
        let oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
        return expiryDate <= oneMonthFromNow && expiryDate >= currentDate;
      }
    },
    created () {
        this.getData()
    },
    data() {
        return {
          org_id: null,
          role_id: null,
          modals: {
            Renewal: false,
          },
          dataWallet: [],
          date_expired: '',
          route: this.$route.name,
          userImage: this.$getImgUrlSite('default-avatar.png'),
          // isOpen: false,
          user: {},
          org_id: null,
          project_id: null,
          projects: '',
          refreshTokens: null,
          members: null,
          wallet: null,
          emptyWallet: null,
          route_dict: {
            'users-management': [
              {route: '', title: 'Users management', active: true}
            ],
            'projects-management': [
              {route: '', title: 'Projects management', active: true}
            ],
            'wallet': [
              {route: '', title: 'Wallet', active: true}
            ],
            'profile': [
              {route: '', title: 'Profile', active: true}
            ],
            'settings': [
              {route: '', title: 'Settings', active: true}
            ],

          },
          tokensValue: 0
        }
    },
    methods: {
      getData() {
        let user = JSON.parse(localStorage.getItem("user"));
        let user_id  = user.data.id
        ExternalUserDataService.findOne(user_id).then(
            Response => {
              this.user = Response.data.data
              if (this.user.renewalDate) this.date_expired = this.formatDate(this.addYearsToDate(this.user.renewalDate, 1))
              else this.date_expired = this.formatDate(this.addYearsToDate(this.user.createdAt, 1))
              this.userImage = this.user.profile_image

            }
        )
          if(this.$orgSelected) this.org_id = this.$orgSelected.id
        if (this.$roleSelected) this.role_id = this.$roleSelected
        if (this.$projectSelected.id && this.org_id && this.$roleSelected) {
          console.log('============== get tokens header', this.$tokensValue)
          WalletDataServices.getTokens(this.org_id, this.$roleSelected, this.$projectSelected.id).then(
                Response => {
                    this.dataWallet = Response.data
                    if(Response.data.balance){
                        if(Response.data.balance > 0){
                            this.$tokensValue = Response.data.balance.toFixed(2)
                            this.tokensValue = Response.data.balance.toFixed(2)
                        }
                        else{
                            this.$tokensValue = '0.00'
                            this.tokensValue = '0.00'
                        }
                    }
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
          }
      },
        openModalRenewal() {
          this.modals.Renewal = true
          this.getData()

        },
        addYearsToDate(date, years) {
            let newDate = new Date(date);
            newDate.setFullYear(newDate.getFullYear() + years);
            return newDate;
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${day}-${month}-${year}`;
        },
        logout () {
            Login.logout()
            this.$router.push({ path: '/' })
            this.$store.state.auth.status.loggedIn = false
            this.$store.state.auth.user = null
        },
        goSettings () {
            this.$router.push({ path: '/settings' })
        },
        getImgUrl (name) {
            try{
                if(name){
                    return `${process.env.VUE_APP_VITE_BUCKET_CDN}/profile-images/${name}?withheaders=yes`
                }
                else{
                  return this.$getImgUrlSite('default-avatar.png')
                }
            }catch{
              return this.$getImgUrlSite('default-avatar.png')
            }
        },

        renewalQuote() {
          WalletDataServices.renewalQuote(this.user.id, this.org_id, this.role_id).then(
            Response => {
              this.$swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Renewal quote sent successfully'
              })
              this.modals.Renewal = false
              this.getData()
            },
            Error => {
              this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: Error.response.data.message
              })
            }
          )
        }
    },
    }
</script>

<style scoped>
.selectOrg {
    width: 280px;
    font-size: 13px;
    background: #5D6176;
    color: white;
    font-weight: 600;

}
.selectOrg .custom-select-arrow {
  background-color: #ff0000 !important;
}

.responsive-nav-1 {
    margin-left: 0px !important;
}
 .responsive-nav-2 {
    margin-left: 165px !important;
}
.highlight-expiry {
  background-color: #ff4444 !important;
  border: 2px solid #ff0000 !important;
  border-radius: 5px;
  font-weight: bold;
  color: #ffffff;
}
.wallet-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  height: 70%;
  color: #ffffff;
  background-color: #4d4c4c;
  border: 2px solid #d2cfcf;
  border-radius: 5px;
  margin: auto;
}

@media (max-width: 991px) {
  .logo-ncm{
    margin-left: 15px;
  }
}

@media (max-width: 553px) {
  .logo-ncm{
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .user{
    margin-right: 0px;
  }
}
@media (max-width: 641px) {
  #membershipDate{
    font-size: 0.9em;
  }
  #tokensValue{
    font-size: 0.9em;
  }
}
@media (max-width: 435px) {
  #membershipDate{
    font-size: 0.7em;
  }
  #tokensValue{
    font-size: 0.7em;
  }
}
</style>


