<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <div v-if="loading" id="toHidden"
            style="
            position: absolute;
            top     :0;
            left    : 0;
            height  : 100%;
            width   :  100%;
            background-color:azure;
            z-index : 1060;
            opacity : 0.6;"
            >
        </div>
        <b-icon  v-if="loading" style="
            z-index: 2000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            "
            icon="three-dots"
            animation="cylon"
            font-scale="4">
        </b-icon>
      <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
          <b-col style="display: flex">
            <h2 class="card-title">
              {{$t('HomeUser.Reports')}}
            </h2> 
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <card>
              <b-row>
                <b-col cols="4" class="m-auto">
                  <h6>Reports list</h6>
                </b-col>
                <b-col>
                  <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                      <b-button-group class="mx-1">
                          <b-button
                              class="btn-simple ncm-btn"
                              style="font-weight: 800 !important;"
                              target="_blank"
                              @click="openCreateModal"
                          >
                          <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                          {{ $t('Reports.Create') }}
                          </b-button>
                      </b-button-group>
                  </b-button-toolbar>
              </b-col>
              <!-- <b-col>
                  <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                    <b-button-group class="mx-1" v-if="role == 'admin' || role == 'analyst'">
                      <n-button id="newAdmin" class="btn-simple ncm-btn"
                          target="_blank" @click.native="openEditModal()">
                          <b-icon icon="cloud-arrow-up" class="button-icon"></b-icon>
                          Upload layer
                      </n-button>
                    </b-button-group>
                  </b-button-toolbar>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-table ref="ecosystemTable" 
                      id="ecosystemTable"
                      class="dashboard-table"
                      :fields="headers" 
                      :items="reports"
                      select-mode="single"
                      show-empty
                      @row-selected="selectRow"
                      :current-page="currentPage"
                      :per-page="perPage"
                      responsive="sm"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      sort-by="createdAt"
                      :sort-desc="true"
                      @filtered="onFiltered"> 
  
                      <template #empty="">
                        {{ $t('NoReportsYet') }}
                      </template>          
                      <template #cell(name)="data">
                        {{ data.item.name.split('_')[0] }}.zip ---- {{ data.item.name }}
                      </template>         
                      <template #cell(options)="data">
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Tooltips.Download')" @click="downloadReport(data.item)">
                              <b-icon icon="download"></b-icon>
                          </b-button>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Tooltips.CopyToken')" @click="copyToken(data.item.token)">
                              <b-icon icon="clipboard"></b-icon>
                          </b-button>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Tooltips.Delete')" @click="openDeleteModal(data.item)">
                              <b-icon icon="trash"></b-icon>
                          </b-button>
                      </template>
                      
                      <template #cell(createdAt)="data">
                          {{ data.item.createdAt | formatDate }}
                      </template>
                      <template #cell(updatedAt)="data">
                          {{ data.item.updatedAt | formatDate }}
                      </template>
  
                      <!-- <template #row-details="row">
                          <b-card class="row-details-card">
                              <div v-if="selectedElement" class="info-container">
                                <b-row>
                                  <b-form-group :label="$t('Languages.Table.Label')" class="info-group" label-class="info-group-label">
                                      <p class="info-group-input">
                                        {{ selectedElement.name }}
                                      </p>
                                  </b-form-group>
                                </b-row>
                              </div>
                          </b-card>
                          
                      </template> -->
  
                  </b-table>
                  <div class="pagination-container">
                      <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                      </b-pagination>
                      <b-form-select v-model="perPage" class="pagination-select">
                          <b-form-select-option :value="5">5</b-form-select-option>
                          <b-form-select-option :value="10">10</b-form-select-option>
                          <b-form-select-option :value="20">20</b-form-select-option>
                          <b-form-select-option
                              :value="reports.length">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                      </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </b-container>

      <modal :show.sync="modals.delete" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("Reports.Modals.Delete") }}
        </h4>
        <label v-if="selectedElement">{{ $t("MsgDeleteReport") }} <b>{{ selectedElement.name }}?</b></label>
        <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click="deleteElement()">
            {{ $t("Modals.Confirm") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.delete = false">
            {{ $t("Modals.Cancel") }}
          </n-button>
        </template>
    </modal>

      <modal :show.sync="modals.create" class="modal-primary" :show-close="false" header-classes="justify-content-center" >
        <p>{{ $t("Reports.Modals.CreateText") }} <b>{{ $projectSelected.name }}</b></p>

        <template slot="footer">
          <n-button type="neutral" link @click="goMap()">
            {{ $t("Modals.Confirm") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.create = false">
            {{ $t("Modals.Cancel") }}
          </n-button>
        </template>
      </modal>

      <modal :show.sync="modals.download" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("MsgCopyReportTitle") }}
        </h4>
        <label v-if="selectedElement">{{ $t("MsgCopyReportExpend") }} <b>{{ selectedElement.value }}</b></label>
        <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click="downloadReport()">
            {{ $t("Modals.Download") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.download = false">
            {{ $t("Modals.Cancel") }}
          </n-button>
        </template>
    </modal>

    </div>
</template>
<script>
  import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import OrganizationsDataService from '@/services/OrganizationsDataService'
  import WalletDataServices from '../../services/WalletDataServices';
  import MapsDataService from '@/services/MapsDataService'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import ProjectsDataService from '../../services/ProjectsDataService';
  import ReportsDataService from '../../services/ReportsDataService';

  export default {
  name: 'reports',
  components: {
      Card,
      Modal,
      [Checkbox.name]: Checkbox,
      [DropDown.name]: DropDown,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      vueDropzone: vue2Dropzone,
  },
  data() {
      return {
          modals: {
              delete: false,
              create: false,
              download: false
          },
          reports: [],
          selectedElement: null,
          headers: [
            { key: 'name', label: this.$t('DashBoardStaff.Table.Files'), sortable: true},
            { key:'project.name',label: this.$t('DashBoardStaff.Table.Project'), sortable: true },
            { key:'createdAt',label: this.$t('Ecosystems.Table.CreatedAt'), sortable: true },
            { key:'options', label: this.$t('DashBoardStaff.Table.Actions'), thClass: 'position-sticky' }
          ],
          allOpenRows: [],
          currentPage: 1,
          perPage: 10,
          loading: false,
          organization_id: undefined,
          role: undefined,
          msgReportError: [],
          msgReportOK: [],
          project_id: undefined
      }
  },
  computed: {
        totalPages () {
            return this.reports.length
        },
        // function to filter orgs with the text field
        
        // sortOptions() {
        //   // Create an options list from our fields
        //   return this.headers
        //     .filter(f => f.sortable)
        //     .map(f => {
        //       return { text: f.label, value: f.key }
        //     })
        // }
      },
      watch: {
        // $orgSelected(newValue){
        //     console.log('Org selected changed!', newValue)
        //     this.organization_id = newValue.id
        //     this.checkExternalRole(this.$store.state.auth.user)
        //     this.getData()
        // },
        $projectSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.project_id = newValue.id
            this.checkExternalRole(this.$store.state.auth.user)
            this.getData()
        }
    },
  created() {
    this.organization_id = this.$orgSelected.id
    this.checkExternalRole(this.$store.state.auth.user)
    this.getData()
  },
  methods:{
    openDownloadModal(report){
      this.selectedElement = report
      this.modals.download = true
    },
    goMap(){
      this.$router.push('/maps')
    },
    copyToken(token){
      if (navigator.clipboard) { // If normal copy method available, use it
        navigator.clipboard.writeText(token);
        this.$swal.fire({
            icon: 'success',
            text: this.$t('Ecosystems.Copied')
        }) 
      } else { // Otherwise fallback to the above function
        this.unsecuredCopyToClipboard(token);
      }
    },
    unsecuredCopyToClipboard(token){
      const textArea = document.createElement("textarea");
      textArea.value = token;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    },
    downloadReport(report) {
      this.loading = true
      //var report = this.selectedElement
      // var tokens_report = report.value
      // console.log('tokens report', tokens_report)
      // if(this.$tokensValue < tokens_report){
      //           this.$swal.fire({
      //               icon: 'error',
      //               text: this.$t('MsgNotEnoughTokensReport'),
      //           })
      //           return
      // }
      

      ReportsDataService.downloadCopy(report.id).then(
            Response => {
                console.log(Response)
                const href = URL.createObjectURL(Response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'report_compressed'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                this.loading = false
                
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
      ) 

      
    },
    clearErrors(){
      this.msgReportError = []
      this.msgReportOK = []
    },
    checkExternalRole(user){
        console.log('$roleSelected', this.$roleSelected)
        this.role = this.$roleSelected.user_role
        console.log(this.role)
    },

    applyRowClass(row) {
        if (row != null) {
          if (!row.is_active) {
          return 'disabled-row';
        } else {
          return '';
        }
        }
      },  
    onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
    },
    selectRow(items){
      console.log('selected:', items)
      this.selectedElement = items[0]
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    handleRowClicked (item, index) {
        this.allOpenRows.map((ele) => {
            if (ele.id !== item.id && ele._showDetails) {
            this.$set(ele, '_showDetails', !ele._showDetails)
            }
        })
        this.allOpenRows = []
        this.$set(item, '_showDetails', !item._showDetails)
        this.allOpenRows.push(item)
    },
    getData(){
      if(this.$projectSelected.id){
        console.log("project:", this.$projectSelected)
        console.log("role:", this.$roleSelected.user_role)
        ReportsDataService.findByProject(this.$projectSelected.id, this.$roleSelected.user_role).then(
            Response => {
                this.reports = Response.data.data
                console.log(this.reports)
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
        ) 
      }
        
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = element
        this.modals.delete = true
    },
    openCreateModal(){
        this.modals.create = true
    },
    deleteElement(){
        console.log(this.selectedElement)
        ReportsDataService.delete(this.selectedElement.id).then(
            async response => {
                console.log(response.data)
                this.modals.delete = false
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('Alerts.ReportDeleted'),
                  text: ''
                  }).then(() => {
                    this.getData()
                  });
            },
            error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error.response.data.message
              })
            }
        )
    }
  }
  }
  </script>
  <style scoped>
  .disabled-row {
    background-color: #f8f8f8;
    color: #999999;
  }

  
  ::v-deep .dz-preview{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }

  ::v-deep .dz-image{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }


  ::v-deep .dz-progress{
      visibility: hidden;
  }

  ::v-deep .dz-remove{
    position: absolute !important;
    top: 0px !important;
    left: 69px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    padding: 0px !important;
    border: none !important;
    color: #fe2626b0 !important;
  }

  .layers-size-modal{
    margin-bottom: 0;
    font-size: 13px;
    font-style: italic;
    color: #ffffff8a;
  }

  .error-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.error-item {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #721c24;
}

.error-item::before {
  color: #721c24;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* .row .col.d-flex {
  justify-content: flex-end;
} */
.checks-ok-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.checks-ok-item {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #155724;
}

.checks-ok-item::before {
  color: #155724;
  margin-right: 5px;
  margin-bottom: 5px;
}

::v-deep #dropzone{
  padding: 0;
  min-height: auto;
  margin-bottom: 16px;
}
::v-deep .card-modal > .card-body {
  padding: 0.8em!important;
  /* overflow-y: hidden; */
}

@media (max-width: 1388px) {
  .page-header-bs{
    margin-top: 70px;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
  </style>