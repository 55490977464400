<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <div v-if="loading" id="toHidden"
                            style="
                                position: absolute;
                                top     :0;
                                left    : 0;
                                height  : 100%;
                                width   :  100%;
                                background-color:azure;
                                z-index : 1000;
                                opacity : 0.6;"
                            >
        </div>
        <b-icon  v-if="loading" style="
        z-index: 2000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        "
        icon="three-dots"
        animation="cylon"
        font-scale="4">
        </b-icon>
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                <h2 class="card-title">
                    {{ $t('ProjectsManagement.Title') }}
                </h2> 
                </b-col>
            </b-row>
            <b-row>
            <b-col>
                <card>
                    <b-row>
                        <b-col cols="4" style="float: left; display: flex;">
                            <span class="mt-auto mb-auto"><h6 class=" mb-0">{{ $t('ProjectsManagement.Title') }}</h6></span>
                        </b-col>
                        <b-col v-if="role == 'admin'">
                            <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                                <b-button-group class="mx-1">
                                    <b-button
                                        class="btn-simple ncm-btn"
                                        style="font-weight: 800 !important;"
                                        target="_blank"
                                        @click="openCreateModal"
                                    >
                                    <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                    {{ $t('ProjectsManagement.Create') }}
                                    </b-button>
                                </b-button-group>
                            </b-button-toolbar>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table
                            style="overflow-x:auto;" 
                            ref="usersTable"
                            id="usersTable"
                            :fields="role == 'analyst' ? headersAnalyst : headers" 
                            :items="projects"
                            select-mode="single"
                            responsive="sm"
                            :selectable="role != 'analyst'"
                            show-empty
                            @row-selected="selectRow"
                            @row-clicked="handleRowClicked"
                            @sort-changed="sortingChanged"
                            :current-page="currentPage"
                            :per-page="perPage"
                            sort-by="createdAt"
                            :sort-desc="true"
                            > 
                            <template #empty="">
                                {{$t('Projects.Empty')}}
                            </template>
                            <template #cell(options)="data">
                                <b-button v-if="role == 'admin'" class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                                    <b-icon icon="pen"></b-icon>
                                </b-button>
                                <b-button  v-if="role == 'project_manager' || role == 'admin'" class="action-table" v-b-tooltip.hover.viewport title="Assign analyst" @click="openAssignModal(data.item)">
                                    <b-icon icon="person-plus"></b-icon>                                
                                </b-button>
                                <b-button v-if="role == 'admin'" class="action-table" v-b-tooltip.hover.viewport :title="$t('ProjectsManagement.Delete')" @click="openDeleteModal(data.item)">
                                    <b-icon icon="check-circle"></b-icon>
                                </b-button>
                                <b-button class="action-table" v-b-tooltip.hover.viewport title="Open map" @click="openMap(data.item)">
                                    <b-icon icon="map"></b-icon>
                                </b-button>
                            </template>
                            <template #cell(is_active)="data">
                                <div v-if="data.item.is_active == true">
                                    <b-icon icon="check2"></b-icon>
                                </div>
                                <div v-else>
                                    <b-icon icon="x"></b-icon>
                                </div>
                            </template>
                            <template #cell(share_wallet)="data">
                                <div v-if="data.item.share_wallet == true">
                                    <b-icon icon="check2"></b-icon>
                                </div>
                                <div v-else>
                                    <b-icon icon="x"></b-icon>
                                </div>
                            </template>
                            <template #cell(tokens_available)="data">
                                
                                <template v-if="data.item.tokens_available">
                                    {{ data.item.tokens_available.toFixed(2)}}
                                </template>
                                <template v-else-if="data.item.tokens_available == 0">
                                    0.00
                                </template>
                            </template>
                            <template #cell(createdAt)="data">
                                {{ data.item.createdAt | formatDate }}
                            </template>
                            <template #cell(finished_at)="data">
                                {{ data.item.finished_at | formatDate }}
                            </template>
                            <template #row-details="" v-if="role == 'project_manager' || role == 'admin'">
                                <b-card class="row-details-card" v-if="role == 'project_manager'">
                                    <div v-if="selectedElement" class="info-container">
                                        <b-row>
                                            <b-col>
                                                <!-- <b-row style="display: flex; justify-content: center;">
                                                    <h5>{{ $t('ProjectsManagement.PM') }}</h5>
                                                </b-row>
                                                    <b-table
                                                    ref="usersTable"
                                                    id="usersTable"
                                                    :fields="headersProjectManager" 
                                                    :items="[selectedElement.project_manager]"
                                                    responsive="sm"
                                                    show-empty
                                                    > 
                                                    <template #empty="">
                                                        {{ $t('ProjectsManagement.NoPM') }}
                                                    </template>
                                                    <template #cell(createdAt)="data">
                                                        {{ data.item.createdAt | formatDate }}
                                                    </template>
                                                    <template #cell(finished_at)="data">
                                                        {{ data.item.finished_at | formatDate }}
                                                    </template>
                                                    <template #cell(options)="data">
                                                        <b-button v-if="!selectedElement.share_wallet" class="action-table" v-b-tooltip.hover.viewport title="Add tokens" @click="openAssignTokensModal(data.item)">
                                                            <b-icon icon="coin"></b-icon>
                                                        </b-button>
                                                    </template>
                                                    </b-table> -->
                                                    <h5>{{ $t('ProjectsManagement.Analysts') }}</h5>
                                                <b-table
                                                ref="usersTable"
                                                id="usersTable"
                                                :fields="headersProjectAnalyst" 
                                                :items="selectedElement.analysts"
                                                responsive="sm"
                                                show-empty
                                                :current-page="currentPageAnalyst"
                                                :per-page="perPageAnalyst"
                                                > 
                                                <template #empty="">
                                                    {{ $t('ProjectsManagement.NoAnalysts') }}
                                                </template>

                                                <template #cell(tokens_available)="data">
                                                    <template v-if="data.item.tokens_available">
                                                        {{ data.item.tokens_available.toFixed(2)}}
                                                    </template>
                                                    <template v-else-if="data.item.tokens_available == 0">
                                                        0.00
                                                    </template>
                                                </template>

                                                <template #cell(createdAt)="data">
                                                    {{ data.item.createdAt | formatDate }}
                                                </template>
                                                <template #cell(finished_at)="data">
                                                    {{ data.item.finished_at | formatDate }}
                                                </template>
                                                <template #cell(options)="data">
                                                    <b-button v-if="!selectedElement.share_wallet" class="action-table" v-b-tooltip.hover.viewport title="Add tokens" @click="openAssignTokensModal(data.item)">
                                                        <b-icon icon="coin"></b-icon>
                                                    </b-button>
                                                </template>
                                                </b-table>
                                            </b-col>
                                            <b-col>
                                                <h5>{{ $t('ProjectsManagement.Invitations') }}</h5>
                                            <b-table
                                            ref="usersTable"
                                            id="usersTable"
                                            :fields="headersInvitations" 
                                            :items="selectedElement.invitations"
                                            responsive="sm"
                                            show-empty
                                            :current-page="currentPageInvitations"
                                            :per-page="perPageInvitations"
                                            > 
                                            <template #empty="">
                                                {{ $t('ProjectsManagement.NoInvitations') }}
                                            </template>

                                            <template #cell(createdAt)="data">
                                                {{ data.item.createdAt | formatDate }}
                                            </template>
                                            <template #cell(finished_at)="data">
                                                {{ data.item.finished_at | formatDate }}
                                            </template>
                                            </b-table>
                                            <div style="display: flex">
                                                <b-pagination
                                                    v-model="currentPageInvitations"
                                                    :total-rows="totalPagesInvitations"
                                                    :per-page="perPageInvitations">
                                                </b-pagination>
                                                <b-form-select v-model="perPageInvitations" class="pagination-select">
                                                    <b-form-select-option :value="3">3</b-form-select-option>
                                                    <b-form-select-option :value="5">5</b-form-select-option>
                                                    <b-form-select-option :value="10">10</b-form-select-option>
                                                    <b-form-select-option :value="20">20</b-form-select-option>
                                                    <b-form-select-option :value="totalPagesInvitations">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="6">
                                                <h5>Layers</h5>
                                                <b-table id="table_wms_layers"
                                                    ref="table_wms_layers"
                                                    :items="selectedElement.layers"
                                                    :fields="headersLayers"
                                                    :current-page="currentPageLayers"
                                                    :per-page="perPageLayers"
                                                    responsive="sm"
                                                    show-empty
                                                    > 
                                                    <template #empty="">
                                                    No layers for this project
                                                    </template>

                                                    
                                                </b-table>
                                                <div style="display: flex">
                                                    <b-pagination
                                                        v-model="currentPageLayers"
                                                        :total-rows="totalPagesLayers"
                                                        :per-page="perPageLayers">
                                                    </b-pagination>
                                                    <b-form-select v-model="perPageLayers" class="pagination-select">
                                                        <b-form-select-option :value="3">3</b-form-select-option>
                                                        <b-form-select-option :value="5">5</b-form-select-option>
                                                        <b-form-select-option :value="10">10</b-form-select-option>
                                                        <b-form-select-option :value="20">20</b-form-select-option>
                                                        <b-form-select-option :value="totalPagesLayers">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                                    </b-form-select>
                                                </div>
                                            </b-col>
                                            <!-- <b-col>
                                                <b-row style="display: flex; justify-content: center;">
                                                    <h5>{{ $t('ProjectsManagement.Reports') }}</h5>
                                                </b-row>
                                                    <b-table
                                                    ref="usersTable"
                                                    id="usersTable"
                                                    :fields="headersReports" 
                                                    :items="[]"
                                                    responsive="sm"
                                                    show-empty
                                                    > 
                                                    <template #empty="">
                                                        {{ $t('ProjectsManagement.NoReports') }}
                                                    </template>

                                                    <template #cell(createdAt)="data">
                                                        {{ data.item.createdAt | formatDate }}
                                                    </template>
                                                    <template #cell(finished_at)="data">
                                                        {{ data.item.finished_at | formatDate }}
                                                    </template>
                                                    </b-table>
                                            </b-col> -->
                                        </b-row>
                                    <!-- <b-row>
                                        <b-form-group :label="$t('Projects.Table.Details.Manager')" class="info-group" label-class="info-group-label">
                                        <p class="info-group-input">
                                            <template v-if="selectedElement.project_manager">
                                                <p class="user-details-name">{{ selectedElement.project_manager.name}} {{ selectedElement.project_manager.surname}} ({{ selectedElement.project_manager.email }})</p>
                                                <p class="user-details" v-if="selectedElement.project_manager_automatic_renewal">Automatic renewal: Yes</p>
                                                <p class="user-details" v-else>Automatic renewal: No</p>
                                            </template>
                                            <template v-else>
                                               {{$t('Projects.Table.Details.ManagerEmpty')}}
                                            </template>
                                            </p>
                                        </b-form-group>
                                    </b-row>
                                    <b-row>
                                        <b-form-group style="width:100%" :label="$t('Projects.Table.Details.Analysts')" class="info-group" label-class="info-group-label">
                                        <b-row class="info-group-input">
                                            <template v-if="selectedElement.analysts.length > 0">
                                                <b-col class="user-info-detail" v-for="a in selectedElement.analysts" :key="a.id" cols="2">
                                                    <p class="user-details-name">{{ a.name}} {{ a.surname}} ({{ a.email }})</p>
                                                    <p class="user-details">Assigned at: {{ a.createdAt | formatDate }}</p>
                                                </b-col>
                                            </template>
                                            <template v-else>
                                                <b-col class="user-info-detail">
                                                    <p class="user-details-name">
                                                    {{$t('Projects.Table.Details.AnalystsEmpty')}}
                                                    </p>
                                                </b-col>
                                            </template>
                                        </b-row>
                                        </b-form-group>
                                    </b-row> -->
                                    </div>
                                </b-card>
                                <b-card class="row-details-card" v-else>
                                    <div v-if="selectedElement" class="info-container">
                                    <b-row>
                                        <b-col>
                                            <h5>{{ $t('ProjectsManagement.Analysts') }}</h5>
                                            <b-table
                                            ref="usersTable"
                                            id="usersTable"
                                            :fields="headersProjectAnalyst" 
                                            :items="selectedElement.analysts"
                                            responsive="sm"
                                            show-empty
                                            :current-page="currentPageAnalyst"
                                            :per-page="perPageAnalyst"
                                            > 
                                            <template #empty="">
                                                {{ $t('ProjectsManagement.NoAnalysts') }}
                                            </template>

                                            <template #cell(createdAt)="data">
                                                {{ data.item.createdAt | formatDate }}
                                            </template>
                                            <template #cell(finished_at)="data">
                                                {{ data.item.finished_at | formatDate }}
                                            </template>
                                            <template #cell(options)="data">
                                                <b-button v-if="!selectedElement.share_wallet" class="action-table" v-b-tooltip.hover.viewport title="Add tokens" @click="openAssignTokensModal(data.item)">
                                                    <b-icon icon="coin"></b-icon>
                                                </b-button>
                                            </template>

                                            </b-table>
                                            <div style="display: flex">
                                                <b-pagination
                                                    v-model="currentPageAnalyst"
                                                    :total-rows="totalPagesAnalyst"
                                                    :per-page="perPageAnalyst">
                                                </b-pagination>
                                                <b-form-select v-model="perPageAnalyst" class="pagination-select">
                                                    <b-form-select-option :value="3">3</b-form-select-option>
                                                    <b-form-select-option :value="5">5</b-form-select-option>
                                                    <b-form-select-option :value="10">10</b-form-select-option>
                                                    <b-form-select-option :value="20">20</b-form-select-option>
                                                    <b-form-select-option :value="totalPagesAnalyst">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                            
                                        </b-col>
                                        <b-col>
                                            <h5>{{ $t('ProjectsManagement.Invitations') }}</h5>
                                            <b-table
                                            ref="usersTable"
                                            id="usersTable"
                                            :fields="headersInvitations" 
                                            :items="selectedElement.invitations"
                                            responsive="sm"
                                            show-empty
                                            :current-page="currentPageInvitations"
                                            :per-page="perPageInvitations"
                                            > 
                                            <template #empty="">
                                                {{ $t('ProjectsManagement.NoInvitations') }}
                                            </template>

                                            <template #cell(createdAt)="data">
                                                {{ data.item.createdAt | formatDate }}
                                            </template>
                                            <template #cell(finished_at)="data">
                                                {{ data.item.finished_at | formatDate }}
                                            </template>
                                            </b-table>
                                            <div style="display: flex">
                                                <b-pagination
                                                    v-model="currentPageInvitations"
                                                    :total-rows="totalPagesInvitations"
                                                    :per-page="perPageInvitations">
                                                </b-pagination>
                                                <b-form-select v-model="perPageInvitations" class="pagination-select">
                                                    <b-form-select-option :value="3">3</b-form-select-option>
                                                    <b-form-select-option :value="5">5</b-form-select-option>
                                                    <b-form-select-option :value="10">10</b-form-select-option>
                                                    <b-form-select-option :value="20">20</b-form-select-option>
                                                    <b-form-select-option :value="totalPagesInvitations">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                            
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="6">
                                            <h5>Layers</h5>
                                            <b-table id="table_wms_layers"
                                                ref="table_wms_layers"
                                                :items="selectedElement.layers"
                                                :fields="headersLayers"
                                                :current-page="currentPageLayers"
                                                :per-page="perPageLayers"
                                                responsive="sm"
                                                show-empty
                                                > 
                                                <template #empty="">
                                                No layers for this project
                                                </template>

                                                
                                            </b-table>
                                            <div style="display: flex">
                                                <b-pagination
                                                    v-model="currentPageLayers"
                                                    :total-rows="totalPagesLayers"
                                                    :per-page="perPageLayers">
                                                </b-pagination>
                                                <b-form-select v-model="perPageLayers" class="pagination-select">
                                                    <b-form-select-option :value="3">3</b-form-select-option>
                                                    <b-form-select-option :value="5">5</b-form-select-option>
                                                    <b-form-select-option :value="10">10</b-form-select-option>
                                                    <b-form-select-option :value="20">20</b-form-select-option>
                                                    <b-form-select-option :value="totalPagesLayers">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                                </b-card>   
                            </template>
                            </b-table>
                            <div class="pagination-container">
                                <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option
                                        :value="users.length">{{ $t('UsersManagement.All') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <!-- Modal create  -->
    <modal :show.sync="modals.create" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
            {{ $t('ProjectsManagement.CreateProject') }}
        </h4>
        <card style="background-color: #5D6176; border-radius: 10px;">
                <b-row style="color: white; font-weight: 600;">
                    <b-col>
                        {{ $t('ProjectsManagement.Wallet') }}
                    </b-col>
                </b-row>
                <b-row style="color: rgb(255, 255, 255);  font-weight: 600;">
                    <b-col>
                        <h2 v-if ="project.ammount == null" class="m-0" style="font-weight:500"><b-icon icon="coin"></b-icon>{{dataWallet.balance.toFixed(2)}}  → </h2>
                        <h2 class="m-0" v-else style="font-weight:500"><b-icon icon="coin"></b-icon>{{dataWallet.balance.toFixed(2)}}  → <span style=" font-weight: 400; color: #c5c5c5;"><b-icon icon="coin"></b-icon>{{computedTokensToCreate.toFixed(2)}}</span> </h2>
                    </b-col>
                </b-row>
        </card>
        <b-form-group :label="$t('Languages.Modals.Modify.Name') + ' *' ">
            <b-form-input
            v-model="project.name"
            id="input-name"
            name="input-name"
            v-validate="{ required: true}"
            :state="validateState('input-name')"
            aria-describedby="input-name-live-feedback"
            size="sm"
            :placeholder=" $t('Languages.Modals.Modify.Name') + ' *'  "
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-live-feedback">{{ veeErrors.first('input-name') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Description">
            <b-form-input
            v-model="project.description"
            id="input-description"
            name="input-description"
            :state="validateState('input-description')"
            aria-describedby="input-description-live-feedback"
            size="sm"
            placeholder="Description"
            ></b-form-input>
            <b-form-invalid-feedback id="input-description-live-feedback">{{ veeErrors.first('input-description') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('AddProject.Form.Manager')" label-for="input-manager">
            <b-form-select v-model="project.user_id"
                id="input-manager"
                name="input-manager"
                v-validate="{ required: true}"
                aria-describedby="input-manager-live-feedback"
                >
                <b-form-select-option :value="null">{{ $t('ProjectsManagement.SelectPM') }}</b-form-select-option>
                <b-form-select-option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.name }} ({{ user.email }})
                </b-form-select-option>
                <b-form-invalid-feedback id="input-description-live-feedback">{{ veeErrors.first('input-manager') }}</b-form-invalid-feedback>
            </b-form-select>
        </b-form-group>
        <b-form-group :label="$t('AddProject.Amount')" label-for="input-ammount">
            <b-form-input
            v-model="project.ammount"
            id="input-ammount"
            name="input-ammount"
            type="number"
            v-validate="{ numeric: true}"
            :state="validateState('input-ammount')"
            aria-describedby="input-ammount-live-feedback"
            size="sm"
            :placeholder="$t('AddProject.Amount')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-ammount-live-feedback">{{ veeErrors.first('input-ammount') }}</b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex">
            <b-form-group class="ml-2">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="shared_wallet"
                    name="checkbox-1"
                    style="margin-top: 20px"
                    >
                    <label style="margin-top: 2px;">{{ $t('ProjectsManagement.Shared') }}</label>
                </b-form-checkbox>
            </b-form-group>
            <b-form-group class="ml-2">
                <b-form-checkbox
                    id="checkbox-2"
                    v-model="automatic_renewal"
                    name="checkbox-2"
                    style="margin-top: 20px"
                    >
                    <label style="margin-top: 2px;">{{ $t('ProjectsManagement.Renewal') }}</label>
                </b-form-checkbox>
            </b-form-group>  
        </div>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="createProject">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click="modals.create = false">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
            </n-button>
        </template>
    </modal>
    <!-- Modal modify  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
            {{ $t('ProjectsManagement.Modify') }}
        </h4>
        <b-form-group :label="$t('Languages.Modals.Modify.Name') + ' *' ">
            <b-form-input v-if="selectedElement"
            v-model="selectedElement.name"
            id="input-name-modify"
            name="input-name-modify"
            v-validate="{ required: true}"
            :state="validateState('input-name-modify')"
            aria-describedby="input-name-modify-live-feedback"
            size="sm"
            :placeholder=" $t('Languages.Modals.Modify.Name') + ' *'  "
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-modify-live-feedback">{{ veeErrors.first('input-name-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Description">
            <b-form-input v-if="selectedElement"
            v-model="selectedElement.description"
            id="input-description-modify"
            name="input-description-modify"
            :state="validateState('input-description-modify')"
            aria-describedby="input-description-modify-live-feedback"
            size="sm"
            placeholder="Description"
            ></b-form-input>
            <b-form-invalid-feedback id="input-description-modify-live-feedback">{{ veeErrors.first('input-description-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('AddProject.Form.Manager')" label-for="input-managerE">
            <b-form-select v-if="selectedElement"
                v-model="selectedElement.user_id"
                id="input-managerE"
                name="input-managerE"
                v-validate="{ required: true}"
                aria-describedby="input-managerE-live-feedback"
                >
                <b-form-select-option :value="null">{{ $t('ProjectsManagement.SelectPM') }}</b-form-select-option>
                <b-form-select-option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.name }} ({{ user.email }})
                </b-form-select-option>
                <b-form-invalid-feedback id="input-description-live-feedback">{{ veeErrors.first('input-managerE') }}</b-form-invalid-feedback>
            </b-form-select>
        </b-form-group>
        <b-form-group class="ml-2">
            <b-form-checkbox v-if="selectedElement"
                id="checkbox-1"
                v-model="selectedElement.project_manager_automatic_renewal"
                name="checkbox-1"
                >
                <label style="margin-top: 2px;">{{ $t('ProjectsManagement.Renewal') }}</label>
            </b-form-checkbox>
        </b-form-group>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="updateElement">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click="modals.modify = false">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
            </n-button>
        </template>
    </modal>
    <!-- Modal assign analyst -->
    <modal v-show="modals.assign" :show.sync="modals.assign" header-classes="justify-content-center">
        <h4  v-if="selectedElement" slot="header" class="title title-up">
            {{ $t('ProjectsManagement.Assign') }} ({{ selectedElement.name }})
        </h4>
        <card>
            <b-form-tags id="tags-with-dropdown" v-model="selectedAnalysts" no-outer-focus class="mb-2">
                <template v-slot="{ tags, disabled, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                        <li v-for="tag in tags" :key="tag" class="list-inline-item">
                            
                            <b-form-tag
                                @remove="removeTag(tag)"
                                :disabled="disabled"
                                variant="info"
                            >
                            {{ searchName(tag) }} {{ searchSurname(tag) }} ({{ searchEmail(tag) }})
                            </b-form-tag>
                        </li>
                    </ul>
                    <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                    <template #button-content>
                        <b-icon icon="plus"></b-icon> {{ $t("AddProject.AddUsers") }}
                    </template>
                    <b-dropdown-item-button 
                        v-for="option in availableOptions()"
                        style="cursor: pointer;"
                        :key="option"
                        :style="{ cursor: 'pointer' }"
                        @click="selectedAnalysts.push(option)"
                    >
                    {{ searchName(option) }} {{ searchSurname(option) }} ({{ searchEmail(option) }})
                </b-dropdown-item-button>
                    <b-dropdown-text v-if="availableOptions().length === 0">
                        {{$t("AddProject.SearchEmpty")}}
                    </b-dropdown-text>
                </b-dropdown>
                </template>
            </b-form-tags>
        </card>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="inviteAnalysts">
                {{ $t("Parameters.Modals.Create.Footer.Save") }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.assign = false">
                {{ $t("Parameters.Modals.Create.Footer.Close") }}
            </n-button>
        </template>
    </modal>

    <!-- Modal assign tokens -->
    <modal :show.sync="modals.add" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{ $t('ProjectsManagement.Tokens') }}
            </h4>
            <card v-if="selectedElement" style="background-color: #5D6176; border-radius: 10px;">
                <b-row style="color: white; font-weight: 600;">
                    <b-col>
                        {{ $t('ProjectsManagement.ProjectWallet') }}
                    </b-col>
                </b-row>
                <b-row style="color: rgb(255, 255, 255);  font-weight: 600;">
                    <b-col>
                        <h2 v-if ="tokens_to_add == null" class="m-0" style="font-weight:500"><b-icon icon="coin"></b-icon>{{selectedElement.tokens_available.toFixed(2)}}  → </h2>
                        <h2 class="m-0" v-else style="font-weight:500"><b-icon icon="coin"></b-icon>{{selectedElement.tokens_available.toFixed(2)}}  → <span style=" font-weight: 400; color: #c5c5c5;"><b-icon icon="coin"></b-icon>{{computedTokensToTransfer.toFixed(2)}}</span> </h2>
                    </b-col>
                </b-row>
            </card>
            <h6 v-if="selectedAnalyst">To → {{ selectedAnalyst.name }}({{ selectedAnalyst.email }})</h6>
            <b-form-group label=" Tokens *" label-for="input-tokens">
                <b-form-input
                    v-model="tokens_to_add"
                    id="input-tokens"
                    name="input-tokens"
                    v-validate="{required: true}"
                    :state="validateState('input-tokens')"
                    aria-describedby="input-tokens-live-feedback"
                    size="sm"
                    placeholder=" Tokens"
                ></b-form-input>
                <b-form-invalid-feedback id="input-tokens-live-feedback">{{ veeErrors.first('input-tokens') }}</b-form-invalid-feedback>
            </b-form-group>
            <n-button class="btn-simple ncm-btn" @click.native="distributeTokensAnalyst()">
            {{$t('UsersManagement.Modals.Invite.Send')}} 
            </n-button>
            <n-button class="btn-simple ncm-btn-danger"  @click.native="modals.add = false">
                {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }} 
            </n-button>
        </modal>
    <!-- Modal delete  -->
    <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t('ProjectsManagement.Delete') }}</p>
        <template slot="footer">
            <n-button type="neutral" link @click="deleteElement">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
            </n-button>
            <n-button type="neutral" link @click="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
            </n-button>
        </template>
    </modal>
    
    </div>
</template>
<script>
import { Badge,  Button, BuInfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
import ProjectsDataService from '../../services/ProjectsDataService'
import {Table, TableColumn, Tooltip} from 'element-ui';
import OrganizationsDataService from '../../services/OrganizationsDataService';
import WalletDataServices from '../../services/WalletDataServices';
export default {
    components : {
        Card,
        Modal,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Tooltip.name]: Tooltip,
        [Button.name]: Button,
        [FormGroupInput.name]: FormGroupInput,
    },
    data() {
        return {
            role: null,
            loading: false,
            modals: {
                modify: false,
                delete: false,
                create: false,
                assign:false,
                add: false
            },
            value_analysts: '',
            project: {
            user_id: null
            },
            shared_wallet: true,
            automatic_renewal:true,
            selectedAnalysts: [],
            is_active: false,
            currentPage: 1,
            perPage: 10,
            currentPageAnalyst: 1,
            perPageAnalyst: 3,
            currentPageInvitations: 1,
            perPageInvitations: 3,
            currentPageLayers: 1,
            perPageLayers: 3,
            user: {},
            name: null,
            description: null,
            organization_id: 31,
            user_id: null,
            proj: [],
            projects: [],
            selectedElement: null,
            selectedAnalyst: null,
            organizations: [
                { id: "31", name:'Organization 1' },
                { id: "26", name:'Organization 2' },
            ],
            headers:[
                // { key:'organization_id', label: 'ID' },
                { key:'name',label: this.$t('DashBoardStaff.Table.Name'), sortable: true },
                { key:'description',label: this.$t('Languages.Table.Description'), sortable: true },
                { key:'project_manager.email',label: this.$t('Languages.Table.ProjectManager'), sortable: true },
                { key:'is_active',label: this.$t('ExternalOrganizations.Active'), sortable: true },
                { key:'share_wallet',label: this.$t('AddProject.Form.SharedWallet'), sortable: true},
                { key:'tokens_available',label: this.$t('AddProject.Form.TokensAvailable'), sortable: true },
                { key:'createdAt',label: this.$t('Languages.Table.CreatedAt'), sortable: true },
                { key:'finished_at',label: this.$t('Languages.Table.FinishedAt'), sortable: true},
                { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
            ],
            headersAnalyst:[
                // { key:'organization_id', label: 'ID' },
                { key:'name',label:'Name', sortable: true },
                { key:'description',label:'Description', sortable: true },
                { key:'is_active',label:'Active', sortable: true },
                { key:'project_manager.email',label: this.$t('Languages.Table.ProjectManager'), sortable: true },
                { key:'tokens_available',label:'Tokens available', sortable: true },
                { key:'createdAt',label:'Created At', sortable: true },
                { key:'finished_at',label:'Finished At', sortable: true },
                { key: 'options', label: 'Actions', thClass: 'position-sticky' }
            ],
            headersProjectManager:[
                { key:'name',label:'Name', sortable: true },
                { key:'surname',label:'Surname', sortable: true },
                { key:'email',label:'Email' , sortable: true},
                { key:'createdAt',label:'Created At', sortable: true },
                { key: 'options', label: 'Actions', thClass: 'position-sticky' }
            ],
            headersReports:[
                { key:'id',label:'ID', sortable: true },
                { key:'name',label:'Name', sortable: true },
                { key:'user',label:'User' , sortable: true },
                { key:'createdAt',label:'Created at' , sortable: true }
            ],
            headersProjectAnalyst:[
                { key:'name',label:'Name', sortable: true },
                { key:'surname',label:'Surname', sortable: true },
                { key:'email',label:'Email' , sortable: true},
                { key:'tokens_available',label:'Tokens available', sortable: true},
                { key:'createdAt',label:'Created At', sortable: true },
                { key: 'options', label: 'Actions', thClass: 'position-sticky' }
            ],
            headersInvitations:[
                { key:'user.name',label:'Name', sortable: true },
                { key:'user.surname',label:'Surname', sortable: true  },
                { key:'user.email',label:'Email', sortable: true },
                { key:'createdAt',label:'Created At', sortable: true },
                { key: 'options', label: 'Actions', thClass: 'position-sticky' }
            ],
            search: '', 
            auxOrganizationID: -99,
            auxOrganizationLS: [],
            allOpenRows: [],
            users: [],
            usersWithoutMe: [],
            tokens_to_add: '',
            dataWallet: [],
            wms_layers: [],
            headersLayers: [
                { key: 'internal_name', label: 'Name', sortable: true},
                { key: 'visible_name', label: 'Visble name', sortable: true},
                { key: 'projection', label: 'Projection', sortable: true},
            ],
            projects: [],
        }
    },
    computed : {
        criteria() {
            return this.search.trim().toLowerCase()
        },
        totalPages () {
            return this.projects.length
        },
        totalPagesAnalyst () {
            return this.selectedElement.analysts.length
        },
        totalReports () {
            return this.selectedElement.reports.length
        },
        totalPagesInvitations () {
            if(this.selectedElement.invitations)
                return this.selectedElement.invitations.length
            else
                return 0
        },
        totalPagesLayers () {
            return this.selectedElement.layers.length
        },
        computedTokensToTransfer () {
            return this.selectedElement.tokens_available - this.tokens_to_add
        },
        computedTokensToCreate () {
            return this.dataWallet.balance - this.project.ammount
        },
    },
    watch: {
        $orgSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.organization_id = newValue.id
            this.checkExternalRole(this.$store.state.auth.user)
            this.getData()
        },
        $projectSelected(newValue){
            console.log('Project selected changed!', newValue)
        },
        $roleSelected(newValue){
            console.log('role selected changed!', newValue)
            this.checkExternalRole(this.$store.state.auth.user)
            this.getData()
        }
    },
    created () {
        this.organization_id = this.$orgSelected.id
        this.role = this.$roleSelected.user_role
        this.checkExternalRole(this.$store.state.auth.user)
        this.getData()
    },
    methods: {
        searchName (id) {
            console.log('ID', id)
            console.log(this.users.find(f => f.id == id))
            return this.users.find(f => f.id == id).name
        },
        searchSurname (id) {
            return this.users.find(f => f.id == id).surname
        },
        searchEmail (id) {
            return this.users.find(f => f.id == id).email
        },
        availableOptions(value_analysts) {
            var values = this.users.map(f => f.id)
            var without_selected = values.filter(f => !this.selectedAnalysts.includes(f))
            var invited = this.selectedElement.invitations.map(f => f.user_id)
            console.log('INVITED', invited)
            var without_invited = without_selected.filter(f => !invited.includes(f))
            console.log('WITHOUT INVITED', without_invited)
            var analysts = this.selectedElement.analysts.map(f => f.id)
            var without_analysts = without_invited.filter(f => !analysts.includes(f))
            console.log('WITHOUT INVITED AND ANALYSTS', without_analysts)
            return without_analysts
        },
        openCreateModal(){
            this.project = {
                user_id: null
            }
            this.modals.create = true
        },
        distributeTokensAnalyst () {
            let data = {
                organization_id: this.organization_id,
                level: 'analyst',
                tokens: this.tokens_to_add,
                project_id: this.selectedElement.id,
                user_id: this.selectedAnalyst.id
            }

            if (this.tokens_to_add == null || this.tokens_to_add < 0){
                this.$swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: this.$t('Alerts.ErrorTokensNegative')
                })
                return
            }

            if(this.selectedElement.tokens_available < this.tokens_to_add){
                this.$swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: this.$t('Alerts.ErrorNoTokensToTransfer')
                })
                return
            }
            WalletDataServices.distributeTokens(data).then(
                Response => {
                    console.log(Response)
                    this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.TokensAddedSuccessfully'),
                                text: this.$t('Alerts.AddedToAnalyst')
                            }).then(() => {
                                this.modals.add = false
                                this.getData()
                            });

                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        checkExternalRole(user){
            if (this.$roleSelected) {
                if (this.$roleSelected.user_role) {
                    console.log('Role selected', this.$roleSelected)
                    this.role = this.$roleSelected.user_role
                }
                
            }
        },
        inviteAnalysts() {
            const objectArray = this.selectedAnalysts.map(id => ({ id }));

            var data = {
                project_id: this.selectedElement.id,
                users: objectArray
            }
            console.log('LOS ANALISTAS SON', data)

            this.loading = true;

            ProjectsDataService.inviteAnalysts(data).then(
                Response =>{
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.AnalystInvited')
                    }).then(() => {
                        this.modals.assign = false
                        this.getData()
                        // for(var invitation of Response.data.invitations){
                            //this.assignAnalyst(invitation)
                        // }
                    });
                    this.loading = false;
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                    this.loading = false;
                }
            )
        },
        assignAnalyst(invitation) {
            console.log(this.selectedElement.id)
            console.log(this.selectedAnalysts)
            var data = {
                project_id: invitation.project_id,
                user_id: invitation.user_id
            }
            console.log(data)
            ProjectsDataService.SetAnalysts(data).then(
                Response =>{
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.AnalystAssigned'),
                    }).then(() => {
                        this.modals.assign = false
                        this.getData()
                    });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        selectRow (items) {
            
            this.selectedElement = items[0]
        },
        sortingChanged(item){
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                this.$set(ele, '_showDetails', !ele._showDetails)
                }
            })
        },
        handleRowClicked (item) {
            console.log(this.role)
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                this.$set(ele, '_showDetails', !ele._showDetails)
                }
            })
            this.allOpenRows = []
            this.$set(item, '_showDetails', !item._showDetails)
            this.allOpenRows.push(item)
            this.selectedElement = item
        },
        async getData () {
            if (!this.organization_id) {
                return
            }
            console.log('============== get tokens projects mngmnt', this.$tokensValue)
            await WalletDataServices.getTokens(this.organization_id).then(
                    Response => {
                        this.dataWallet = Response.data
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
            )
            console.log(this.organization_id)
            console.log(this.$roleSelected)

        await OrganizationsDataService.findByOrganizationId(this.organization_id, true).then(
            Response => {
                this.users = Response.data.data
                
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            await ProjectsDataService.findProjectsByOrganizationId(this.organization_id, this.role).then(
                Response => {
                    this.projects = Response.data.data
                    console.log('LOS PROYECTOS OBTENIDOS SON:', this.projects)
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        createProject() {
            this.$validator.validateAll(['input-name', 'input-description', 'input-manager']).then(result => {
                if (!result) {
                    return
                }   

                var ammount = this.project.ammount
                var data = {
                    name : this.project.name,
                    description : this.project.description,
                    share_wallet: this.shared_wallet,
                    ammount: ammount,
                    organization_id: this.organization_id,
                    user_id: this.project.user_id,
                    project_manager_automatic_renewal: this.automatic_renewal,
                }
                // Endpoint que pasandole: nombre de proyecto, descripcion, shared_wallet (true o false, si es true, pasar ammount tokens(opcionales, solo valor si shared_wallet es false)),
                // organization_id, user_id, project_manager, y se crea el nuevo proyecto, sin analistas

                ProjectsDataService.SetProject(data).then(
                    Response => {
                        console.log(Response.data)
                        this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.ProjectCreatedAndAssociated'),
                            }).then(() => {
                                let new_tokens = parseFloat(this.$tokensValue) - ammount
                                if(new_tokens > 0)
                                    this.$tokensValue = new_tokens.toFixed(2)
                                else
                                    this.$tokensValue = '0.00'
                                this.editing = false
                                this.modals.create = false
                                this.getData()
                            });
                    },
                    Error => {
                        console.log('Error al crear y asociar project')
                        this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorProjectCreatedAndAssociated'),
                            }).then(() => {
                                this.getData()
                                this.editing = false
                                this.modals.create = false
                            });
                    }
                )
            })
        },
        updateElement(){
        this.$validator.validateAll(['input-name-modify', 'input-description-modify']).then(async result => {
            if (!result) {
                return
            }
            console.log(this.selectedElement)
            ProjectsDataService.update(this.selectedElement).then(
                async response => {
                    console.log(response.data)
                    this.modals.modify = false
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.ProjectModified'),
                        text: ''
                        }).then(() => {
                            this.getData()
                    });

                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
            }
            )
        })
        },
        openAssignTokensModal(element){
            console.log(element)
            this.selectedAnalyst = {...element}
            this.modals.add = true
        },
        openEditModal(element){
            console.log(element)
            console.log('DATOS PARA COGER EL ELEMENT', element)
            this.selectedElement = {...element}
            this.modals.modify = true
        },
        openAssignModal (element) {
            this.selectedElement = {...element}
            this.selectedAnalysts = element.analysts.map(f => f.id);
            console.log('LOS ANALISTAS SON', this.selectedAnalysts)
            this.modals.assign = true
        },
        openDeleteModal(element){
            console.log(element)
            this.selectedElement = element
            this.modals.delete = true
        },
        deleteElement(){
        ProjectsDataService.finish(this.selectedElement.id).then(
                async response => {
                    console.log(response.data)
                    this.modals.delete = false
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('ProjectsManagement.Delete'),
                    text: ''
                    }).then(() => {
                        this.getData()
                    });
    
                },
                error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message
                })
            })
        },
        openMap(project){
            console.log('ABRIMOS EL MAPA CON EL PROYECTO SELECCIONADO EN LA TABLA', project.id, 'TENEMOS EN EL SIDEBAR EL PROJECTO SELECCIONADO: ', this.$projectSelected.id)
            if (project.id != this.$projectSelected.id){
                this.$swal.fire({
                    icon: 'info',
                    text: this.$t('MsgAlertGoMap'),
                }).then(() => {
                    this.$projectSelected = project
                    this.$router.push('/maps')
                })
            } else {
                this.$router.push('/maps')
            }
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
    },    
}
</script>

<style>

.user-details {
    margin-bottom: 0px;
    font-size: 11px
}

.user-details-name{
    margin-bottom: 0px;
    font-size: 14px;
}

.pagination-select{
    min-width: 60px !important;
}

@media (max-width: 1307px) {
    .page-header-bs {
        margin-top: 70px;
    }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>
