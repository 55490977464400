<template>
  <div class="page-header signup-page section-image">
        <div class="page-header-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg17.jpg') +')'}"/>
        <div class="content-page">
            <div class="container">
              <div class="not-found-content">
                <b-row>
                  <b-col class="text-center">
                    <h1 class="not-found-title">404</h1>
                    <h2 class="not-found-subtitle">NOT FOUND</h2>
                  </b-col>
                </b-row>
              </div>
            </div>
        </div>
        <footer-logo background-color="#ffffff"/>
    </div>
</template>
<script>
  import { API_KEY } from '@/constants'
  import FooterLogo from '@/layout/FooterLogo';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  export default {
  name: 'not-found',
  components: {
    FooterLogo,
  },
  data() {
      return {
        
      }
  },
  
  created() {
    
  },
  methods:{

  }
  }
  </script>
<style scoped>
.content-page{
  height: 100%;
  width: 100%;
  position: absolute;
}

.container{
  display: flex;
  align-items: center;
}

.not-found-content{
  width: 100%;
}

.not-found-title {
  font-size: 10rem;
  font-weight: bold;
  color: #ffffff5e;
  margin-bottom: 0%;
}

.not-found-subtitle {
  font-size: 2.5rem;
  font-weight: lighter;
  color: #ffffff94;
}
</style>