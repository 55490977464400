<template>
    <navbar
      position="fixed"
      :transparent="transparent"
      :color-on-scroll="colorOnScroll"
      menu-classes="ml-auto mn-0 mr-0 pb-0"
    >
    <template>
      <router-link 
        class="nav-item"
        to="/"
      > 
        <img
          :src="$getImgUrlSite('LogoNoSpinOff.png')"
          style="width: 100%; max-width: 160px;"
          alt="LogoNCM"
        >
        <!-- <img 
          :src="$getImgUrlSite('LogoNoSpinOff-negative.png')"
          height="34;"
          width="200"
          alt="LogoNCM"
        > -->
      </router-link>
    </template>
    <template slot="navbar-menu">
      <!-- <drop-down
        tag="li"
        title="Components"
        icon="now-ui-icons design_app"
        class="nav-item"
      >
        <nav-link to="/components">
          <i class="now-ui-icons business_chart-pie-36" /> Components
        </nav-link>
        <a
          href="https://demos.creative-tim.com/vue-now-ui-kit-pro/documentation"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons design_bullet-list-67" /> Documentation
        </a>
      </drop-down>
      <drop-down
        tag="li"
        title="sections"
        icon="now-ui-icons files_paper"
        class="nav-item"
      >
        <nav-link
          class="dropdown-item"
          to="/sections#headers"
        >
          <i class="now-ui-icons shopping_box" /> Headers
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/sections#features"
        >
          <i class="now-ui-icons ui-2_settings-90" /> Features
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/sections#blogs"
        >
          <i class="now-ui-icons text_align-left" /> Blogs
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/sections#teams"
        >
          <i class="now-ui-icons sport_user-run" /> Teams
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/sections#projects"
        >
          <i class="now-ui-icons education_paper" /> Projects
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/sections#pricing"
        >
          <i class="now-ui-icons business_money-coins" /> Pricing
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/sections#testimonials"
        >
          <i class="now-ui-icons ui-2_chat-round" /> Testimonials
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/sections#contactus"
        >
          <i class="now-ui-icons tech_mobile" /> Contact Us
        </nav-link>
      </drop-down>

      <drop-down
        tag="li"
        title="Examples"
        icon="now-ui-icons design_image"
        class="nav-item"
      >
        <nav-link to="/about">
          <i class="now-ui-icons business_bulb-63" /> About-us
        </nav-link>
        <nav-link to="/blog-post">
          <i class="now-ui-icons text_align-left" /> Blog Post
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/blog-posts"
        >
          <i class="now-ui-icons design_bullet-list-67" /> Blog Posts
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/contact"
        >
          <i class="now-ui-icons location_pin" /> Contact Us
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/landing"
        >
          <i class="now-ui-icons education_paper" /> Landing Page
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/login"
        >
          <i class="now-ui-icons users_circle-08" /> Login Page
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/pricing"
        >
          <i class="now-ui-icons business_money-coins" /> Pricing
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/ecommerce"
        >
          <i class="now-ui-icons shopping_shop" /> Ecommerce Page
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/product"
        >
          <i class="now-ui-icons shopping_bag-16" /> Product Page
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/profile"
        >
          <i class="now-ui-icons users_single-02" /> Profile Page
        </nav-link>
        <nav-link
          class="dropdown-item"
          to="/signup"
        >
          <i class="now-ui-icons tech_mobile" /> Signup Page
        </nav-link>
      </drop-down> -->
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          target="_blank"
        >
          <p>Cookies policy</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          target="_blank"
        >
          <p>Privacy policy</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link" 
          href="#"
          target="_blank"
        >
          <p>Data protection policy</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link"
          href="#who"
        >
        <p class="nav-landing" style="color: black !important">{{ $t("LandingPage.Who.title") }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#whatwe"
        >
        <p class="nav-landing" style="color: black !important">{{ $t("LandingPage.WhatWeThinnk.title") }}</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link "
          href="#what"
        >
          <p class="nav-landing" style="color: black !important">{{ $t("LandingPage.What.title") }}</p>
        </a>
      </li>
      <!-- <li class="nav-item nav-landing">
        <a
          class="nav-link"
          href="#whot"
        >
        <p class="nav-landing">{{ $t("LandingPage.WhoT.title") }}</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link nav-landing"
          href="#contact"
        >
        <p class="nav-landing" style="color: black !important">{{ $t("LandingPage.Contact.title") }}</p>
        </a>
      </li>

      <!-- <li class="nav-item nav-landing">
        <b-button
          class="nav-link btn-neutral btn-success"
          target="_blank"
          @click="$router.push({ path: '/explore' })"
        >
          <p>EXPLORE</p>
        </b-button>
      </li>
      <li class="nav-item nav-landing">
        <b-button
          class="nav-link btn btn-success"
          target="_blank"
          @click="$router.push({ path: '/staff' })"
        >
          <p>STAFF</p>
        </b-button>
      </li> -->
      <!-- <li class="nav-item">
        <b-button
          class="nav-link btn btn-primary"
          @click="$router.push({ path: '/login' })"
          target="_blank"
        >
          <p>LOGIN</p>
        </b-button>
      </li> -->
      <div class="locale country-selector"></div>
      <div class="country-selector"><LocaleSwitcher /></div>
    </template>
  </navbar>
</template>

<script>
  import { DropDown, Navbar, NavLink } from '@/components';
  import { Popover } from 'element-ui'
  import Button from '../components/Button.vue';
  import LocaleSwitcher from "../components/LocaleSwitcher.vue";

  export default {
    name: 'MainNavbar',
    components: {
      Navbar,
      LocaleSwitcher,
    },
    props: {
      transparent: Boolean,
      colorOnScroll: Number,
    }
  }
</script>

<style scoped>
.landing-link {
    color: rgb(0 0 0) !important;
    font-weight: 800 !important;
}
</style>
