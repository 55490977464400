<template>
    <div class="wrapper blog-post" style="font-size: 12px;">
        <div class="page-header page-header-small ">
            <parallax
                class="page-header-image"
                :style="{'background-image': 'url(' + $getImgUrlSite('cov1.jpg') +')', opacity: '100%'}" 
            />
            <div class="content-center">
                <h1 class="title">
                    {{ $t("PostsLanding.Title") }}
                </h1> 
            </div>
        </div>
        <div class="section pb-0">
            <div class="container">
                <div class="row">
                    <div class="col  m-auto">
                        <b-form-group label="Show">
                            <b-form-select v-model="selectedCount" @change="updateDisplayedPosts" style="font-size: 12px;">
                                <b-form-select-option :value="10">10</b-form-select-option>
                                <b-form-select-option :value="15">15</b-form-select-option>
                                <b-form-select-option :value="20">20</b-form-select-option>
                                <b-form-select-option :value="filteredArticles.length">{{ $t("DashBoardStaff.Options") }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>                        
                    </div>
                    <div class="col m-auto">
                        <b-form-group label="Language">
                            <b-form-select v-model="languageFilter" style="font-size: 12px;">
                                <b-form-select-option :value="null"  append-top>{{ $t("PostsLanding.FilterL") }}</b-form-select-option>
                                <b-form-select-option v-for="language in languages" :key="language.id" :value="language.id">
                                    {{ language.name | capitalizeFirstLetter }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>                        
                    </div>
                    <div class="col m-auto">
                        <b-form-group label="Category">
                            <b-form-select v-model="categoryFilter"  style="font-size: 12px;">
                                <b-form-select-option :value="null" append-top>{{ $t("PostsLanding.FilterC") }}</b-form-select-option>
                                <b-form-select-option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                    </div>
                    <div class="col m-auto">
                        <b-form-group label="Visibility">
                            <b-form-select v-model="visibilityFilter"  style="font-size: 12px;">
                                <b-form-select-option :value="null" append-top>{{ $t("PostsLanding.FilterV") }}</b-form-select-option>
                                <b-form-select-option value="public">{{ $t("PostsLanding.Public") }}</b-form-select-option>
                                <b-form-select-option value="private">{{ $t("PostsLanding.Private") }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <!-- <div style="margin-top: 27px;">
                            <b-form-radio-group v-model="visibilityFilter" class="d-flex">
                                <b-form-radio value="null"><p style="font-size: 12px;margin-top: 3px;">{{ $t("PostsLanding.All") }}</p></b-form-radio>
                                <b-form-radio value="public"><p style="font-size: 12px;margin-top: 3px;">{{ $t("PostsLanding.Public") }}</p></b-form-radio>
                                <b-form-radio value="private"><p style="font-size: 12px;margin-top: 3px;">{{ $t("PostsLanding.Private") }}</p></b-form-radio>
                            </b-form-radio-group>
                        </div> -->
                    </div>
                    <div class="col d-flex">
                        <b-dropdown  class="my-custom-dropdown">
                            <template #button-content>
                                <span v-if="selectedOption">{{ selectedOption }}</span>
                                <span v-else>{{ $t("PostsLanding.OrderBy") }}</span>
                            </template>
                            <b-dropdown-item @click="selectOption('Name ↧')"> Name ↧</b-dropdown-item>
                            <b-dropdown-item @click="selectOption('Name ↥')"> Name ↥</b-dropdown-item>
                            <b-dropdown-item @click="selectOption('Date ↧')">Date ↧</b-dropdown-item>
                            <b-dropdown-item @click="selectOption('Date ↥')">Date ↥</b-dropdown-item>
                            <b-dropdown-item @click="selectOption('Category ↧')">Category ↧</b-dropdown-item>
                            <b-dropdown-item @click="selectOption('Category ↥')">Category ↥</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title">
                            {{ $t("PostsLanding.PostsList") }}
                        </h2>
                        <br>
                        <card v-for="article in filteredArticlesVisibility" :key="article.id" type="blog" plain id="cards">
                            <div class="row">
                                <!-- Public/Private Button Section -->
                                <div class="col-12 col-md-4" id="visibilityButton">
                                <b-button v-if="article.visibility" class="public-private-posts">
                                    {{ $t("PostsLanding.Public") }} <b-icon icon="eye"></b-icon>
                                </b-button>
                                <b-button v-else class="public-private-posts">
                                    {{ $t("PostsLanding.Private") }} <b-icon icon="eye-slash"></b-icon>
                                </b-button>
                                </div>
                            </div>

                            <div class="row">
                                <!-- Image or Video Section -->
                                <div class="col-12 col-md-5 mb-3 mb-md-0">
                                <template v-if="article.image != null && isVideo(article.image.url)">
                                    <video
                                    class="img img-raised rounded img-fluid"
                                    controls
                                    style="width: 100%; height: auto;"
                                    >
                                    <source :src="getVideoUrl(article.image.url)" type="video/mp4">
                                    </video>
                                </template>
                                <template v-else>
                                    <template v-if="article.image">
                                    <img class="img img-raised rounded img-fluid" :alt="article.image.description" :src="getImgUrl(article.image.url)">
                                    </template>
                                    <template v-else>
                                    <img v-if="article.external_image == null" class="img img-raised rounded img-fluid" alt="Image default" :src="$getImgUrlSite('blurred-image-1.jpg')">
                                    <img v-else class="img img-raised rounded img-fluid" :alt="article.external_image_description" :src="article.external_image">
                                    </template>
                                </template>
                                </div>

                                <!-- Article Content Section -->
                                <div class="col-12 col-md-7">
                                <div class="row">
                                    <div class="col">
                                    <h6 class="category text-primary">
                                        <b-icon icon="pie-chart-fill"></b-icon> {{article.category_post_name}}
                                    </h6>

                                    <router-link v-if="$store.state.auth.status.loggedIn" :to="{name: 'Post', params: {category: article.category_post_name.toLowerCase(), title_slug: article.title_slug, from_page: currentPage}}">
                                        <h3 class="card-title link-hover">
                                        {{article.title}}
                                        </h3>
                                    </router-link>
                                    <p :title="article.subtitle" v-if="article.subtitle" class="card-description">
                                        <b>
                                        <template v-if="article.subtitle.length > 100">
                                            {{article.subtitle.substr( 0, 100) + '…'}}
                                        </template>
                                        <template v-else>
                                            {{article.subtitle}}
                                        </template>
                                        </b>
                                    </p>
                                    <p :title="article.public_content" v-if="article.public_content">
                                        <template v-if="article.public_content.length > 100">
                                        {{article.public_content.substr(0, 100) + '…'}}
                                        </template>
                                        <template v-else>
                                        {{article.public_content}}
                                        </template>
                                    </p>
                                    </div>
                                </div>

                                <!-- Date and Read More Section -->
                                <div class="row align-items-center">
                                    <div class="col-8 col-md-6">
                                    <p class="date-read-more-wrapper mb-0">
                                        <b>{{ article.updatedAt | formatDate }}</b>
                                    </p>
                                    </div>
                                    <div class="col-4 col-md-6 text-right">
                                    <router-link class="read-more-link float-right" :to="{ name: 'Post', params: { category: article.category_post_name.toLowerCase(), title_slug: article.title_slug, from_page: currentPage }}">
                                        {{ $t("Explore.ReadMore") }}
                                    </router-link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </card>
                        <div>
                            <b-pagination v-model="currentPage" v-if="filteredArticles.length > perPage" :total-rows="filteredArticles.length" :per-page="perPage"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import BlogDataService from '../../services/BlogDataService'; 
import CategoriesPosts from '../../services/CategoriesPostsDataServices'  
import LanguageDataService from '../../services/LanguageDataService'

import { Card, DropDown } from '@/components';
export default {
    components: {
    Card,
    DropDown
    },
    data() {
        return {
            selectedOption: null,
            selectedCount: 10,
            posts: [],
            categories: [],
            languages: [],
            currentPage: 1,
            perPage: 10,
            languageFilter: null,
            categoryFilter: null, 
            allPosts: [],
            visibilityFilter: null
        }
    },
    watch: {
        selectedCount() {
            this.updatePageSize();
        },
        perPage() {
            this.currentPage = 1;
        }
    },

    filters: {
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    computed: {
        totalPages() {
            const totalCount = this.filteredArticles.length;
            const totalPages = Math.ceil(totalCount / this.perPage);
            return totalPages > 0 ? totalPages : 1;
        },
        filteredArticles() {
            let filteredArticles = this.allPosts;
            if (this.languageFilter) {
                filteredArticles = filteredArticles.filter(
                    (article) => article.language_id === this.languageFilter
                )
            }

            if (this.categoryFilter) {
                filteredArticles = filteredArticles.filter(
                    (article) => article.category_post_id === this.categoryFilter
                )
            }

            return filteredArticles;
        },
        // articlesShow() {
        //     const startIndex = (this.currentPage - 1) * this.perPage;
        //     const endIndex = startIndex + this.perPage

        //     let displayedArticles = this.filteredArticles.slice(startIndex, endIndex)

        //     if (this.selectedOption === 'Date') {
        //         displayedArticles = displayedArticles.sort((a, b) => {
        //         return new Date(b.updatedAt) - new Date(a.updatedAt)
        //     });
        //     }

        //     return displayedArticles;
        // },
        articlesShow() {
        const startIndex = (this.currentPage - 1) * this.perPage;
        const endIndex = startIndex + this.perPage;

        let displayedArticles = this.filteredArticles.slice(startIndex, endIndex);

        if (this.selectedOption === 'Date ↧') {
            displayedArticles = displayedArticles.sort((a, b) => {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
            });
        } else if (this.selectedOption === 'Date ↥') {
            displayedArticles = displayedArticles.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
            });
        } else if (this.selectedOption === 'Name ↧') {
            displayedArticles = displayedArticles.sort((a, b) => {
            const nombreA = a.title.toLowerCase();
            const nombreB = b.title.toLowerCase();
            if (nombreA < nombreB) return -1;
            if (nombreA > nombreB) return 1;
            return 0;
            });
        } else if (this.selectedOption === 'Name ↥') {
            displayedArticles = displayedArticles.sort((a, b) => {
            const nombreA = a.title.toLowerCase();
            const nombreB = b.title.toLowerCase();
            if (nombreA > nombreB) return -1;
            if (nombreA < nombreB) return 1;
            return 0;
            });
        }else if (this.selectedOption === 'Category ↧') {
        displayedArticles = displayedArticles.sort((a, b) => {
        const categoriaA = a.category_post_name.toLowerCase();
        const categoriaB = b.category_post_name.toLowerCase();
        if (categoriaA < categoriaB) return -1;
        if (categoriaA > categoriaB) return 1;
        return 0;
        });
    } else if (this.selectedOption === 'Category ↥') {
        displayedArticles = displayedArticles.sort((a, b) => {
        const categoriaA = a.category_post_name.toLowerCase();
        const categoriaB = b.category_post_name.toLowerCase();
        if (categoriaA > categoriaB) return -1;
        if (categoriaA < categoriaB) return 1;
        return 0;
        });
    }

        return displayedArticles;
        },

        filteredArticlesVisibility() {
            let filteredArticles = this.articlesShow; // Obtén los artículos filtrados previamente

            if (this.visibilityFilter === 'public') {
            filteredArticles = filteredArticles.filter((article) => article.visibility == true);
            } else if (this.visibilityFilter === 'private') {
            filteredArticles = filteredArticles.filter((article) => article.visibility == false);
            }

            return filteredArticles;
        }
        
    },
    created () {
        this.getData()
    },
    mounted(){
        if(this.$route.params.page)
            this.currentPage = this.$route.params.page
    },
    methods: {
        isVideo(url) {
            const videoExtensions = ['mp4', 'webm', 'ogg']; // Agrega las extensiones de video que deseas comprobar
            const extension = url.split('.').pop();
            return(videoExtensions.includes(extension.toLowerCase()))
        },
        getVideoUrl(url) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${url}?withheaders=yes`
            // try {
            //     if(url){
            //         return require('../../assets/multimedia/blog/images/' + url);
            //     }
            // } catch {
            //     console.log('error loading video')
            // }
            // Construye la ruta de archivo local para el video
        },
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        updatePageSize() {
            // Actualizar el tamaño de página según el número seleccionado
            this.perPage = this.selectedCount;
            this.currentPage = 1;
        },
        previousPage() {
            // Ir a la página anterior
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            // Ir a la siguiente página
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        getData() {
            this.perPage = this.selectedCount;
            BlogDataService.findAllPostLanding().then(
                Response => {
                    console.log('BLOGDATASERVICES:', Response.data.data)
                    this.posts = Response.data.data
                    this.allPosts = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })                    
                    if (Error.response.status == '401') {
                        this.$router.push('/explore')
                    }
                }
            )
            CategoriesPosts.findAll().then(
                Response => {
                    console.log('CATEGORIESDATASERVICES:', Response.data.data)

                    this.categories = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            LanguageDataService.findAll().then(
                async Response => {
                    this.languages = Response.data
                },
                Error => {
                    this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
            )
        },
        selectOption (option) {
            this.selectedOption = option;
        },
        updateDisplayedPosts() {
            this.currentPage = 1;
        },
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
    }
};

</script>
<style>
.my-custom-dropdown {
  position: relative;
  margin-top: 17px;
  float:right;
  margin: auto !important;
  
}
 
.my-custom-dropdown .dropdown-toggle {
  background-color: #5D6176;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.my-custom-dropdown .dropdown-toggle:hover {
    background-color: #5D6176 !important;
}

.read-more-link {
  text-decoration: none;
  font-size: 13px ;
  color: inherit;
  position: relative;
  font-weight: bold;
  color: #5D6176!important;
}

.read-more-link:hover {
  color: #5D6176;
}

.read-more-link:hover::after {
  opacity: 1;
}
.link-hover:hover {
  text-decoration: underline;
}

#visibilityButton {
  display: flex;
  justify-content: flex-end;
  margin-left: 7%;
}
@media (max-width: 991px) {
    #infoPost {
        margin-top: 20px;
    }
    /* #visibilityButton {
        margin-left: 150px!important;
    } */
}

@media (max-width: 1199px) {
    #visibilityButton {
        margin-left: 50px;
    }
}

@media (max-width: 767px) {
    #visibilityButton {
        margin-left: 0px!important;
    }
    .custom-cols {
        gap: 2px;
    }
}

.date-read-more-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media (max-width: 991px) {
    .custom-cols {
        gap: 2px!important;  
    }
}

@media (min-width: 768px) {
    .date-read-more-wrapper .read-more-link {
        display: none;
    }
}

</style>


