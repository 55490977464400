<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <button @click="generatePDF">PDF</button>
            <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="920"
                filename="hee hee"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
				:html-to-pdf-options="htmlToPdfOptions"
                ref="html2Pdf"
            >
            <section slot="pdf-content" ref="pdf-content" style="background-color: white !important; padding: 90px !important; padding-top: 0px !important">
             <!-- <div style="text-align: center; background-color: #f2f2f2; padding: 10px; border-bottom: 1px solid #ccc;">
                    BASIC VALUE REPORT

                    Client: Ecovalue Consultants, S.L.
                    Date: August 14 2022
                    Report ECV220100014G-01

                </div>  -->
                
                <a>________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">1. Valued area</h5>
                <a style="">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800;">1.1. Coordinates (external rectangle limits)</h5>
                
                <table style="width: 100%; border: 1px solid black; font-size: 12px;">
                    <tr style="text-align: left;">
                        <th style="width: 70px; padding-left: 7px; border: 1px solid black;"></th>
                        <th style="border: 1px solid black; padding-left: 7px; font-weight: 500;">X</th>
                        <th style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Y</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SW</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px; ">53.07873248500005</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.06162968600006</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">NW</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">53.16795223600008</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.07836025500006</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">NE</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">53.15787688700004</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.22705000900004</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SE</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">53.06868090500007</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.21001812000009</td>
                    </tr>
                </table>

                <h5 style="font-size: 12px; font-weight: 800;  margin-top: 15px;">1.2. Map</h5>
                <div >
                     <!--<img src="" alt="Map Image" style="width:100%; height: auto;"> -->
                     
                     <!-- <div v-show="isMapLoaded" id="mapPDF" ref="mapPDF" class="map" style="height: 200px">



                    </div> -->

                </div>

                <h5 style="font-size: 12px; font-weight: 800;">1.3. Valued area, by land use</h5>
                <table style="width: 100%; border: 1px solid black; font-size: 12px;">
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px; ">CR.1 Rainfed herbaceous crops (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">80.20</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px;">GR.2 Temperate grasslands, savannas and shrublands (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">135.91</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px;">TC.4 Temperate broadleaf and mixed tree-covered areas (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">336.27</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px;">SV.1 Sparsely natural vegetated areas (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">109.97</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;">Total valued area (Ha)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">662.35</th>
                    </tr>
                </table>

                <div class="html2pdf__page-break"></div> 

                <a style="margin-top: 15px;">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">2. Value chart</h5>
                <a style="">________________________________________________________________________________________</a>

                <div style="margin-top: 10px;">
                   <!-- <img src="" alt="Value Chart" style="width:100%; height: auto;">  -->
                   <BarChart :chartData="chartData" :options="chartOptions" />
                   <!-- <BoxPlot /> -->
                   <!-- <BarChart2/> -->
                </div>

                <p style="font-size: 12px; text-align: justify;">Bar width shows the share of land uses in the valued area. Bar height shows highest, lowest and expected (adjusted median) value of land uses in the valued area. 
                    See Annex for full methodology and data.
                </p>

                <div class="html2pdf__page-break"></div> 

                <a style="margin-top: 15px;">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">3. Rating chart (más Adelante, ahora no está programado)</h5>
                <a style="">________________________________________________________________________________________</a>

                <div >
                    <!-- <img src="" alt="Rating Chart" style="width:100%; height: auto;"> -->
                    <p style="width: 20%; margin: auto; margin-top: 10px;">Relative rating</p>
                    <RadarChart style="margin-bottom: 22px" :chartData="radarChartData" :options="radarChartOptions" />
                    <p style="width: 51%; margin: auto; font-size: 9px;">100 index level = values of 90 percentile sites in same bioclimatic zone
                        R100 = best in 100km radio <br>
                        R300 = best in 300km radio
                    </p>

                </div>

                <p style="margin-top: 35px; font-size: 12px; text-align: justify;">See Annex for methodology and data.</p>

                

                <a style="margin-top: 15px;">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">6. Annex</h5>
                <a style="">________________________________________________________________________________________</a>
                

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.1 Methodology summary (please refer to the knowledge base for complete information)</h5>

                <p style="font-size: 12px; text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

                <div class="html2pdf__page-break"></div>  

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.2 Value range by hectare of land use of the ecosystem services in the valued area</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">CR.1 Rainfed herbaceous crops</th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">225.61</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">716.78</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">2,095.52</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">177.58</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">256.14</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">356.28</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 3</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">44.07</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">83.28</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">16.16</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">40.76</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total CR.1 Rainfed herbaceous crops</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">463.42</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,051.79</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">2,575.84</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">GR.2 Temperate grasslands, savannas and shrublands</th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">171.04</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">606.70</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">1,902.46</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">193.77</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">274.83</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">399.45</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 3</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">44.07</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">83.28</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">16.16</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">40.76</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total GR.2 Temperate grasslands, savannas and shrublands</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">425.04</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">960.4</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">2,425.95</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">TC.4 Temperate broadleaf and mixed tree-covered areas</th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">221.86</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">926.63</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">3,888.02</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">315.33</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">463.35</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">852.65</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">69.89</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">94.43</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">176.51</td>
                    </tr>

                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.80</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">29.93</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">78.94</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total TC.4 Temperate broadleaf and mixed tree-covered areas</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">625.88</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,514.34</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">4,996.12</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <div class="html2pdf__page-break"></div>  

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">SV.1 Sparsely natural vegetated areas</th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">20.60</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">200.25</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">371.34</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">87.32</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">134.30</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">114.27</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">16.04</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">24.53</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">20.57</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">1.78</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">5.41</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">5.60</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total SV.1 Sparsely natural vegetated areas</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">125.74</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">364.49</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">511.78</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">Median values summary</th>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Area (Ha)</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Provisioning services</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Regulating and maintenance services</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Cultural services</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">TOTAL</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1 Rainfed herbaceous crops</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">80.20</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">716.78</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">256.14</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,051.79</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.2 Temperate grasslands, savannas and shrublands</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">135.91</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">606.7</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">274.83</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">960.4</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">TC.4 Temperate broadleaf and mixed tree-covered areas</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">336.27</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">926.63</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">463.35</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">94.43</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">29.93</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1514.34</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">SV.1 Sparsely natural vegetated areas</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">109.97</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">200.25</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">134.3</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">24.53</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">5.41</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">364.49</th>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">TOTAL VALUED AREA (Ha)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">662.35</th>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">AVERAGE VALUE OF VALUED AREA (EUR / Ha / Yr)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">714.97</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">344.94</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">70.52</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">23.32</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,153.76</th>
                    </tr>
                </table> 

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.3. Aggregate value range of the ecosystem services in valued area</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Area (Ha)</td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1 Rainfed herbaceous crops</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">80.20</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">37,166.28</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">84,353.56</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">206,582.37</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.2 Temperate grasslands, savannas and shrublands</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">135.91</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">57,767.19</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">130,527.96</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">329,710.86</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">TC.4 Temperate broadleaf and mixed tree-covered areas</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">336.27</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">210,464.67</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">509,227.11</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">1,680,045.27</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">SV.1 Sparsely natural vegetated areas </td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">109.97</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">13,827.63</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">40,082.97</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56,280.45</td>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">TOTAL VALUED AREA (Ha)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">662.35</th>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">AVERAGE VALUE OF VALUED AREA (EUR / Ha / Yr)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">481.96</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,153.76</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">3,431.15</th>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">TOTAL VALUE OF VALUED AREA (EUR / Yr)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">319,225.77</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">764,191.60</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">2,272,618.95</th>
                    </tr>
                </table>

                <div class="html2pdf__page-break"></div> 

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.4 Adjustment parameters in the valued area</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;">Ecosystem</th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">Parameter</th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Bird-related protected area </td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cow breeding</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Ecological agriculture</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Existence of fences</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Existence of trails</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Flat land</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Herbaceous plant</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">High growth</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Horse breeding</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Land productivity</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4 – SV.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Links with territory</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Low-till agriculture</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Migration birds area</td>
                    </tr>

                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Mosaic landscape</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Semi-woody plant</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4 – SV.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: autochthonous </td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: bluegrass</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: corn</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: fescues</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: rare</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: rye</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Traditional agriculture</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Visitor center</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Visitor center</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Woody plant</td>
                    </tr>
                </table>
                <div class="html2pdf__page-break"></div> 
                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.5 Land uses (from FAO) (solo los que se utilicen, y enlace para ver más información)</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Category of land use</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Land use</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.1</td>
                        <td rowspan="12" style="border: 1px solid black; text-align: left; padding-left: 7px;">Artificial surfaces</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Urban forests</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Urban parks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Neighborhood and private green spaces</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Urban gardens</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Isolated or lined trees</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Riverside banks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.7</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Green walls</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.8</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Green roofs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.9</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Sport facilities</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.10</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cemeteries</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.11</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Campings</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.12</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Road and railroad banks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1</td>
                        <td rowspan="5" style="border: 1px solid black; text-align: left; padding-left: 7px;">Crops</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Rainfed herbaceous crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Irrigated herbaceous crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Irrigated herbaceous crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Irrigated woody crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Multiple or layered crop</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.1</td>
                        <td rowspan="5" style="border: 1px solid black; text-align: left; padding-left: 7px;">Grassland</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical grasslands, savannas and shrublands</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate grasslands, savannas and shrublands</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Flooded grasslands and savannas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Montane grasslands and shrublands</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tundra</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.1</td>
                        <td rowspan="8" style="border: 1px solid black; text-align: left; padding-left: 7px;">Tree-covered areas</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical moist broadleaf tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical dry broadleaf tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical coniferous tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate broadleaf and mixed tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate riparian tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate coniferous tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.7</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Boreal tree-covered areas / taiga</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.8</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Mediterranean and sclerophyll tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SC.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Shrub-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.1</td>
                        <td rowspan="4" style="border: 1px solid black; text-align: left; padding-left: 7px;">Shrubs and/or herbaceous vegetation, aquatic or regularly flooded</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Peat wetlands / bogs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Riparian buffer</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Swamps</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Marshes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">MG.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Mangroves</td>
                    </tr>
                    
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Category of land use</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Land use</th>
                    </tr>
                    
                    
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SV.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Sparsely natural vegetated areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TB.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Terrestrial barren land</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Loose and shifting sand and/or dunes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TB.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Bare soil, gravels and rocks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SG.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Permanent snow and glaciers</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.1</td>
                        <td rowspan="4" style="border: 1px solid black; text-align: left; padding-left: 7px;">Inland waterbodies</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Lakes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Ponds</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Reservoirs</td>
                    </tr>

                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Rivers</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.1</td>
                        <td rowspan="6" style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal areas</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Estuaries</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Beaches</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cliffed or rocky coasts</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal dunes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Continental Shelf Sea</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Seagrass/algae beds</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IT.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal water bodies and inter-tidal</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal water bodies</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IT.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Inter-tidal areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">OC.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Oceans</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Coral reefs</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cold-water coral reefs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Warm-water coral reefs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">ME.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Multi-ecosystem</td>
                    </tr>
                </table>

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.6 Ecosystem services (UN reference system) (solo los que se utilicen, y enlace para ver más información)</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Provisioning services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.1</td>
                        <td rowspan="7" style="border: 1px solid black; text-align: left; padding-left: 7px;">Biomass provisioning services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Crop provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Grazed biomass provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Livestock provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Aquaculture provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Wood provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Wild fish and other natural aquatic biomass provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.7</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Wild animals, plants and other biomass provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.2.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Genetic material services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.3.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Water supply</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.4.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Other provisioning services</td>
                    </tr>
                    
                </table>
                <div class="html2pdf__page-break"></div> 
                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                <tr>
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Regulating and maintenance services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.1.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Global climate regulation services </td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.2.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Rainfall pattern regulation services (at sub-continental scale)</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.3.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Local (micro and meso) climate regulation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.4.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Air filtration services </td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.5.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Soil quality regulation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.6.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Soil and sediment retention services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Soil erosion control services</td>
                    </tr>
                </table>
                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Provisioning services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.6.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Landslide mitigation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.7.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Solid waste remediation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.8.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Water purification services (water quality regulation)</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Retention and breakdown of nutrients</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.8.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Retention and breakdown of other pollutants</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.9.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Water flow regulation services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Baseline flow maintenance services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.9.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Peak flow mitigation services</td>
                    </tr>
                </table>
                
                
                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Provisioning services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.10.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Flood control services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal protection services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.10.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">River flood mitigation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.11.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Storm mitigation services</td>
                    </tr>

                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.12.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Noise attenuation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.13.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Pollination services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.14.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Biological control services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Pest control services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.14.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Disease control services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.15.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Nursery population and habitat maintenance services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.16.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Other regulating and maintenance services</td>
                    </tr>
                    <tr>
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Cultural services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.1.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Recreation-related services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.2.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Visual amenity services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.3.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Education, scientific and research services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.4.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Spiritual, artistic and symbolic services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.5.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Other cultural services</td>
                    </tr>

                    <tr>
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Flows related to non-use values</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">N.1.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Ecosystem and species appreciation</td>
                    </tr>
                </table> 

            </section>
            
            </vue-html2pdf>

            <!-- <BarChart /> -->
        </b-container>  
    </div>
</template>
<script>
import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
import {Table, TableColumn, Tooltip} from 'element-ui';
import BarChart from '../../components/Charts/BarChart'
import RadarChart from '../../components/Charts/RadarChart'
//import BarChart2 from '../../components/Charts/BarChart2'
//import BoxPlot from '../../components/Charts/BoxPlot'
import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from 'html3pdf'

export default {
    components : {
        Card,
        Modal,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
        VueHtml2pdf,
        BarChart,
        RadarChart
        //BoxPlot,
        //BarChart2
    },
    data() {
        return {
            chartData: {
  labels: ["CR.1", "GR.2", "TC.4", "SV.1", "AVERAGE"],
  datasets: [
    {
      label: "EUR / Ha / Year",
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
        "rgba(255, 159, 64, 0.5)"
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)"
      ],
      borderWidth: 1,
      outlierColor: "#999999",
      data: [
        { min: 800, q1: 1000, median: 1200, q3: 1500, max: 2000 }, // CR.1
        { min: 600, q1: 900, median: 1000, q3: 1300, max: 1800 }, // GR.2
        { min: 1000, q1: 1800, median: 2000, q3: 2500, max: 3500 }, // TC.4
        { min: 100, q1: 200, median: 400, q3: 600, max: 900 }, // SV.1
        { min: 500, q1: 1500, median: 1500, q3: 1500, max: 2800 } // AVERAGE - Solo los bigotes
      ]
    }
  ]
},
chartOptions: {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true
    }
  },
  scales: {
    xAxes: [{
          maxBarThickness: 200,
        }
    ],
    x: {
      stacked: false,
      categoryPercentage: 0.5, // Ajusta el ancho general de las barras
      barPercentage: 0.5,   // Ajusta el ancho de las barras dentro de la categoría
      title: {
        display: true,
        text: "Category"
      },
      ticks: {
        autoSkip: false // Evita que se omitan etiquetas
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "EUR / Ha / Year"
      }
    }
  }
},
radarChartData: {
  labels: [
    'Average value',
    'Provisioning services',
    'Regulation services',
    'Cultural services',
    'Non-use services',
  ],
  datasets: [
  {
    label: 'Target',
    data: [85, 50, 60, 85, 90],
    fill: false,
    borderColor: '#AA221C',
  },
  {
    label: 'R300',
    data: [80, 70, 40, 105, 100],
    fill: false,
    borderColor: '#8B9BA8',
  },
  {
    label: 'R100',
    data: [60, 60, 40, 80, 70],
    fill: false,
    borderColor: '#C7C7C7',
  }
  
]
},
radarChartOptions:{
    scale: {
        ticks: {
            min: 0,
            max: 120
        }
    },
    elements: {
      line: {
        borderWidth: 3
      },
      point: {
        radius: 0
      }
    }
  },
            htmlToPdfOptions: {
                    margin: [15, 0, 25, 0],

					filename: `profile.pdf`,

					image: {
						type: 'jpeg', 
						quality: 1
					},

					enableLinks: true,

					html2canvas: {
						scale: 10,
						useCORS: true,
					},

					jsPDF: {
						unit: 'mm',
						format: 'a4',
						orientation: 'portrait'
					}
                },
            currentReportDate: null,
            currentReportToken: null,
            currentReportName: null,
            countrySelected: {
                name: 'Spain'
            },
            showGraph: false
        }
    },
    computed: {
    
    },
    watch : {
    },
    created () {
    },
    mounted(){
        this.showGraph = true
    },
    methods: {
        async generatePDF(){
            var opt= {
                    margin: [15, 0, 25, 0],
                    filename: `profile.pdf`,

                    enableLinks: true,

                    image: {
                        type: 'jpeg', 
                        quality: 1
                    },

                    html2canvas: {
                        useCORS: true,
                        enableLinks: true,
                        scale: 10
                    },

                    jsPDF: {
                        unit: 'mm',
                        format: 'a4',
                        orientation: 'portrait'
                    }
                }

            var date = Date.now()
            var token = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2)
            
            this.currentReportDate = date
            this.currentReportToken = token.toUpperCase()
            this.currentReportName = date + '_' + this.countrySelected.name + '_report'
            var html1 = await html2pdf().set(opt).from(this.$refs['pdf-content']).toPdf().get('pdf').then(async (pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
					
                    for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i)
                        pdf.setFontSize(11)
                        pdf.setTextColor('#000000')
                        pdf.setFont(undefined, 'bold')
						pdf.text('Token: ' + this.currentReportToken + ' | Fecha:' + this.currentReportDate, (pdf.internal.pageSize.getWidth() * 0.1), (pdf.internal.pageSize.getHeight() - 10))
						pdf.text('Puede validar este documento en: https://naturalcapitalmatters.com/report-check', (pdf.internal.pageSize.getWidth() * 0.1), (pdf.internal.pageSize.getHeight() - 5))
                    } 
                    //let link = document.createElement('a');
                   // link.target = '_blank';
                    //link.href = pdf.output('bloburl');
                    //link.download = this.currentReportName;
                    
                        pdf.save()
                   
                })
               console.log(html1)
            // this.$refs.html2Pdf.generatePdf()
        }
        
    }
    
}
</script>
<style scoped>
.disabled-row {
  background-color: #f8f8f8;
  color: #999999;
}
.positive-color{
    color: green;
}
.negative-color{
    color: red;
}

h5{
    font-size: 12px;
    font-weight: 800;
}
</style>