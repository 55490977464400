<template>
    <div>
      <canvas ref="chart"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from 'chart.js'; // Importa directamente `chart.js`
  
  export default {
    name: 'RadarCHart',
    props: {
      chartData: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    mounted() {
      // Usamos el método `Chart` directamente para renderizar el gráfico
      this.chart = new Chart(this.$refs.chart, {
        type: 'radar', // Asegúrate de especificar el tipo aquí
        data: this.chartData,
        options: this.options
      });
    },
    beforeDestroy() {
      // Destruye el gráfico cuando se destruya el componente
      if (this.chart) {
        this.chart.destroy();
      }
    }
  };
  </script>
  
  <style scoped>
  canvas {
    height: 100px;
  }
  </style>
  