<template>
    <div class="wrapper">
        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="920"
                filename="hee hee"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
				:html-to-pdf-options="htmlToPdfOptions"
                ref="html2Pdf"
            >
            <section slot="pdf-content" ref="pdf-content" style="background-color: white !important; padding: 90px !important; padding-top: 0px !important">
             <!-- <div style="text-align: center; background-color: #f2f2f2; padding: 10px; border-bottom: 1px solid #ccc;">
                    BASIC VALUE REPORT

                    Client: Ecovalue Consultants, S.L.
                    Date: August 14 2022
                    Report ECV220100014G-01

                </div>  -->
                
                <a>________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">1. Valued area</h5>
                <a style="">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800;">1.1. Coordinates (external rectangle limits)</h5>
                
                <table style="width: 100%; border: 1px solid black; font-size: 12px;">
                    <tr style="text-align: left;">
                        <th style="width: 70px; padding-left: 7px; border: 1px solid black;"></th>
                        <th style="border: 1px solid black; padding-left: 7px; font-weight: 500;">X</th>
                        <th style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Y</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SW</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px; ">53.07873248500005</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.06162968600006</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">NW</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">53.16795223600008</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.07836025500006</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">NE</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">53.15787688700004</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.22705000900004</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SE</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">53.06868090500007</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.21001812000009</td>
                    </tr>
                </table>

                <h5 style="font-size: 12px; font-weight: 800;  margin-top: 15px;">1.2. Map</h5>
                <div style="margin-bottom: 15px" >
                     <!--<img src="" alt="Map Image" style="width:100%; height: auto;"> -->
                     
                     <div v-show="isMapLoaded" id="mapPDF" ref="mapPDF" class="map" style="height: 200px">



                    </div>

                </div>

                <h5 style="font-size: 12px; font-weight: 800;">1.3. Valued area, by land use</h5>
                <table style="width: 100%; border: 1px solid black; font-size: 12px;">
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px; ">CR.1 Rainfed herbaceous crops (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">80.20</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px;">GR.2 Temperate grasslands, savannas and shrublands (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">135.91</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px;">TC.4 Temperate broadleaf and mixed tree-covered areas (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">336.27</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid black; text-align: left; padding-right: 7px;">SV.1 Sparsely natural vegetated areas (Ha)</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">109.97</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;">Total valued area (Ha)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">662.35</th>
                    </tr>
                </table>

                <div class="html2pdf__page-break"></div> 

                <a style="margin-top: 15px;">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">2. Value chart</h5>
                <a style="">________________________________________________________________________________________</a>

                <div style="margin-top: 10px;">
                   <!-- <img src="" alt="Value Chart" style="width:100%; height: auto;">  -->
                   <BarChart :chartData="chartData" :options="chartOptions" />
                   <!-- <BoxPlot /> -->
                   <!-- <BarChart2/> -->
                </div>

                <p style="font-size: 12px; text-align: justify;">Bar width shows the share of land uses in the valued area. Bar height shows highest, lowest and expected (adjusted median) value of land uses in the valued area. 
                    See Annex for full methodology and data.
                </p>

                <div class="html2pdf__page-break"></div> 

                <a style="margin-top: 15px;">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">3. Rating chart (más Adelante, ahora no está programado)</h5>
                <a style="">________________________________________________________________________________________</a>

                <div >
                    <!-- <img src="" alt="Rating Chart" style="width:100%; height: auto;"> -->
                    <p style="width: 20%; margin: auto; margin-top: 10px;">Relative rating</p>
                    <RadarChart style="margin-bottom: 22px" :chartData="radarChartData" :options="radarChartOptions" />
                    <p style="width: 51%; margin: auto; font-size: 9px;">100 index level = values of 90 percentile sites in same bioclimatic zone
                        R100 = best in 100km radio <br>
                        R300 = best in 300km radio
                    </p>

                </div>

                <p style="margin-top: 35px; font-size: 12px; text-align: justify;">See Annex for methodology and data.</p>

                

                <a style="margin-top: 15px;">________________________________________________________________________________________</a>
                <h5 style="font-size: 12px; font-weight: 800; margin-bottom: -13px;">6. Annex</h5>
                <a style="">________________________________________________________________________________________</a>
                

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.1 Methodology summary (please refer to the knowledge base for complete information)</h5>

                <p style="font-size: 12px; text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

                <!-- <div class="html2pdf__page-break"></div>   -->

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.2 Value range by hectare of land use of the ecosystem services in the valued area</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">CR.1 Rainfed herbaceous crops</th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">225.61</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">716.78</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">2,095.52</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">177.58</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">256.14</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">356.28</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 3</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">44.07</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">83.28</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">16.16</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">40.76</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total CR.1 Rainfed herbaceous crops</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">463.42</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,051.79</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">2,575.84</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">GR.2 Temperate grasslands, savannas and shrublands</th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">171.04</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">606.70</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">1,902.46</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">193.77</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">274.83</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">399.45</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services 3</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 1</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">44.07</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">83.28</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services 2</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">...</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">16.16</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">40.76</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total GR.2 Temperate grasslands, savannas and shrublands</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">425.04</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">960.4</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">2,425.95</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">TC.4 Temperate broadleaf and mixed tree-covered areas</th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">221.86</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">926.63</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">3,888.02</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">315.33</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">463.35</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">852.65</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">69.89</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">94.43</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">176.51</td>
                    </tr>

                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">18.80</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">29.93</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">78.94</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total TC.4 Temperate broadleaf and mixed tree-covered areas</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">625.88</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,514.34</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">4,996.12</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <div class="html2pdf__page-break"></div>  

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <td style="padding-left: 7px; border: 1px solid black; font-weight: 500;"></td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">SV.1 Sparsely natural vegetated areas</th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Provisioning services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">20.60</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">200.25</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">371.34</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Regulating and maintenance services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">87.32</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">134.30</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">114.27</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Cultural services</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">16.04</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">24.53</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">20.57</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">1.78</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">5.41</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">5.60</td>
                    </tr>
                    <tr>
                        <th style="text-align: right; padding-right: 7px;border: 1px solid black;">Total SV.1 Sparsely natural vegetated areas</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">125.74</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">364.49</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">511.78</th>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: right; padding-right: 7px;font-weight: 500;">All figures in EUR/Ha/year</td>
                    </tr>
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">Median values summary</th>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Area (Ha)</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Provisioning services</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Regulating and maintenance services</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Cultural services</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">Flows related to non-use values</td>
                        <td style="border: 1px solid black; padding-left: 7px;  font-weight: 500;">TOTAL</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1 Rainfed herbaceous crops</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">80.20</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">716.78</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">256.14</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,051.79</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.2 Temperate grasslands, savannas and shrublands</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">135.91</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">606.7</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">274.83</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56.72</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">22.15</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">960.4</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">TC.4 Temperate broadleaf and mixed tree-covered areas</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">336.27</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">926.63</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">463.35</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">94.43</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">29.93</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1514.34</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">SV.1 Sparsely natural vegetated areas</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">109.97</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">200.25</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">134.3</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">24.53</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">5.41</td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">364.49</th>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">TOTAL VALUED AREA (Ha)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">662.35</th>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">AVERAGE VALUE OF VALUED AREA (EUR / Ha / Yr)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">714.97</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">344.94</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">70.52</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">23.32</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,153.76</th>
                    </tr>
                </table> 

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.3. Aggregate value range of the ecosystem services in valued area</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;"></th>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Area (Ha)</td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Lower</td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Median</td>
                        <td style="border: 1px solid black; padding-left: 7px; font-weight: 500;">Upper</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1 Rainfed herbaceous crops</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">80.20</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">37,166.28</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">84,353.56</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">206,582.37</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.2 Temperate grasslands, savannas and shrublands</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">135.91</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">57,767.19</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">130,527.96</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">329,710.86</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">TC.4 Temperate broadleaf and mixed tree-covered areas</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">336.27</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">210,464.67</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">509,227.11</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">1,680,045.27</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px; border: 1px solid black; font-weight: 500;">SV.1 Sparsely natural vegetated areas </td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">109.97</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">13,827.63</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">40,082.97</td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;">56,280.45</td>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">TOTAL VALUED AREA (Ha)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">662.35</th>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                        <td style="border: 1px solid black; text-align: right; padding-right: 7px;"></td>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">AVERAGE VALUE OF VALUED AREA (EUR / Ha / Yr)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">481.96</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">1,153.76</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">3,431.15</th>
                    </tr>
                    <tr>
                        <th style="text-align: left; padding-left: 7px;border: 1px solid black;">TOTAL VALUE OF VALUED AREA (EUR / Yr)</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;"></th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">319,225.77</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">764,191.60</th>
                        <th style="border: 1px solid black; text-align: right; padding-right: 7px;">2,272,618.95</th>
                    </tr>
                </table>

                <div class="html2pdf__page-break"></div> 

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.4 Adjustment parameters in the valued area</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr>
                        <th style="border: 1px solid black; text-align: left; padding-right: 7px;">Ecosystem</th>
                        <th style="border: 1px solid black; text-align: left; padding-left: 7px;">Parameter</th>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Bird-related protected area </td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px;font-weight: 500; border: 1px solid black;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cow breeding</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Ecological agriculture</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Existence of fences</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Existence of trails</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Flat land</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Herbaceous plant</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">High growth</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Horse breeding</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Land productivity</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4 – SV.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Links with territory</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Low-till agriculture</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Migration birds area</td>
                    </tr>

                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Mosaic landscape</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Semi-woody plant</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4 – SV.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: autochthonous </td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: bluegrass</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: corn</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: fescues</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: rare</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Species: rye</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Traditional agriculture</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Visitor center</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1 – GR.2 – TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Visitor center</td>
                    </tr>
                    <tr>
                        <td style="text-align: right; padding-right: 7px; border: 1px solid black; font-weight: 500;">CR.1</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Woody plant</td>
                    </tr>
                </table>
                <div class="html2pdf__page-break"></div> 
                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.5 Land uses (from FAO) (solo los que se utilicen, y enlace para ver más información)</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Category of land use</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Land use</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.1</td>
                        <td rowspan="12" style="border: 1px solid black; text-align: left; padding-left: 7px;">Artificial surfaces</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Urban forests</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Urban parks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Neighborhood and private green spaces</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Urban gardens</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Isolated or lined trees</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Riverside banks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.7</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Green walls</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.8</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Green roofs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.9</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Sport facilities</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.10</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cemeteries</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.11</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Campings</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">AS.12</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Road and railroad banks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1</td>
                        <td rowspan="5" style="border: 1px solid black; text-align: left; padding-left: 7px;">Crops</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Rainfed herbaceous crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Irrigated herbaceous crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Irrigated herbaceous crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Irrigated woody crops</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Multiple or layered crop</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.1</td>
                        <td rowspan="5" style="border: 1px solid black; text-align: left; padding-left: 7px;">Grassland</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical grasslands, savannas and shrublands</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate grasslands, savannas and shrublands</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Flooded grasslands and savannas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Montane grasslands and shrublands</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">GR.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tundra</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.1</td>
                        <td rowspan="8" style="border: 1px solid black; text-align: left; padding-left: 7px;">Tree-covered areas</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical moist broadleaf tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical dry broadleaf tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Tropical and subtropical coniferous tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate broadleaf and mixed tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate riparian tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Temperate coniferous tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.7</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Boreal tree-covered areas / taiga</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TC.8</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Mediterranean and sclerophyll tree-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SC.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Shrub-covered areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.1</td>
                        <td rowspan="4" style="border: 1px solid black; text-align: left; padding-left: 7px;">Shrubs and/or herbaceous vegetation, aquatic or regularly flooded</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Peat wetlands / bogs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Riparian buffer</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Swamps</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SA.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Marshes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">MG.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Mangroves</td>
                    </tr>
                    
                </table>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Category of land use</th>
                        <th style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Land use</th>
                    </tr>
                    
                    
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SV.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Sparsely natural vegetated areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TB.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Terrestrial barren land</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Loose and shifting sand and/or dunes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">TB.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Bare soil, gravels and rocks</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">SG.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Permanent snow and glaciers</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.1</td>
                        <td rowspan="4" style="border: 1px solid black; text-align: left; padding-left: 7px;">Inland waterbodies</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Lakes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Ponds</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Reservoirs</td>
                    </tr>

                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IW.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Rivers</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.1</td>
                        <td rowspan="6" style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal areas</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Estuaries</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Beaches</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cliffed or rocky coasts</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal dunes</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Continental Shelf Sea</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CA.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Seagrass/algae beds</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IT.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal water bodies and inter-tidal</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal water bodies</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">IT.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Inter-tidal areas</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">OC.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Oceans</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Coral reefs</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Cold-water coral reefs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">CR.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Warm-water coral reefs</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">ME.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Multi-ecosystem</td>
                    </tr>
                </table>

                <h5 style="font-size: 12px; font-weight: 800; margin-top: 15px;">6.6 Ecosystem services (UN reference system) (solo los que se utilicen, y enlace para ver más información)</h5>

                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Provisioning services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.1</td>
                        <td rowspan="7" style="border: 1px solid black; text-align: left; padding-left: 7px;">Biomass provisioning services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Crop provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Grazed biomass provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.3</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Livestock provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.4</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Aquaculture provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.5</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Wood provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.6</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Wild fish and other natural aquatic biomass provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.1.7</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Wild animals, plants and other biomass provisioning services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.2.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Genetic material services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.3.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Water supply</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">P.4.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Other provisioning services</td>
                    </tr>
                    
                </table>
                <div class="html2pdf__page-break"></div> 
                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                <tr>
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Regulating and maintenance services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.1.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Global climate regulation services </td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.2.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Rainfall pattern regulation services (at sub-continental scale)</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.3.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Local (micro and meso) climate regulation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.4.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Air filtration services </td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.5.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Soil quality regulation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.6.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Soil and sediment retention services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Soil erosion control services</td>
                    </tr>
                </table>
                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Provisioning services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.6.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Landslide mitigation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.7.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Solid waste remediation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.8.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Water purification services (water quality regulation)</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Retention and breakdown of nutrients</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.8.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Retention and breakdown of other pollutants</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.9.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Water flow regulation services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Baseline flow maintenance services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.9.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Peak flow mitigation services</td>
                    </tr>
                </table>
                
                
                <table style="width: 100%; border: 1px solid black; font-size: 12px; margin-top: 25px;">
                    <tr style="text-align: left;">
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Provisioning services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.10.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Flood control services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Coastal protection services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.10.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">River flood mitigation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.11.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Storm mitigation services</td>
                    </tr>

                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.12.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Noise attenuation services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.13.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Pollination services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.14.1</td>
                        <td rowspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Biological control services</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Pest control services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.14.2</td>
                        <td style="border: 1px solid black; text-align: left; padding-left: 7px;">Disease control services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.15.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Nursery population and habitat maintenance services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">R.16.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Other regulating and maintenance services</td>
                    </tr>
                    <tr>
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Cultural services</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.1.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Recreation-related services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.2.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Visual amenity services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.3.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Education, scientific and research services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.4.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Spiritual, artistic and symbolic services</td>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">C.5.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Other cultural services</td>
                    </tr>

                    <tr>
                        <th style="padding-left: 7px; border: 1px solid black; background-color: black; color: white; text-align: left;">Code</th>
                        <th colspan="2" style="border: 1px solid black; padding-left: 7px; background-color: black; color: white; text-align: left;">Flows related to non-use values</th>
                    </tr>
                    <tr>
                        <td style="text-align: left; padding-left: 7px;font-weight: 500; border: 1px solid black;">N.1.1</td>
                        <td colspan="2" style="border: 1px solid black; text-align: left; padding-left: 7px;">Ecosystem and species appreciation</td>
                    </tr>
                </table> 

            </section>
            
            </vue-html2pdf>
        <div v-if="loading" id="toHidden"
            style="
            position: absolute;
            top     :0;
            left    : 0;
            height  : 100%;
            width   :  100%;
            background-color:azure;
            z-index : 1000;
            opacity : 0.6;"
            >
        </div>
        <b-icon  v-if="loading" style="
            z-index: 2000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            "
            icon="three-dots"
            animation="cylon"
            font-scale="4">
        </b-icon>
        <div class="card-container">
            <card class="initialCard" v-if="isSelectorLoaded">
                    <h4 slot="header" class="title title-up" style="text-align: center;">
                        {{ $t('Maps.SelectDesiredOption') }}
                    </h4>
                    <div class="search-container">
                        <b-input-group style="width: 50%; margin: auto; margin-bottom: 35px;">
                                <b-input-group-prepend style="height: 32px !important">
                                    <!-- <span class="input-group-text" style="padding: 7px 2px !important; border-right: none !important;"><b-icon icon="globe" id="togglePassword" style="height: -webkit-fill-available;"></b-icon></span> -->
                                    <span class="input-group-text focus-input" ref="prependSearch" id="prependSearch" style="padding: 8px 5px !important;border-right: none !important;"><b-icon icon="search" style="height: -webkit-fill-available;"></b-icon></span>
                                </b-input-group-prepend>
                                
                                    <b-form-input class="focus-input" v-model="searchTextInitial" @input.native="filterCountriesInitial" @change.native="selectCountry()" list="country-list-search" placeholder="Search countries..." style="height: 32px !important; border-left: none !important; border-top-right-radius: 15px; border-bottom-right-radius: 15px;; padding-left: 0px">
                                
                                </b-form-input>
                            <datalist id="country-list-search">
                                <option v-for="country in filteredCountriesInitial">{{ country.name }}</option>
                            </datalist>
                        </b-input-group>
                        <n-button v-if="selectedOption != null" class="btn-simple ncm-btn" @click="selectThisOption(searchTextInitial)" style="margin: 0 auto; display: block;">
                                {{ $t('Maps.Select') }}
                        </n-button>
                    </div>
                    <!-- <b-form-group>
                        <div class="search-container" style="max-width: fit-content; margin-left: 60px;">
                            <input
                                type="search"
                                v-model="searchTextInitial"
                                placeholder="Search countries..."
                                @input="filterCountriesInitial"
                                @click.stop
                                style="height: 32px !important; padding: 10px; width: 280px!important; margin-left: 70px; margin-bottom: 20px;"
                            />
                        </div>

                        <input type="search" placeholder="Search" class="form-control" v-model="option" @click.stop style="height: 51px !important; padding: 10px; width: 450px!important; margin-left: 20px;"> -->
                        <!-- <a v-for="option in filteredCountries" :value="option"
                            class="dropdown-item" :key="option.id" @click="selectThisCountry(option)" style="cursor: pointer; max-width: fit-content;"
                        > -->
                        <!-- <b-form-select id="layer" v-model="selectedOption"
                        class="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        style="width: 400px!important; margin-left: 70px;">

                        <b-form-select-option :value="null"> {{$t('Maps.SelectOption')}}</b-form-select-option>
                        <b-form-select-option v-for="(country, index) of filteredCountriesInitial" :key="index" :value="country"> {{ country.name }}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback id="input-country-live-feedback">{{ veeErrors.first('input-country') }}</b-form-invalid-feedback>
                    </b-form-group> -->

            </card>
        </div>
        <div v-show="isMapLoaded" id="map" ref="map" class="map" :style="{ cursor: cursorStyle }">

            <div id="scale_bar"></div>
            <div id="scale_bar1"></div>
            <div class="divRow">
                <b-row class="buttonRow" ref="buttonRow">
                    <!-- <b-button @click="generateReport()">PDF</b-button> -->

                    <drop-down class="" id="changeOption" style="max-width: 200px;">
                        <n-button
                            slot="title"
                            class="dropdown-toggle"
                            type="primary"
                            data-toggle="dropdown"
                            style="background-color: #5d6176; color: white; border: 1px solid;"
                        >
                            <b v-if="countrySelected == undefined">{{ $t('Maps.Country') }}</b>
                            <b v-else> {{ $t('Maps.Country') }}: {{ countrySelected.name }}  </b>
                        </n-button>
                        <div class="search-container">
                            <input
                                type="search"
                                v-model="searchText"
                                placeholder="Search countries..."
                                @input="filterCountries"
                                @click.stop
                                style="height: 51px !important; padding: 10px; width: 450px!important; margin-left: 20px;"
                            />
                        </div>

                        <!-- <input type="search" placeholder="Search" class="form-control" v-model="option" @click.stop style="height: 51px !important; padding: 10px; width: 450px!important; margin-left: 20px;"> -->
                        <a v-for="option in filteredCountries" :value="option"
                            class="dropdown-item" :key="option.id" @click="selectThisCountry(option)" :class="{ 'selected-option': countrySelected === option }" style="cursor: pointer;"
                        >
                        <!-- <n-button class="btn-simple ncm-btn" style="font-weight: 800 !important; background-color: white; font-size: 1em;">{{ option.country }}</n-button> -->
                        <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase;">{{ option.name }}</div>
                        </a>
                    </drop-down>

                    <drop-down class="" id="selectLayers">

                        <n-button
                            slot="title"
                            class="dropdown-toggle"
                            type="primary"
                            data-toggle="dropdown"
                            style="background-color: #5d6176; color: white; border: 1px solid;"
                        >
                            <b v-if="selectedBaseMap == undefined">Base map</b>
                            <b v-else>{{ $t('Maps.BaseMap') }}: {{ selectedBaseMap }}</b>
                        </n-button>

                        <a class="dropdown-item" @click="chooseBaseMap('OSM')" :class="{ 'selected-option': selectedBaseMap === 'OSM' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="map" class="mr-2"></b-icon>OSM</div>
                        </a>
                        <a class="dropdown-item" @click="chooseBaseMap('Satellite')" :class="{ 'selected-option': selectedBaseMap === 'Satellite' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="globe" class="mr-2"></b-icon>{{ $t('Maps.Satellite') }}</div>
                        </a>

                        <!-- <n-button
                        id="wms_layers_btn"
                        class="btn-simple ncm-btn"
                        style="font-weight: 800 !important; background-color: white; "
                        @click.native="showWMSLayersModal()"
                        >
                            <b-icon icon="layers-fill" class="button-icon"></b-icon>
                            {{ $t('Maps.WMSLayers') }}
                        </n-button>

                        <n-button
                        id="catastro_btn"
                        ref="catastro_btn"
                        class="btn-simple ncm-btn"
                        style="font-weight: 800 !important; background-color: white; "
                        @click.native="showCatastroModal()"
                        >
                            <b-icon icon="layers-fill" class="button-icon"></b-icon>
                            {{ $t('Maps.Catastro') }}
                        </n-button>

                        <n-button
                        id="upload_btn"
                        class="btn-simple ncm-btn"
                        style="font-weight: 800 !important; background-color: white"
                        @click.native="showUploadLayerModal()"
                        >
                            <b-icon icon="plus" class="button-icon"></b-icon>
                            {{ $t('Maps.UploadLayer') }}
                        </n-button> -->

                    </drop-down>

                    <drop-down class="" id="selectLayers">

                        <n-button
                            slot="title"
                            class="dropdown-toggle"
                            type="primary"
                            data-toggle="dropdown"
                            style="background-color: #5d6176; color: white; border: 1px solid;"
                        >
                            <b>{{ $t('Maps.ViewMenu') }}</b>
                        </n-button>

                        <a class="dropdown-item" @click="showWMSLayersModal()" :class="{ 'selected-option': selectedLayerOption === 'WMSLayers' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="layers" class="button-icon"></b-icon>{{ $t('Maps.WMSLayers') }}</div>
                        </a>

                        <a class="dropdown-item" v-if="enableCadastre" @click="addLayerExternal()" :class="{ 'selected-option': selectedLayerOption === 'Cadastre' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="layers" class="button-icon"></b-icon>{{ $t('Maps.Catastro') }}</div>
                        </a>

                        <a class="dropdown-item" @click="showExternalLayersModal()" :class="{ 'selected-option': selectedLayerOption === 'UserLayers' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="layers" class="button-icon"></b-icon>{{ $t('Maps.UserLayers') }}</div>
                        </a>

                        <a class="dropdown-item" @click="showUploadLayerModal()" >
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="plus" class="button-icon"></b-icon>{{ $t('Maps.UploadLayer') }}</div>
                        </a>


                    </drop-down>

                    <drop-down class="" id="selectMenu">


                        <n-button
                            slot="title"
                            class="dropdown-toggle"
                            type="primary"
                            data-toggle="dropdown"
                            style="background-color: #5d6176; color: white; border: 1px solid;"
                        >
                            <b>{{ $t('Maps.SelectMenu') }}</b>
                        </n-button>

                        <a class="dropdown-item" @click="openGetInfo()" v-if="layerApplied || cadastreActivated" :class="{ 'selected-option': selectedMenuOption === 'Click' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="mouse" class="button-icon"></b-icon>{{ $t('Maps.Click') }}</div>
                        </a>

                        <a class="dropdown-item" @click="drawPolygonParcel()" :class="{ 'selected-option': selectedMenuOption === 'Draw' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="pentagon" class="button-icon"></b-icon>{{ $t('Maps.Draw') }}</div>
                        </a>

                        <a class="dropdown-item" @click="usePolygonLayer()" v-if="layerApplied" :class="{ 'selected-option': selectedMenuOption === 'All' }">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase; cursor: pointer;"><b-icon icon="layers" class="button-icon"></b-icon>{{ $t('Maps.All') }}</div>
                        </a>

                    </drop-down>

                    <n-button
                        id="clear_btn"
                        class="btn-simple"
                        style="font-weight: 800 !important; background-color: #5d6176; color: white; border-color: white!important;"
                        @click.native="clearAll()"
                    >
                    <b-icon icon="arrow-clockwise" class="button-icon" style="color: white; font-weight: 800 !important;"></b-icon>
                    <span style="color: white;">{{ $t('Maps.Clear') }}</span>
                    </n-button>

                    <n-button
                        id="clear_btn"
                        class="btn-simple"
                        style="font-weight: 800 !important; background-color: #5d6176; color: white; border-color: white!important;"
                        @click.native="showFinalModal()"
                    >
                    <b-icon icon="arrow-right" class="button-icon" style="color: white; font-weight: 800 !important;"></b-icon>
                    <span style="color: white;">{{ $t('Maps.GO') }}!</span>
                    </n-button>

                    <!-- <drop-down class="" id="measuretype">
                        <n-button
                            slot="title"
                            class="dropdown-toggle"
                            type="primary"
                            data-toggle="dropdown"
                            style="background-color: #5d6176; color: white; border: 1px solid;"
                        >
                            <b v-if="!measureOption">{{ $t('Maps.MeasureOption') }}</b>
                            <b v-else>{{ measureOption.name}}</b>
                        </n-button>
                        <a v-for="(option, index) of measureOptions" class="dropdown-item" :key="index" @click="chooseMeasureOption(option)">
                            <div style="font-size: 1.1em; font-weight: bold; color: #5d6176; text-transform: uppercase;"><b-icon :icon="option.icon" class="mr-2"></b-icon>{{option.name}}</div>
                        </a>
                    </drop-down> -->



                    <div id="legend" ref="legend" style="visibility: hidden; bottom: 1%; right: 1%; position: fixed;">
                        <h6>{{ $t('Maps.Legend') }}</h6>
                        <div v-for="(legend, index) of legend" :key="index" style="margin-top: 7px;">
                            <span style="font-size: 15px; font-weight: lighter;">{{legend.title}}</span>
                            <b-row style="display: flex; align-items: center; margin-left: 1px; margin-top: 5px">
                                <img :src="legend.url" style="object-fit: contain">
                            </b-row>
                        </div>
                    </div>
                </b-row>
            </div>

            <n-button
                v-if="downloadAvailable"
                id="download_btn"
                class="btn-simple ncm-btn"
                style="font-weight: 800 !important; background-color: white"
                @click.native="downloadReport()"
            >
            <b-icon icon="file-earmark-pdf" class="button-icon"></b-icon>
            {{ $t('Maps.Download') }}
            </n-button>

        </div>

        <div id="query_tab" ref="query_tab" style="visibility: hidden; display: flex; position:fixed; background-color: #ffffff; bottom: 0%; top: 70%; opacity: 85%;">

            <!-- <div class="nav nav-tabs" id="nav-tab" role="tablist" style="font-size:14px;">
                <b-button class="nav-link active" id="nav-attributes-tab" data-bs-toggle="tab" data-bs-target="#nav-attributes" type="b-button" role="tab" aria-controls="nav-attributes" aria-selected="true">Select by Attributes</b-button>
                <b-button class="nav-link" id="nav-draw-tab" data-bs-toggle="tab" data-bs-target="#nav-draw" type="b-button" role="tab" aria-controls="nav-draw" aria-selected="false">Select by Draw</b-button>
            </div> -->
            <div class="query-panel-btns" ref="query-panel-btns" style="display: flex; position: fixed; background-color: #64687d; width: 620px; margin-top: -9px;">
                <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="width: 100%; margin-left: 95px;">
                <b-button-group class="mx-1">
                    <n-button
                        ref="tab-btn-atr"
                        :class="['btn-simple ', attributesSelected ? 'tab-active' : '']"
                        :style="{fontWeight: 800, backgroundColor: attributesSelected ? 'white!important' : '#64687d', opacity: 1, color: attributesSelected ? '#64687d!important' : 'white', position: 'relative' }"
                        @click.native="selectTab('attributes')"
                    >
                    <b-icon icon="layers-fill" class="button-icon"></b-icon>
                    {{ $t('Maps.SelectByAttribute') }}
                    </n-button>
                    <n-button
                        ref="tab-btn-draw"
                        :class="['btn-simple ', drawSelected ? 'tab-active' : '']"
                        :style=" {fontWeight: 800, backgroundColor: drawSelected ? 'white!important' : '#64687d', opacity: 1, color: drawSelected ? '#64687d!important' : 'white', position: 'relative', marginLeft: '20px' } "
                        @click.native="selectTab('draw')"
                    >
                    <b-icon icon="layers-fill" class="button-icon"></b-icon>
                    {{ $t('Maps.SelectByDraw') }}
                    </n-button>
                </b-button-group>
            </b-button-toolbar>
            </div>


                <div class="query-panel-content" ref="query-panel-content" style="padding: 10px; position: relative; margin-top: 50px; margin-left: 80px;">
                    <div v-if="attributesSelected">
                        <b-row>
                            <b-col style="max-height: 40px; max-width: 260px;">
                                <label for="layer"><b>{{ $t('Maps.SelectLayer') }}</b></label>
                                <b-form-select id="layer"
                                :options="wms_layers"
                                value-field="Name"
                                text-field="Name"
                                class="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                style="width: 200px; max-height: 40px;">
                                <b-form-select-option :value="null">{{ $t('Maps.SelectLayer') }}</b-form-select-option>
                            </b-form-select>
                            </b-col>
                            <b-col style="max-width: 260px;">
                                <label for="value"><b>{{ $t('Maps.EnterValue') }}</b></label>
                                <input type="text" class="form-control form-select-sm" id="value" name="value" style="max-height: 30px;">
                            </b-col>
                        </b-row>
                        <b-row style="margin-top: 20px;">
                            <b-col style="max-width: 260px;">
                                <label for="attributes"><b>{{ $t('Maps.SelectAttribute') }}</b></label>
                                <select id="attributes" ref="attributes" class="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option selected>{{ $t('Maps.SelectAttribute') }}</option>
                                </select>
                            </b-col>
                            <b-col style="max-width: 260px;">
                                <label for="operator"><b>{{ $t('Maps.SelectAttribute') }}</b></label>
                                <select id="operator" class="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option selected>{{ $t('Maps.SelectOperator') }}</option>
                                </select>
                            </b-col>
                        </b-row>
                        <b-button onclick="query()" type="b-button" class="btn btn-danger btn-sm">{{ $t('Maps.LoadLayer') }}</b-button>
                    </div>
                    <div v-if="drawSelected">
                        <n-button
                            id="add_layer_btn"
                            ref="add_layer_btn"
                            class="btn-simple ncm-btn"
                            style="position: absolute; display: flex; margin-left: 37%; margin-top: 8%!important; background-color: white; font-size: 1.2em; z-index: 999; max-width: 35px; max-height: 35px; align-items: center; justify-content: center; padding: 10px;"
                            @click.native="addLayerListQuery()"
                        >
                        <b-icon icon="plus" class="button-icon" style="font-weight: bold;"></b-icon>
                        </n-button>
                        <div class="row">
                            <div class="col-7">
                                <label class="mt-2" style="display: flex;" for="layer1"><b>{{ $t('Maps.SelectLayer') }}</b>
                                    <!-- <label style="margin-top: 2px;">{{ $t('Maps.MultipleLayers') }}</label> -->
                                </label>
                                <div style="display: flex">
                                    <b-form-select id="layer" v-model="selectedLayerTab"
                                    :options="wms_layers"
                                    value-field="Name"
                                    text-field="Name"
                                    class="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    style="max-width: 170px;">
                                        <b-form-select-option :value="null">{{ $t('Maps.SelectLayer') }}</b-form-select-option>
                                    </b-form-select>
                                    <!-- <n-button
                                        id="add_layer_btn"
                                        ref="add_layer_btn"
                                        class="btn-simple ncm-btn"
                                        style="position: absolute; margin-left: 170%; font-weight: 800 !important; background-color: white; margin-top: 0 !important"
                                        @click.native="addLayerListQuery()"
                                    >
                                    <b-icon icon="plus" class="button-icon"></b-icon>
                                    </n-button> -->
                                </div>
                                <!-- <div style="display: flex" v-else>
                                    <b-form-select id="layer" v-model="selectedLayerTab"
                                    :options="wms_layers"
                                    value-field="Name"
                                    text-field="Name"
                                    class="form-select form-select-sm"
                                    aria-label=".form-select-sm example">
                                        <b-form-select-option :value="null">{{ $t('Maps.SelectLayer') }}</b-form-select-option>
                                    </b-form-select>
                                </div> -->
                                <br>
                                <label for="draw_type"><b>{{ $t('Maps.SelectGeometry') }}</b></label>
                                <b-form-select id="draw_type" v-model="selectedGeometryTab" @change="changeDrawType()"
                                    class="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    style="max-width: 240px;">
                                    <b-form-select-option :value="null">{{ $t('Maps.SelectShape') }}</b-form-select-option>
                                    <b-form-select-option value="Square">{{ $t('Maps.Square') }}</b-form-select-option>
                                    <b-form-select-option value="Box">{{ $t('Maps.Box') }}</b-form-select-option>
                                    <b-form-select-option value="Polygon">{{ $t('Maps.Polygon') }}</b-form-select-option>
                                    <b-form-select-option value="Star">{{ $t('Maps.Star') }}</b-form-select-option>
                                    <b-form-select-option value="clear">{{ $t('Maps.Clear') }}</b-form-select-option>
                                </b-form-select>
                                <!-- <n-button
                                    id="add_layer_btn"
                                    ref="add_layer_btn"
                                    class="btn-simple ncm-btn"
                                    style="position: absolute; margin-left: 170%; font-weight: 800 !important; background-color: white; margin-top: 0 !important"
                                    @click.native="addLayerListQuery()"
                                >
                                <b-icon icon="plus" class="button-icon"></b-icon>
                                </n-button> -->
                            </div>
                            <div class="col-5">
                                <div>
                                    <label class="mt-2" for="draw_type"><b>{{ $t('Maps.LayersQuery') }}:</b></label>
                                    <div style="overflow-y: auto; overflow-x: hidden; max-height: 130px; min-width: 200px; max-width: 200px; position: absolute; white-space: nowrap;">
                                        <p v-for="(layer, index) of query_layers_list" :key="index" style="margin-bottom: 2px !important; font-size: 15px; overflow: hidden; text-overflow: ellipsis;">
                                            <span v-if="layer.length > 25" class="tooltipLayers" :title="layer"><b-icon icon="x" class="button-icon"  @click="deleteLayer(index)"></b-icon>{{ layer.substr(0, 25) + '...' }}</span>
                                            <span v-else class="tooltipLayers" :title="layer"><b-icon icon="x" class="button-icon"  @click="deleteLayer(index)"></b-icon>{{layer}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </div>

        <div class="tooltip tooltip-measure" ref="measure-tooltip" v-show="showMeasureToolTip"></div>
        <div class="tooltip tooltip-help" ref="help-tooltip" v-show="showHelpToolTip"></div>
        <div class="tooltip tooltip-getinfo" ref="getinfo-tooltip" v-show="showGetinfoTooltip"></div>

        <!-- <modal :show.sync="modals.features_layers" :close-outside="true" class="modal-wms" :show-close="true" header-classes="justify-content-center">
            <div class="modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel">Features</h6>
            </div>
            <div class="modal-body" style="padding: 0"> -->
            <div id="table_data" ref="table_data">
                <h6 class="modal-title" id="staticBackdropLabel">{{ $t('Maps.Features') }}</h6>
                <b-table id="table_data_table" class="table table-hover"
                                ref="table_data_table"
                                :items="table_data"
                                :fields="sortableCols"
                                responsive="sm"
                                show-empty
                                :current-page="currentPageData"
                                :per-page="perPageData"
                                >
                                <template #empty="">
                                    {{ $t('Maps.NoData') }}
                                </template>

                                <!-- <template v-slot:[`cell(${summaryField})`]="data" v-for="summaryField in sortableCols">
                                        <template v-if="data.item.summaryField">
                                            {{ data.item[summaryField].toFixed(2) }}eee
                                        </template>
                                </template> -->

                                <template #cell(intersection_polygon)="data">
                                    <span
                                        style="color: #5d6176; font-weight: 800; text-decoration: underline;"
                                        :title="data.item.intersection_polygon">
                                        {{ $t('Maps.Polygon') }} <b-icon icon="pentagon" class="button-icon" ></b-icon>
                                    </span>
                                </template>

                                <template #cell(intersection_percentage)="data">
                                    {{ data.item.intersection_percentage | formatPercentage }}
                                </template>

                            </b-table>
                            <div style="display: flex">
                                <b-pagination
                                    v-model="currentPageData"
                                    :total-rows="totalPagesData"
                                    :per-page="perPageData">
                                </b-pagination>
                                <b-form-select v-model="perPageData" class="pagination-select">
                                    <b-form-select-option :value="4">4</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option :value="totalPagesData">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                </b-form-select>
                            </div>
            </div>
        <!-- </modal> -->



        <!-- Scrollable modal -->

         <!-- Modal delete  -->
         <modal :show.sync="modals.wms_layers" :close-outside="true" class="modal-wms" :show-close="true" header-classes="justify-content-center">
                <div class="modal-header">
                        <h6 class="modal-title" id="staticBackdropLabel">{{ $t('Maps.AvailableWMSLayers') }}</h6>
                </div>
                <div class="modal-body" style="padding: 0">
                        <b-table id="table_wms_layers" class="table table-hover"
                            style="max-height: 400px !important; min-height: 400px !important; overflow-x: auto !important;"
                            ref="table_wms_layers"
                            :items="table_layers"
                            :fields="wms_layers_headers"
                            responsive="sm"
                            show-empty
                            :current-page="currentPage"
                            :per-page="perPage"
                            selectable
                            select-mode="single"
                            sort-by="Name"
                            @row-selected="selectRow"
                            >
                            <template #empty="">
                                {{ $t('Maps.NoWMSLayers') }}
                            </template>

                            <template #cell(Abstract)="data">
                                <template v-if="data.item.Abstract">
                                    <template v-if="data.item.Abstract.length > 50">
                                        <span :title="data.item.Abstract">{{data.item.Abstract.substr( 0, 50) + '…' }}</span>
                                    </template>
                                    <template v-else>
                                        {{data.item.Abstract}}
                                    </template>
                                </template>
                            </template>


                        </b-table>
                        <b-button class="b-btn" style="margin-right: 2%; float: right" v-on:click="addLayerGeoserver()">
                            {{ $t('Maps.SelectLayer') }}
                        </b-button>
                        <div style="display: flex">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalPages"
                                :per-page="perPage">
                            </b-pagination>
                            <b-form-select v-model="perPage" class="pagination-select">
                                <b-form-select-option :value="5">5</b-form-select-option>
                                <b-form-select-option :value="10">10</b-form-select-option>
                                <b-form-select-option :value="20">20</b-form-select-option>
                                <b-form-select-option :value="totalPages">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                            </b-form-select>
                        </div>
                    </div>
            </modal>

            <modal :show.sync="modals.uploaded_layers" :close-outside="true" class="modal-wms" :show-close="true" header-classes="justify-content-center">
                <div class="modal-header">
                        <h6 class="modal-title" id="staticBackdropLabel">{{ $t('Maps.UploadedLayers') }}</h6>
                </div>
                <div class="modal-body" style="padding: 0">
                        <b-table id="table_wms_layers" class="table table-hover"
                            style="max-height: 400px !important; min-height: 400px !important; overflow-x: auto !important;"
                            ref="table_wms_layers"
                            :items="table_layers"
                            :fields="user_layers_headers"
                            responsive="sm"
                            show-empty
                            :current-page="currentPage"
                            :per-page="perPage"
                            selectable
                            select-mode="single"
                            sort-by="Name"
                            @row-selected="selectRow"
                            >
                            <template #empty="">
                                {{ $t('Maps.NoUploadedLayers') }}
                            </template>

                            <!-- <template #cell(Abstract)="data">
                                <template v-if="data.item.Abstract">
                                    <template v-if="data.item.Abstract.length > 50">
                                        <span :title="data.item.Abstract">{{data.item.Abstract.substr( 0, 50) + '…' }}</span>
                                    </template>
                                    <template v-else>
                                        {{data.item.Abstract}}
                                    </template>
                                </template>
                            </template> -->


                        </b-table>
                        <b-button class="b-btn" style="margin-right: 2%; float: right" v-on:click="addLayerGeoserver()">
                            {{ $t('Maps.SelectLayer') }}
                        </b-button>
                        <div style="display: flex">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalPages"
                                :per-page="perPage">
                            </b-pagination>
                            <b-form-select v-model="perPage" class="pagination-select">
                                <b-form-select-option :value="5">5</b-form-select-option>
                                <b-form-select-option :value="10">10</b-form-select-option>
                                <b-form-select-option :value="20">20</b-form-select-option>
                                <b-form-select-option :value="totalPages">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                            </b-form-select>
                        </div>
                    </div>
            </modal>


            <modal :show.sync="modals.catastro" :close-outside="true" class="modal-wms" :show-close="true" header-classes="justify-content-center">
                <div class="modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel">{{ $t('Maps.Catastro') }}</h6>
                </div>
                <div>
                    <b-table id="table_wms_layers" class="table table-hover"
                            style="max-height: 400px !important; min-height: 400px !important; overflow-x: auto !important;"
                            ref="table_wms_layers"
                            :items="cadastre_layers"
                            :fields="catastro_headers"
                            responsive="sm"
                            show-empty
                            :current-page="currentPage2"
                            :per-page="perPage2"
                            selectable
                            sort-by="Name"
                            select-mode="single"
                            @row-selected="selectRow"
                            >
                            <template #empty="">
                                {{ $t('Maps.NoCadastreLayers') }}
                            </template>

                            <template #cell(ButtonSelect)="">
                                <b-form-checkbox value="null" class="custom-radio" checked="false"></b-form-checkbox>
                            </template>
                    </b-table>

                    <b-button class="b-btn" style="margin-right: 2%; float: right" v-on:click="addLayerExternal()">
                        {{ $t('Maps.SelectLayer') }}
                    </b-button>

                    <div style="display: flex">
                            <b-pagination
                                v-model="currentPage2"
                                :total-rows="totalPages2"
                                :per-page="perPage2">
                            </b-pagination>
                            <b-form-select v-model="perPage2" class="pagination-select">
                                <b-form-select-option :value="5">5</b-form-select-option>
                                <b-form-select-option :value="10">10</b-form-select-option>
                                <b-form-select-option :value="20">20</b-form-select-option>
                                <b-form-select-option :value="totalPages2">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                            </b-form-select>
                        </div>

                </div>
            </modal>


            <!-- Modal upload shapefile  -->
    <modal :show.sync="modals.upload" class="modal-upload" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t('Maps.UploadLayer') }}
      </h4>
      <b-form-group label="Label *">
          <b-form-input
            id="input-label"
            name="input-label"
            v-validate="{ required: true}"
            :state="validateState('input-label')"
            aria-describedby="input-label-live-feedback"
            size="sm"
            placeholder="Label *"
            v-model="label_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-label-live-feedback">{{ veeErrors.first('input-label') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Projection *">
          <b-form-input
            id="input-projection"
            name="input-projection"
            v-validate="{ required: true}"
            :state="validateState('input-projection')"
            aria-describedby="input-projection-live-feedback"
            size="sm"
            placeholder="Projection *"
            v-model="projection_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-projection-live-feedback">{{ veeErrors.first('input-projection') }}</b-form-invalid-feedback>
      </b-form-group>
      <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions">
      </vue-dropzone>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="uploadLayer()">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.upload = false">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>


    <modal :show.sync="modals.final" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        Get natural capital report
      </h4>
      <b-form-group label="Type of report">
        <b-select  v-model="typeOfReport">
            <option value="0">Simple report</option>
            <option value="1">Complete report</option>
        </b-select>
      </b-form-group>
      <label v-if="countrySelected">System will do a intersection for the data layer of: <b>{{ countrySelected.name }}</b></label>

      <label>You have selected a total of: <b><em>{{formatArea(totalArea)}}</em></b></label>
      <label>The price for the selected report is: {{tokensReport}}</label>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="executeIntersection()">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.final = false">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>

    <!-- <modal :show.sync="modals.initial" class="modal-initial" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t('Maps.SelectDesiredOption') }}
      </h4>
      <b-form-group>
<div class="search-container" style="max-width: fit-content;">
            <input
                type="search"
                v-model="searchTextInitial"
                placeholder="Search countries..."
                @input="filterCountriesInitial"
                @click.stop
                style="height: 32px !important; padding: 10px; width: 280px!important; margin-left: 70px; margin-bottom: 20px;"
            />
        </div>

        <b-form-select id="layer" v-model="selectedOption"
          class="form-select form-select-sm"
          aria-label=".form-select-sm example"
          style="width: 400px!important; margin-left: 15px;">

          <b-form-select-option :value="null"> {{$t('Maps.SelectOption')}}</b-form-select-option>
          <b-form-select-option v-for="(country, index) of filteredCountriesInitial" :key="index" :value="country"> {{ country.name }}</b-form-select-option>
        </b-form-select>
        <b-form-invalid-feedback id="input-country-live-feedback">{{ veeErrors.first('input-country') }}</b-form-invalid-feedback>
      </b-form-group>
      <template slot="footer">
        <n-button v-if="selectedOption != null" class="btn-simple ncm-btn" @click="selectThisOption(selectedOption)">
            {{ $t('Maps.Select') }}
        </n-button>

      </template>
    </modal> -->

        <!--<div class="modal fade" id="wms_layers_window" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Available WMS Layers</h5>
                        <b-button type="b-button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
                    </div>
                    <div class="modal-body">
                        <table id="table_wms_layers" class="table table-hover" style="font-size:15px;">
                        </table>
                    </div>
                    <div class="modal-footer">
                        <b-button onclick="close_wms_window()" type="b-button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</b-button>
                        <b-button onclick="add_layer()" type="b-button" id="add_map_btn" class="btn btn-primary btn-sm">Add Layer to Map</b-button>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>



<script>
import { Button, FormGroupInput, Card, Modal, DropDown} from '@/components';
import {Table, TableColumn, Tooltip} from 'element-ui';

import MapsDataService from '@/services/MapsDataService'
import CadastreDataService from '@/services/CadastreDataService'
import ReportsDataService from '@/services/ReportsDataService'

import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import {Image} from 'ol/layer';
import {Tile, Group, Vector as VectorLayer} from 'ol/layer';
import {XYZ, OSM, Vector as VectorSource, ImageWMS as ImageWMS_Source} from 'ol/source';
import {MousePosition, ZoomToExtent, ZoomSlider, ScaleLine} from 'ol/control'
import {Style, Fill, Stroke, Circle} from 'ol/style';
import {createBox, createRegularPolygon} from 'ol/interaction/Draw';
import {Polygon, LineString, Point, LinearRing} from 'ol/geom';
import {Draw} from 'ol/interaction';
import {GeoJSON, WKT, WMSCapabilities} from 'ol/format';
import unByKey from 'ol/Observable';
import {getArea, getLength} from 'ol/sphere';
import Feature from 'ol/Feature'
import {epsg4326, transformExtent, transform} from 'ol/proj'
import {register as registerOL_Proj} from 'ol/proj/proj4'
import proj4  from 'proj4'
import 'ol-popup/src/ol-popup.css';

import LayerSwitcher from 'ol-layerswitcher'
import Popup from 'ol-popup'
import Geocoder from 'ol-geocoder'
import Papa from "papaparse";
import Toasted from 'vue-toasted';
import Vue from 'vue';

Vue.use(Toasted)
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import 'ol-geocoder/dist/ol-geocoder.css';
import { emitter } from '@/main.js';

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from 'html3pdf'
import convert from 'xml-js'

import countries_json from '@/assets/country_codes/slim-3.json'

import BarChart from '../../components/Charts/BarChart'
import RadarChart from '../../components/Charts/RadarChart'

import moment from  'moment'


export default {
    components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      [DropDown.name]: DropDown,
      vueDropzone: vue2Dropzone,
      VueHtml2pdf,
      BarChart,
    RadarChart
    },
    data: () => ({
        chartData: {
  labels: ["CR.1", "GR.2", "TC.4", "SV.1", "AVERAGE"],
  datasets: [
    {
      label: "EUR / Ha / Year",
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
        "rgba(255, 159, 64, 0.5)"
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)"
      ],
      borderWidth: 1,
      outlierColor: "#999999",
      data: [
        { min: 800, q1: 1000, median: 1200, q3: 1500, max: 2000 }, // CR.1
        { min: 600, q1: 900, median: 1000, q3: 1300, max: 1800 }, // GR.2
        { min: 1000, q1: 1800, median: 2000, q3: 2500, max: 3500 }, // TC.4
        { min: 100, q1: 200, median: 400, q3: 600, max: 900 }, // SV.1
        { min: 500, q1: 1500, median: 1500, q3: 1500, max: 2800 } // AVERAGE - Solo los bigotes
      ]
    }
  ]
},
chartOptions: {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true
    }
  },
  scales: {
    xAxes: [{
          maxBarThickness: 200,
        }
    ],
    x: {
      stacked: false,
      categoryPercentage: 0.5, // Ajusta el ancho general de las barras
      barPercentage: 0.5,   // Ajusta el ancho de las barras dentro de la categoría
      title: {
        display: true,
        text: "Category"
      },
      ticks: {
        autoSkip: false // Evita que se omitan etiquetas
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "EUR / Ha / Year"
      }
    }
  }
},
radarChartData: {
  labels: [
    'Average value',
    'Provisioning services',
    'Regulation services',
    'Cultural services',
    'Non-use services',
  ],
  datasets: [
  {
    label: 'Target',
    data: [85, 50, 60, 85, 90],
    fill: false,
    borderColor: '#AA221C',
  },
  {
    label: 'R300',
    data: [80, 70, 40, 105, 100],
    fill: false,
    borderColor: '#8B9BA8',
  },
  {
    label: 'R100',
    data: [60, 60, 40, 80, 70],
    fill: false,
    borderColor: '#C7C7C7',
  }
  
]
},
radarChartOptions:{
    scale: {
        ticks: {
            min: 0,
            max: 120
        }
    },
    elements: {
      line: {
        borderWidth: 3
      },
      point: {
        radius: 0
      }
    }
  },
        dropzoneOptions: {
                disablePreviews: true,
                createImageThumbnails: false,
                autoQueue: false,
                url: 'http://localhost',
                autoProcessQueue: false,
                thumbnailWidth: 20,
                maxFilesize: 5000,
                dictDefaultMessage: 'Drop files here or click to upload <b-icon icon="trash"></b-icon>',
                init: function() {
                    this.on("thumbnail", function(file) {
                        console.log('on thumbnail', file)
                    })
                    this.on("addedfile", function(file, done) {
                        console.log('on added', file)

                    })
                    this.on("complete", function(file, done) {
                        console.log('on complete', file)

                    })
                },
                // accept: function(file, done) {
                //     if(file.type != 'video/mp4'){
                //         file.rejectDimensions = function() {
                //             done("Wrong image dimensions, minimum 1280x720");
                //             Swal.fire({
                //             icon: 'error',
                //             title: 'Wrong image dimensions, minimum 1280x720'
                //             });
                //         };
                //         file.acceptDimensions = done;
                //     }
                //     else{
                //         done()
                //     }

                // }
            },
        modals: {
            wms_layers: false,
            catastro: false,
            upload: false,
            initial: false,
            final: false,
            uploaded_layers: false,
        },
        text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
        selectedLayer: null,
        map: undefined,
        mapPDF: undefined,
        mapElement: undefined,
        mapElementPDF: undefined,
        buttonRowElement: undefined,
        queryTabElement: undefined,
        queryContentElement: undefined,
        queryPanelContentElement: undefined,
        queryPanelBtnsElement: undefined,
        measureTooltipElement: undefined,
        helpTooltipElement: undefined,
        getinfoTooltipElement: undefined,
        // geojson: '',
        featureOverlay: '',
        overlays: '',
        overlaysPDF: '',
        style: '',
        selected: '',
        features: '',
        layer_name: '',
        layerControl: '',
        content: '',
        selectedFeature: '',
        layerSwitcher: undefined,
        measureOption: null,
        wms_layers: [],
        wms_layers_wfs: [],
        cadastre_layers: [],
        selectedLayerTab: null,
        selectedGeometryTab: null,
        popup: null,
        measuretype: 'select',
        sketch: null,
        view: null,
        viewPDF: null,
        // helpTooltipElement: null,
        continuePolygonMsg: 'Click to continue drawing the polygon',
        continueLineMsg: 'Click to continue drawing the line',
        // wms_layers_headers: [
        // { key: 'visible_name', label: 'Name', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        // { key: 'projection', label: 'Projection', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        // // { key: 'id', label: 'Abstract', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        // ],
        // user_layers_headers: [
        //     { key: 'visible_name', label: 'Name', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        //     { key: 'projection', label: 'Projection', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        // ],
    //    catastro_headers: [
    //     // { key: 'ButtonSelect', label: 'Select', tdClass: 'table-title', thClass: 'table-title'},
    //     { key: 'Name', label: 'Name', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
    //     { key: 'Title', label: 'Title', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
    //     // { key: 'Abstract', label: 'Abstract', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
    //     ],

        currentPage: 1,
        currentPage2: 1,
        perPage: 10,
        perPage2: 10,
        currentPageData: 1,
        perPageData: 4,
        draw1: null,
        draw2: null,
        draw: null,
        helpTooltip: null,
        measureTooltip: null,
        getinfoTooltip: null,
        source: null,
        vectorLayer: null,
        source1: null,
        vector1: null,
        table_data: [],
        table_headers: [],
        // measureOptions: [
            // {name: 'Line', value: "length"},
            // {name: 'Polygon', value: "area"},
            // {name: 'Clear Measurement', value: "clear"}
        // ],
        showQueryPanel: false,
        drawSelected: true,
        attributesSelected: false,
        downloadAvailable: false,
        buttonShowQuery: '',
        buttonShowLegend: '',
        buttonShowInfo: '',
        showInfoActivated: false,
        showQueryActivated: false,
        showLegenActivated: false,
        legend: [],
        selectedElement: null,
        showMeasureToolTip: false,
        showHelpToolTip: false,
        showGetinfoTooltip: false,
        multiple_layer: false,
        query_layers_list: [],
        loading: false,
        label_form: '',
        projection_form: '',
        selectedOption: null,
        checkInitializedMap: false,
        centerBase: [-2.90988888614811,39.101193373642424],
        projectionBase: 'EPSG:4326',
        toggleCat: false,
        loading: false,
        cursorStyle: 'default',
        isDropdownOpen: false,
        optionsArray: [],
        countrySelected: undefined,
        array_geojson: [],
        selectedBaseMap: 'OSM',
        layerOSM: undefined,
        layerSatellite: undefined,
        currentPolygons: [],
        selectedDataLayer: undefined,
        countries: countries_json,
        geocoder: undefined,
        enableCadastre: false,
        user_layers: [],
        table_layers: [],
        drawPolygonActivated: false,
        instersectWithAll: false,
        cadastreActivated: false,
        layerApplied: false,
        isDropdownDisabled: false,
        searchText: '',
        searchTextInitial: '',
        isMapLoaded: false,
        isSelectorLoaded: true,
        selectedCountry: null,
        selectedMenuOption: null,
        selectedLayerOption: null,
        data_layers: [],
        totalArea: 0,
        currentReportDate: null,
        currenReportToken: null,
        currentReportName: null,
        user: {},
        htmlToPdfOptions: {
                    margin: [15, 0, 25, 0],

					filename: `profile.pdf`,

					image: {
						type: 'jpeg', 
						quality: 1
					},

					enableLinks: true,

					html2canvas: {
						scale: 2,
						useCORS: true
					},

					jsPDF: {
						unit: 'mm',
						format: 'a4',
						orientation: 'portrait'
					}
                },
            layer_intersections: undefined,
            mapElementPDF: undefined,
            typeOfReport: 0,
        }),
    async mounted() {

        // this.getGeoserverLayers()
        // this.getCadastreLayers()
        // this.initializeMap()
        this.mapElement = this.$refs['map']
        this.mapElementPDF = this.$refs['mapPDF']
        this.buttonRowElement = this.$refs['buttonRow']
        this.queryTabElement = this.$refs['query_tab']
        this.queryContentElement = this.$refs['query_content']
        this.queryPanelContentElement = this.$refs['query-panel-content']
        this.queryPanelBtnsElement = this.$refs['query-panel-btns']
        this.measureTooltipElement = this.$refs['measure-tooltip']
        this.helpTooltipElement = this.$refs['help-tooltip']
        this.getinfoTooltipElement = this.$refs['getinfo-tooltip']


        this.modifyDiv()
    },
    watch: {
        $orgSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.organization_id = newValue.id
            this.checkExternalRole(this.$store.state.auth.user)
            this.getGeoserverLayers()
        }
    },
    created () {
        this.user = JSON.parse(localStorage.getItem('user')).data
        this.buttonShowQuery = this.$t("Maps.OpenQuery"),
        this.buttonShowLegend = this.$t("Maps.ShowLegend"),
        this.buttonShowInfo = this.$t("Maps.GetInfo"),
        this.fetchOptions()

        this.organization_id = this.$orgSelected.id
        this.checkExternalRole(this.$store.state.auth.user)
    },
    computed:{
        wms_layers_headers() {return[
            { key: 'Name', label: this.$t('Maps.Name'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
            { key: 'Title', label: this.$t('Maps.Title'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
            { key: 'Abstract', label: this.$t('Maps.Abstract'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        ]},
        user_layers_headers() {return[
            { key: 'Name', label: this.$t('Maps.Name'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
            { key: 'Title', label: this.$t('Maps.Title'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
            { key: 'Abstract', label: this.$t('Maps.Abstract'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        ]},
        measureOptions(){return [
            {name: this.$t('Maps.Line'), value: "length", icon: 'slash-lg'},
            {name: this.$t('Maps.Polygon'), value: "area", icon: 'pentagon'},
            {name: this.$t('Maps.ClearMeasurement'), value: "clear", icon: 'arrow-clockwise'}
        ]},
        catastro_headers(){return [
        // { key: 'ButtonSelect', label: 'Select', tdClass: 'table-title', thClass: 'table-title'},
        { key: 'Name', label: this.$t('Maps.Name'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        { key: 'Title', label: this.$t('Maps.Title'), sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        // { key: 'Abstract', label: 'Abstract', sortable: true, tdClass: 'table-title', thClass: 'table-title'},
        ]},
        totalPages () {
            return this.wms_layers.length
        },
        totalPages2 () {
            return this.cadastre_layers.length
        },
        totalPagesData () {
            return this.table_data.length
        },
        sortableCols() {
            if(this.table_data.length > 0){
                return Object.keys(this.table_data[0]).map(f => {
                    let tmp = {};
                    tmp.label = f.charAt(0).toUpperCase() + f.slice(1).toLowerCase();
                    tmp.sortable = true;
                    tmp.key = f;
                    console.log(tmp)
                    return tmp
                })
            }
        },
        filteredCountries() {
            // Filter countries based on the case-sensitive search input
            // return this.countries.filter((country) =>
            //     country.name.includes(this.searchText)
            // );
            return this.countries.filter((country) =>
                country.name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        },
        filteredCountriesInitial() {
            // Filter countries based on the case-sensitive search input
            // return this.countries.filter((country) =>
            //     country.name.includes(this.searchText)
            // );
            return this.countries.filter((country) =>
                country.name.toLowerCase().includes(this.searchTextInitial.toLowerCase())
            );

        },
        tokensReport() {
            return Math.trunc(this.totalArea / 10000)
        }
    },
    methods: {
        async hasDownloaded (event) {
                // console.log('hasDownloaded')
                // console.log(event)
                // const formData = new FormData();    
                // formData.append("file", event, this.currenReportName + '.pdf');
                // await MapsDataService.uploadReport(formData).then(
                //         async response => {
                //             console.log(response)

                //         },
                //         error => {
                //             console.log((error.response && error.response.data) ||
                //                 error.message ||
                //                 error.toString())
                //         }
                //     )
               
            },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
                console.log('beforeDownload')
                //const date = moment(String(new Date())).format('DD-MM-YYYY:HH:MM:SS')
                var date = Date.now()
                var token = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2)
                
                this.currentReportDate = date
                this.currentReportToken = token.toUpperCase()
                this.currentReportName = date + '_' + this.countrySelected.name + '_report'
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then(async (pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
					
                    for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i)
                        pdf.setFontSize(11)
                        pdf.setTextColor('#000000')
                        pdf.setFontStyle('bold')
						pdf.text('Token: ' + this.currentReportToken + ' | Fecha:' + this.currentReportDate, (pdf.internal.pageSize.getWidth() * 0.1), (pdf.internal.pageSize.getHeight() - 10))
						pdf.text('Puede validar este documento en: https://naturalcapitalmatters.com/report-check', (pdf.internal.pageSize.getWidth() * 0.1), (pdf.internal.pageSize.getHeight() - 5))
                    } 
                    let link = document.createElement('a');
                    link.target = '_blank';
                    link.href = pdf.output('bloburl');
                    link.download = this.currentReportName;
                    const formData = new FormData();    
                    console.log(this.currentReportName)
                    formData.append("reports", pdf.output('blob'), this.currentReportName + '.pdf');
                    formData.append("token",this.currentReportToken);
                    formData.append("date", this.currentReportDate);
                    formData.append("polygons", this.currentPolygons);
                    formData.append("project_id", this.$projectSelected.id);
                    console.log(formData)
                    MapsDataService.uploadReport(formData).then(
                            async response => {
                                console.log(response)

                            },
                            error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.message
                                })
                            }
                        )
                    link.click();
                    link.remove();

                    for(var layer_intersection of this.layer_intersections){
                        let url_parse = new URL(layer_intersection.url)
                        url_parse.searchParams.set('outputFormat', 'shape-zip')
                        url_parse.searchParams.set('format_options', 'CHARSET:UTF-8')
                        console.log('url_parse', url_parse)
                        let link = document.createElement('a');
                        link.target = '_blank';
                        link.href = url_parse;
                        link.click()
                        link.remove()
                    }
                })
               
            },
        async generateReport(){
            this.loading = true
            var opt= {
                    margin: [15, 0, 25, 0],
                    filename: `profile.pdf`,

                    enableLinks: true,

                    image: {
                        type: 'jpeg', 
                        quality: 1
                    },

                    html2canvas: {
                        useCORS: true,
                        enableLinks: true,
                        scale: 10
                    },

                    jsPDF: {
                        unit: 'mm',
                        format: 'a4',
                        orientation: 'portrait'
                    }
                }

            //check if enough tokens
            //this.tokens_report = Math.trunc(this.totalArea / 10000)
            console.log('this.$tokensValue', this.$tokensValue)
            console.log('tokens_report', this.tokensReport)
            if(this.$tokensValue < this.tokensReport){
                this.$swal.fire({
                    icon: 'error',
                    text: this.$t('MsgNotEnoughTokensReport'),
                })
                this.loading = false
                return
            }

            //this.$refs['mapPDF'].style.display = 'block'
            this.downloadReportMap = true
            // $('.showMap').html($('#map').html());
            var date = Date.now()
            var token = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2)
            
            this.currentReportDate = date
            this.currentReportToken = token.toUpperCase()
            this.currentReportName = date + '_' + this.countrySelected.name + '_report'
            var html1 = await html2pdf().set(opt).from(this.$refs['pdf-content']).toPdf().get('pdf').then(async (pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
					
                    for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i)
                        pdf.setFontSize(11)
                        pdf.setTextColor('#000000')
                        pdf.setFont(undefined, 'bold')
						pdf.text('Token: ' + this.currentReportToken + ' | Fecha:' + this.currentReportDate, (pdf.internal.pageSize.getWidth() * 0.1), (pdf.internal.pageSize.getHeight() - 10))
						pdf.text('Puede validar este documento en: https://naturalcapitalmatters.com/report-check', (pdf.internal.pageSize.getWidth() * 0.1), (pdf.internal.pageSize.getHeight() - 5))
                    } 
                    //let link = document.createElement('a');
                   // link.target = '_blank';
                    //link.href = pdf.output('bloburl');
                    //link.download = this.currentReportName;
                    const formData = new FormData();    
                    console.log(this.currentReportName)
                    formData.append("reports", pdf.output('blob'), this.currentReportName + '.pdf');
                    formData.append("token",this.currentReportToken);
                    formData.append("date", this.currentReportDate);
                    formData.append("polygons", JSON.stringify(this.currentPolygons));
                    formData.append("project_id", this.$projectSelected.id);
                    formData.append("value", this.tokensReport);
                    formData.append("organization", this.$orgSelected.id);
                    formData.append("role", this.$roleSelected.user_role);
                    console.log(formData)
                    console.log('download report new method')
                    MapsDataService.uploadReport(formData).then(
                            async response => {
                                console.log(response.data)
                                await ReportsDataService.downloadReport(response.data.token).then(
                                    async response => {
                                        console.log(response)
                                        const href = URL.createObjectURL(response.data);
                                        
                                        // create "a" HTML element with href to file & click
                                        const link = document.createElement('a');
                                        link.href = href;
                                        link.setAttribute('download', 'report_compressed'); //or any other extension
                                        document.body.appendChild(link);
                                        link.click();

                                        // clean up "a" element & remove ObjectURL
                                        document.body.removeChild(link);
                                        URL.revokeObjectURL(href);
                                        this.loading = false

                                    },
                                    error => {
                                        this.$swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: error.response.data.message
                                            })
                                    }
                                )

                            },
                            error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.message
                                })
                            }
                        )
                    this.loading = false
                })
            this.downloadReportMap = false
            // this.$refs.html2Pdf.generatePdf()
        },
        focusPrepend(focus){
            this.$nextTick(() => {
                // const prependSearch = this.$refs.prependSearch;
                // prependSearch.focus();
                if(focus){
                    console.log('focus')
                    const prependSearch2 = document.getElementById('prependSearch')
                    prependSearch2.classList.add('focus-prepend')
                    
                }
                else{
                    console.log('unfocus')
                    const prependSearch2 = document.getElementById('prependSearch')
                    prependSearch2.classList.remove('focus-prepend')
                    
                }
                

            });
            

        },
        selectCountry(){
            this.selectedOption = true
        },
        selectThisOption(option){

            this.enableCadastre = false
            this.getGeoserverLayers()
            this.getCadastreLayers()
            

            var country = this.countries.filter(f => f.name == option)[0]
            this.countrySelected = country

            console.log(country)
            this.getDataLayers()
            //this.getCadastreLayers()

            // if(this.checkInitializedMap == false){
            //     this.initializeMap()
            //     this.modals.initial = !this.modals.initial
            // }else{
            //     this.modals.initial = !this.modals.initial
            // }

            this.checkInitializedMap = true

            var cadastres = this.optionsArray.filter(f => f.name == this.countrySelected.name)
            console.log(cadastres.length)
            if(cadastres.length > 0){
                this.enableCadastre = true
            }
            console.log(this.countrySelected)
            this.isSelectorLoaded = false;
            this.initializeMap()
            this.isMapLoaded = true;
        },
        filterCountries() {
            // You can add additional filtering logic here if needed
            // This method will be called when the input value changes
        },
        selectThisCountryInitial(option) {
            this.countrySelected = option;
            this.isMapLoaded = true;
        },
        filterCountriesInitial() {
            // You can add additional filtering logic here if needed
            // This method will be called when the input value changes
        },
        checkExternalRole(user){
            console.log('$roleSelected', this.$roleSelected)
            this.role = this.$roleSelected.user_role
            console.log(this.role)
        },
        executeIntersection(){
            console.log(this.currentPolygons)
            this.multipleLayerIntersectionPolygon(this.currentPolygons)
            this.modals.final = false
        },
        showFinalModal(){
            this.modals.final = true
        },
        chooseBaseMap(map){

            if(map == 'OSM'){
                this.selectedBaseMap = 'OSM'
                // this.map.removeLayer(this.layerSatellite);
                // this.map.addLayer(this.layerOSM);
                this.map.setLayerGroup(this.layerOSM);
                this.mapPDF.setLayerGroup(this.layerOSM);
            }
            else if (map == 'Satellite'){
                this.selectedBaseMap = 'Satellite'
                // this.map.removeLayer(this.layerOSM);
                // this.map.addLayer(this.layerSatellite);

                this.map.setLayerGroup(this.layerSatellite);
                this.mapPDF.setLayerGroup(this.layerSatellite);
            }

        },
        scalePolygon(polygon, scale){
            const feature = new Feature({
                geometry: new Polygon(polygon),
                name: 'My Polygon',
            });
            const scaledFeaturePolygone = feature.getGeometry().clone();
            scaledFeaturePolygone.scale(scale, scale);

            console.log(scaledFeaturePolygone)
            return scaledFeaturePolygone
            // const featureCoords = feature.getGeometry().getCoordinates();

            // for (let i = 0; i < featureCoords.length; i++) {
            // const coords = featureCoords[i];
            // for (let j = 0; j < coords.length; j++) {
            //     const coord = coords[j];
            //     if (!scaledFeaturePolygone.intersectsCoordinate(coord)) {
            //     return false;
            //     }
            // }
            // }

            // return true;
        },
        
        async multipleLayerIntersectionPolygon(polygons){
            let data = {
                intersections: []
            }

            console.log(polygon)
            for(var polygon of polygons){
                console.log('first coord: ', polygon[0])
                console.log('last coord: ', polygon[polygon.length - 1])
                if(polygon[0].latitude != polygon[polygon.length -1].latitude || polygon[0].longitude != polygon[polygon.length -1].longitude){
                    var first_coord = polygon[0]
                    polygon.push(first_coord)
                }  
            }


            //let layer_to_intersect =  'ncm:' + this.selectedDataLayer.Name

            for(let layer_to_intersect of this.data_layers){
                for(var polygon of polygons){

                    var first_coord = polygon[0]
                    polygon.push(first_coord)

                    let layer = layer_to_intersect
                
                    let correct_bb = layer.BoundingBox
                    if(correct_bb['@SRS'] != this.projectionBase && correct_bb['@SRS'] != 'EPSG:4258'){
                        await MapsDataService.getDefinition(correct_bb['@SRS']).then(
                            async response => {
                                console.log(response.data)
                                proj4.defs(correct_bb['@SRS'],response.data.definition);
                                registerOL_Proj(proj4);
                                let new_polygon = []
                                for(let coordinate of polygon){
                                    console.log('coordinate projection base: ', coordinate)
                                    let new_coordinate = transform([coordinate['latitude'], coordinate['longitude']], this.projectionBase, correct_bb['@SRS'])
                                    console.log('coordinate transform: ', new_coordinate)
                                    new_polygon.push({latitude: Number(new_coordinate[0].toFixed(3)), longitude: Number(new_coordinate[1].toFixed(3))})
                                }
                                console.log('Polygon for:', correct_bb['@SRS'], new_polygon)
                                
                                let data_dict = {
                                    layer: layer.Name,
                                    polygon: new_polygon,
                                    SRS: correct_bb['@SRS']
                                }
                                data.intersections.push(data_dict)

                            },
                            error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.message
                                })
                            }
                        )
                    }
                    else{
                        console.log('original polygon, switching')
                        // let new_polygon = []
                        // for(let coordinate of polygon){
                        //     new_polygon.push({latitude: coordinate['longitude'], longitude: coordinate['latitude']})
                        // }
                        let data_dict = {
                            layer: layer.Name,
                            polygon: polygon,
                            SRS: this.projectionBase
                        }
                        data.intersections.push(data_dict)
                    }




                }
                this.loading = true;
                console.log(data)

                MapsDataService.multipleLayerIntersection(data).then(
                    async response => {
                        console.log(response.data)
                        this.layer_intersections = response.data.layers_intersections
                        var i = 0
                        var emptyIntersection = true
                        for(let layer_intersection of response.data.layers_intersections){
                            this.table_data = layer_intersection.intersection_points
                            if(this.table_data.length > 0){
                                emptyIntersection = false
                            }
                            this.table_headers = null
                            this.style = new Style({
                                fill: new Fill({
                                    color: 'rgba(255, 255, 255, 0.2)'
                                }),
                                stroke: new Stroke({
                                    color: '#ffcc33',
                                    width: 3
                                }),

                                image: new Circle({
                                    radius: 7,
                                    fill: new Fill({
                                        color: '#ffcc33'
                                    })
                                })
                            });

                            let geojson = await new VectorLayer({
                                //title:'dfdfd',
                                //title: '<h5>' + value_crop+' '+ value_param +' '+ value_seas+' '+value_level+'</h5>',
                                source: new VectorSource({
                                    url: layer_intersection.url,
                                    format: new GeoJSON()
                                }),
                                style: this.style,

                            });

                            // geojson.getSource().on('addfeature', () => {
                            //     //alert(geojson.getSource().getExtent());
                            //     this.map.getView().fit(
                            //         geojson.getSource().getExtent(), {
                            //             duration: 1590,
                            //             size: this.map.getSize()
                            //         }
                            //     );
                            //     this.mapPDF.getView().fit(
                            //         geojson.getSource().getExtent(), {
                            //             duration: 1590,
                            //             size: this.mapPDF.getSize()
                            //         }
                            //     );
                            // });

                        
                            var geom = this.createGeom(polygons[i])
                            
                            var polygon_obj = new Polygon(geom)
                            polygon_obj.scale(2)
                            var extent = polygon_obj.getExtent()
                            console.log('polygon_obj', polygon_obj)

                            this.map.getView().fit(
                                polygon_obj.getExtent(), {
                                        duration: 1590,
                                        size: this.map.getSize()
                                    }
                                );
                           
                            this.mapPDF.getView().fit(
                                polygon_obj.getExtent(), {
                                    duration: 1590,
                                    size: this.mapPDF.getSize()
                                }
                            );
                           
                            //overlays.getLayers().push(geojson);
                            //this.map.addLayer(geojson);
                            this.map.removeInteraction(this.draw1);

                            //this.mapPDF.addLayer(geojson);
                            this.mapPDF.removeInteraction(this.draw2);

                            this.array_geojson.push(geojson)

                            // this.map.on('singleclick', this.highlight);
                            this.downloadAvailable = true;
                            this.$toasted.show(this.$t("Maps.Alert"), {
                                theme: "toasted-primary",
                                position: "top-right",
                                duration : 5000
                            });
                            // this.$refs['map'].style.height = '71%';
                            // this.$refs['table_data'].style.height = '29%';
                            // this.$refs['table_data'].style.padding = '10px';
                            this.map.updateSize();
                            this.mapPDF.updateSize();

                            if(this.typeOfReport == 0){

                                

                                // var polygon_wkt_param = 'POLYGON(('
                                // for(let coords of polygons[i]){
                                //     polygon_wkt_param += coords["latitude"] + " " + coords["longitude"] + ","
                                // }
                                    
                                // polygon_wkt_param = polygon_wkt_param.slice(0, -1)
                                // polygon_wkt_param += '))'
                                // console.log(polygon_wkt_param)
                                
                                // var clip_body = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd"> \
                                //                     <ows:Identifier>gs:Clip</ows:Identifier> \
                                //                     <wps:DataInputs> \
                                //                         <wps:Input> \
                                //                         <ows:Identifier>features</ows:Identifier> \
                                //                         <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST"> \
                                //                             <wps:Body> \
                                //                             <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:ncm="https://naturalcapitalmatters.com/"> \
                                //                                 <wfs:Query typeName="${layer_intersection.layer}"/> \
                                //                             </wfs:GetFeature> \
                                //                             </wps:Body> \
                                //                         </wps:Reference> \
                                //                         </wps:Input> \
                                //                         <wps:Input> \
                                //                         <ows:Identifier>clip</ows:Identifier> \
                                //                         <wps:Data> \
                                //                             <wps:ComplexData mimeType="application/wkt"><![CDATA[${polygon_wkt_param}]]></wps:ComplexData> \
                                //                         </wps:Data> \
                                //                         </wps:Input> \
                                //                     </wps:DataInputs> \
                                //                     <wps:ResponseForm> \
                                //                         <wps:RawDataOutput mimeType="application/zip"> \
                                //                         <ows:Identifier>result</ows:Identifier> \
                                //                         </wps:RawDataOutput> \
                                //                     </wps:ResponseForm> \
                                //                     </wps:Execute>`


                                // $.ajax({ type: "POST",
                                //     url: layer_intersection.clip_url,
                                //     data: clip_body,
                                //     contentType: "text/xml",
                                //     cache: false,
                                //     xhrFields:{
                                //         responseType: 'blob'
                                //     },
                                //     error: function(err) { console.log('error XML request', err) },
                                //     success: function(data) {
                                //         console.log('success XML Request!!', data);
                                //         let link = document.createElement('a');
                                //         link.target = '_blank';
                                //         link.href = URL.createObjectURL(data);
                                //         link.click()
                                //         link.remove()
                                //     }
                                // });
                            }
                            else if (this.typeOfReport == 1){

                            }
                            
                            i++
                            // $('.showMap').html($('#map').html());
                            


                        }
                        this.loading = false;
                        if(emptyIntersection){
                            this.$swal.fire({
                                icon: 'info',
                                title: this.$t('MsgEmptyIntersectionTitle'),
                                text: this.$t('MsgEmptyIntersection'),
                            })
                        }

                    },
                    error => {
                        console.log((error.response && error.response.data) || error.message || error.toString())
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorMultipleIntersection'),
                            });
                        this.loading = false;
                    }
                )
            }
        },
        deleteLayer(index) {
            // Remove the layer at the specified index
            this.query_layers_list.splice(index, 1);
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        handleButtonClick(buttonText) {
            console.log(`Hiciste clic en ${buttonText}`);
            // Agrega tu lógica para el clic en el botón aquí
            this.toggleDropdown(); // O cierra el menú después de hacer clic en un botón
        },
        drawPolygon(coord_array){

            console.log(coord_array)

            var polygon_draw = []
            for(var coordinate of coord_array){
                var coord_element = []
                coord_element.push(coordinate['latitude'])
                coord_element.push(coordinate['longitude'])
                polygon_draw.push(coord_element)
            }

            const layer = new VectorLayer({
                source: new VectorSource({
                    features: [new GeoJSON(
                        {
                            dataProjection: 'EPSG:4326',
                            featureProjection: 'EPSG:4326',
                        }).readFeature({
                        'type': 'Feature',
                        'geometry': {
                        'type': 'Polygon',
                        'coordinates': [polygon_draw]
                        }
                    })]
                }),
                style: new Style({
                    stroke: new Stroke({
                        color: 'rgba(60, 157, 205, 1)',
                        width: 2.5
                    }),
                    fill: new Fill({
                        color: 'rgba(246, 244, 241, 0.5)',
                        opacity: 0.5
                    }),
                })
            });

            this.overlays.getLayers().push(layer);
            this.overlaysPDF.getLayers().push(layer);
            this.currentPolygons.push(coord_array)
            console.log([polygon_draw])
            var polygon_obj = new Polygon([polygon_draw])
            console.log(polygon_obj)
            let area = getArea(polygon_obj, {
                projection: 'EPSG:4326'
            });
            this.totalArea += area

        },

        async initializeMap(){

            // if (this.selectedOption == 'option2'){
            //     this.projectionBase = 'EPSG:4326'
            //     this.centerBase = [-2.90988888614811,39.101193373642424]
            // }
            // else if(this.selectedOption == 'option3'){
            //     this.centerBase = [-2.90988888614811,39.101193373642424]
            //     this.projectionBase = 'EPSG:4326'
            // }

            this.createBaseMap()


            emitter.on('es', () => {

                this.buttonShowQuery = this.$t("Maps.OpenQuery"),
                this.buttonShowLegend = this.$t("Maps.ShowLegend"),
                this.buttonShowInfo = this.$t("Maps.GetInfo")
            })

            emitter.on('en', () => {

                this.buttonShowQuery = this.$t("Maps.OpenQuery"),
                this.buttonShowLegend = this.$t("Maps.ShowLegend"),
                this.buttonShowInfo = this.$t("Maps.GetInfo")
            })

            emitter.on('toggleSideBar', () => {

                this.modifyDiv();

            })


            this.source = new VectorSource();
            this.vectorLayer = new VectorLayer({
                source: this.source,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: '#ffcc33',
                        width: 2
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: '#ffcc33'
                        })
                    })
                })
            })

            this.map.addLayer(this.vectorLayer)
            this.mapPDF.addLayer(this.vectorLayer)

            this.source1 = new VectorSource({
                wrapX: false
            });

            this.vector1 = new VectorLayer({
                source: this.source1
            });
            this.map.addLayer(this.vector1);
            this.mapPDF.addLayer(this.vector1);

            await MapsDataService.getCountryLocation(this.countrySelected.name).then(
                async response => {
                    console.log(response.data)
                    var location = response.data.location
                    var bounding_box = response.data.full_body[0].boundingbox
                    var extent = [parseFloat(bounding_box[2]), parseFloat(bounding_box[0]), parseFloat(bounding_box[3]), parseFloat(bounding_box[1])]
                    console.log(extent)

                    this.map.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.map.getSize()
                        }
                    );
                    this.mapPDF.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.mapPDF.getSize()
                        }
                    );

                    // this.map.getView().setCenter([parseFloat(location[1]), parseFloat(location[0])]);
                    // this.map.getView().setZoom(5);
                },
                error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorLocation'),
                        });
                }
            )

        },
        modifyDiv() {

            console.log('this.$sidebarCollapsed', this.$sidebarCollapsed)
            console.log(this.$refs)
            console.log(this.mapElement)
            if(this.$sidebarCollapsed){
                this.mapElement.style.width = "98%";
                this.mapElement.style.left = "2%";
                this.buttonRowElement.style.width = "86%";
                this.buttonRowElement.style.marginLeft = "80px";
                this.queryTabElement.style.left = "0%";
                this.mapElement.style.top = "112px";
                this.mapElement.style.height = "88.2%";
            }else{
                this.mapElement.style.width = "86%";
                this.mapElement.style.left = "14%";
                this.buttonRowElement.style.width = "82%";
                this.buttonRowElement.style.marginLeft = "20px;";
                this.queryTabElement.style.left = "14%";
                this.mapElement.style.top = "112px";
                this.mapElement.style.height = "88.2%";
                // this.queryContentElement.style.left = "14%"; //FALLO
                // this.queryPanelContentElement.style.left = "14%";
                // this.queryPanelBtnsElement.style.left = "14%";
            }
        },
        validateState (ref) {
            if (
                this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
        toggleCategory(){
            this.toggleCat = !this.toggleCat;
        },
        showUploadLayerModal(){
            this.modals.upload = true
        },
        showSelectOptionModal(){
            this.modals.initial = true
        },
        fetchOptions(){
            CadastreDataService.findAll().then(
                    response => {
                        this.optionsArray = response.data.data.filter(option => option.country);
                        console.log("HELLOOOOO", this.optionsArray);

                        // if (Array.isArray(response.data.data)) {
                        //     // Assuming response.data is an array of options and you want to filter by a specific criterion
                        //     this.optionsArray = response.data.data.filter(option => option.someCriteria === 'country');

                        //     // Or, if you want to find a specific element based on a criterion
                        //     // this.optionsArray = response.data.find(option => option.someCriteria === 'desiredValue');

                        //     // Now you can use this.optionsArray in your component
                        // } else {
                        //     console.error('Data is not an array:', response.data);
                        //     // Handle the case where response.data is not an array
                        // }
                    },
                    error => {
                        console.log((error.response && error.response.data) || error.message || error.toString())
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Profile.Alerts.UpdateAvatar.Error')
                            });
                        this.loading = false;
                    }
            )
        },

       async selectThisCountry(option){
            this.enableCadastre = false
            this.getGeoserverLayers()
            this.getCadastreLayers()
            this.countrySelected = option
            this.getDataLayers()
            await MapsDataService.getCountryLocation(this.countrySelected.name).then(
                async response => {
                    console.log(response.data)
                    var location = response.data.location
                    var bounding_box = response.data.full_body[0].boundingbox
                    var extent = [parseFloat(bounding_box[2]), parseFloat(bounding_box[0]), parseFloat(bounding_box[3]), parseFloat(bounding_box[1])]
                    console.log(extent)

                    this.map.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.map.getSize()
                        }
                    );

                    this.mapPDF.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.mapPDF.getSize()
                        }
                    );
                    // this.map.getView().setCenter([parseFloat(location[1]), parseFloat(location[0])]);
                    // this.map.getView().setZoom(5);

                    var cadastres = this.optionsArray.filter(f => f.name == this.countrySelected.name)
                    console.log(cadastres.length)
                    if(cadastres.length > 0){
                        this.enableCadastre = true
                    }
                },
                error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorLocation')
                        });
                }
            )
            console.log(this.countrySelected)
       },
        uploadLayer(){
            this.$validator.validateAll().then(async result => {
                if (!result) {
                return
                }
                console.log(this.$refs.myVueDropzone.getAcceptedFiles())

                let formData = new FormData()
                // formData.append('id', user_id)
                // formData.append('delete_path', this.imagePath)
                //let shape_files = this.$refs['fileInput'].files
                let shape_files = this.$refs.myVueDropzone.getAcceptedFiles()
                console.log(shape_files)


                if(shape_files.length < 3){
                this.$swal.fire({
                icon: 'warning',
                title: this.$t('Alerts.NotEnoughFiles'),
                text: this.$t('Alerts.InfoFiles')
                }).then(() => {
                    return
                });
                }

                let ext_array = []


                let i = 0
                for( let shapefile of shape_files){
                console.log(shapefile)
                let ext = shapefile.name.split('.')[shapefile.name.split('.').length - 1]
                ext_array.push(ext)
                formData.append('files[' + i +']', shapefile)
                i++

                }

                console.log(ext_array)
                if(ext_array.includes('dbf') && ext_array.includes('shx') && ext_array.includes('shp')){
                formData.append('label', this.label_form)
                formData.append('projection', this.projection_form)
                formData.append('is_data_layer', false)

                this.loading = true
                MapsDataService.uploadLayer(formData).then(
                    response => {
                        if(response.status == 200){
                            this.loading = false
                            this.$swal.fire({
                            icon: 'success',
                            title: this.$t('Alerts.LayerUploadedCorrectly')
                            }).then(() => {
                                this.modals.upload = false
                                this.projection_form = ''
                                this.label_form = ''
                                this.$refs.myVueDropzone.removeAllFiles(true)
                                this.getGeoserverLayers()
                            });

                        }

                    },
                    error => {
                        this.loading = false
                        if(error.response.status == 409){
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorUploadLayerDuplicated'),
                            text: this.$t('Alerts.ErrorUploadLayerDuplicatedMsg'),
                            });
                        }
                        else{
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Profile.Alerts.UpdateAvatar.Error')
                            });
                        }
                        console.log((error.response && error.response.data) || error.message || error.toString())
                    }

                    )
                }
                else{
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Error',
                    text: this.$t('Alerts.InfoFiles')
                    }).then(() => {
                        return
                    });
                }

            })


        },
        addLayerListQuery(){
            console.log(this.selectedLayerTab)
            this.query_layers_list.push(this.selectedLayerTab)
        },
        hightlight(){
            console.log("Entra highlight");
        },
        selectRow (items) {
            this.selectedElement = items[0]
        },
        selectTab(tab){
            if(tab == 'attributes'){
                this.drawSelected = false
                this.attributesSelected = true
            }
            else if (tab == 'draw'){
                this.drawSelected = true
                this.attributesSelected = false
            }
        },
        chooseMeasureOption(option){
            this.measureOption = option

            this.map.un('singleclick', this.getInfo);
            this.mapPDF.un('singleclick', this.getInfo);
            this.showInfoActivated = false
            this.buttonShowInfo = this.$t("Maps.GetInfo")

            if (this.popup) {
                this.popup.hide();
            }

            this.map.removeInteraction(this.draw);
            this.mapPDF.removeInteraction(this.draw);
            this.addInteraction();
        },
        showWMSLayersModal(){
            this.table_layers = this.wms_layers
            this.modals.wms_layers = true
            this.selectedLayerOption = "WMSLayers"
        },
        showExternalLayersModal(){
            this.table_layers = this.user_layers
            this.modals.uploaded_layers = true
            this.selectedLayerOption = "UserLayers"
        },
        showCatastroModal(){
            this.modals.catastro = true
        },
        showFeaturesModal(){
            this.modals.features_layers = true
        },
        async add_draw_Interaction(){
            let value = this.selectedGeometryTab;
            if (value) {
                let geometryFunction;
                if (value === 'Square') {
                    value = 'Circle';
                    geometryFunction = createRegularPolygon(4);

                } else if (value === 'Box') {
                    value = 'Circle';

                    geometryFunction = createBox();

                } else if (value === 'Star') {
                    value = 'Circle';
                    geometryFunction = function(coordinates, geometry) {
                        //alert(value);
                        let center = coordinates[0];
                        let last = coordinates[1];
                        let dx = center[0] - last[0];
                        let dy = center[1] - last[1];
                        let radius = Math.sqrt(dx * dx + dy * dy);
                        let rotation = Math.atan2(dy, dx);
                        let newCoordinates = [];
                        let numPoints = 12;
                        for (let i = 0; i < numPoints; ++i) {
                            let angle = rotation + i * 2 * Math.PI / numPoints;
                            let fraction = i % 2 === 0 ? 1 : 0.5;
                            let offsetX = radius * fraction * Math.cos(angle);
                            let offsetY = radius * fraction * Math.sin(angle);
                            newCoordinates.push([center[0] + offsetX, center[1] + offsetY]);
                        }
                        newCoordinates.push(newCoordinates[0].slice());
                        if (!geometry) {
                            geometry = new Polygon([newCoordinates]);
                        } else {
                            geometry.setCoordinates([newCoordinates]);
                        }
                        return geometry;
                    };
                }

                // map.addInteraction(draw1);

                if (this.selectedGeometryTab == 'select' || this.selectedGeometryTab == 'clear') {

                    if(this.draw1){this.map.removeInteraction(this.draw1);}
                    if(this.draw2){this.mapPDF.removeInteraction(this.draw2);}
                    this.vector1.getSource().clear();

                    if (this.array_geojson.length > 0) {
                        for(let geojson of this.array_geojson){
                            geojson.getSource().clear();
                            this.map.removeLayer(geojson);
                            this.mapPDF.removeLayer(geojson);
                        }

                    }

                } else if (this.selectedGeometryTab == 'Square' || this.selectedGeometryTab == 'Polygon' ||this.selectedGeometryTab == 'Circle' || this.selectedGeometryTab == 'Star' || this.selectedGeometryTab == 'Box'){

                    this.draw1 = new Draw({
                        source: this.source1,
                        type: value,
                        geometryFunction: geometryFunction
                    });

                    this.draw2 = new Draw({
                        source: this.source1,
                        type: value,
                        geometryFunction: geometryFunction
                    });


                    this.map.addInteraction(this.draw1);
                    this.mapPDF.addInteraction(this.draw2);

                    this.draw1.on('drawstart', (evt) => {
                        // if (this.vector1) {
                        //     this.vector1.getSource().clear();
                        // }
                        // if (this.array_geojson.length > 0) {
                        //     for(let geojson of this.array_geojson){
                        //         geojson.getSource().clear();
                        //         this.map.removeLayer(geojson);
                        //     }

                        // }

                        //alert('bc');

                    });

                    this.draw1.on('drawend', async (evt) => {
                        let feature = evt.feature;

                        let coords = feature.getGeometry();
                        //console.log(coords);
                        let format = new WKT();
                        let wkt = format.writeGeometry(coords);

                        //let layer_name = document.getElementById("layer1");
                        let value_layer = this.selectedLayerTab

                        console.log(wkt)
                        let regExp = /\(([^)]+)\)/;
                        let matches = regExp.exec(wkt)
                        let polygon_coords_str = matches[1].substring(1)
                        let polygon_coords = polygon_coords_str.split(',')
                        let polygon = []

                        for(let coord_str of polygon_coords){
                            let coord_dict = {}
                            let coord = coord_str.split(' ')
                            coord_dict.latitude = parseFloat(coord[0])
                            coord_dict.longitude = parseFloat(coord[1])
                            polygon.push(coord_dict)
                        }

                        console.log(polygon)
                        // if(!this.multiple_layer){
                        //     let data = {
                        //         polygon: polygon,
                        //         layer: value_layer
                        //     }
                        //     this.loading = true;
                        //     MapsDataService.simpleLayerIntersection(data).then(
                        //         async response => {
                        //             console.log(response.data)
                        //             this.loading = false;
                        //             this.table_data = response.data.intersection_points
                        //             this.table_headers = null
                        //             this.style = new Style({
                        //                 fill: new Fill({
                        //                     color: 'rgba(255, 255, 255, 0.2)'
                        //                 }),
                        //                 stroke: new Stroke({
                        //                     color: '#ffcc33',
                        //                     width: 3
                        //                 }),

                        //                 image: new Circle({
                        //                     radius: 7,
                        //                     fill: new Fill({
                        //                         color: '#ffcc33'
                        //                     })
                        //                 })
                        //             });

                        //             this.geojson = new VectorLayer({
                        //                 //title:'dfdfd',
                        //                 //title: '<h5>' + value_crop+' '+ value_param +' '+ value_seas+' '+value_level+'</h5>',
                        //                 source: new VectorSource({
                        //                     url: response.data.url,
                        //                     format: new GeoJSON()
                        //                 }),
                        //                 style: this.style,

                        //             });

                        //             this.geojson.getSource().on('addfeature', () => {
                        //                 //alert(geojson.getSource().getExtent());
                        //                 this.map.getView().fit(
                        //                     this.geojson.getSource().getExtent(), {
                        //                         duration: 1590,
                        //                         size: this.map.getSize()
                        //                     }
                        //                 );
                        //             });

                        //             //overlays.getLayers().push(geojson);
                        //             this.map.addLayer(this.geojson);
                        //             this.map.removeInteraction(this.draw1);

                        //             // this.map.on('singleclick', this.highlight);
                        //             this.downloadAvailable = true;
                        //             this.$toasted.show(this.$t("Maps.Alert"), {
                        //                 theme: "toasted-primary",
                        //                 position: "top-right",
                        //                 duration : 5000
                        //             });
                        //             // this.$refs['map'].style.height = '71%';
                        //             // this.$refs['table_data'].style.height = '29%';
                        //             // this.$refs['table_data'].style.padding = '10px';
                        //             this.map.updateSize();

                        //         },
                        //         error => {
                        //             console.log((error.response && error.response.data) || error.message || error.toString())
                                    // this.$swal.fire({
                                    //     icon: 'error',
                                    //     title: 'Error in the simple intersection.'
                                    // });
                                    // this.loading = false;
                        //         }
                        //     )
                        // }
                        //else{

                            let data = {
                                intersections: []
                            }

                            for(let layer_to_intersect of this.query_layers_list){
                                console.log(layer_to_intersect)
                                let layer = this.wms_layers.filter(f => f.Name == layer_to_intersect)[0]
                                console.log(layer)
                                let correct_bb = layer.BoundingBox
                                if(correct_bb['@SRS'] != this.projectionBase && correct_bb['@SRS'] != 'EPSG:4258'){
                                    await MapsDataService.getDefinition(correct_bb['@SRS']).then(
                                        async response => {
                                            console.log(response.data)
                                            proj4.defs(correct_bb['@SRS'],response.data.definition);
                                            registerOL_Proj(proj4);
                                            let new_polygon = []
                                            for(let coordinate of polygon){
                                                console.log('coordinate projection base: ', coordinate)
                                                let new_coordinate = transform([coordinate['latitude'], coordinate['longitude']], this.projectionBase, correct_bb['@SRS'])
                                                console.log('coordinate transform: ', new_coordinate)
                                                new_polygon.push({latitude: new_coordinate[0], longitude: new_coordinate[1]})
                                            }
                                            console.log('Polygon for:', correct_bb['@SRS'], new_polygon)
                                            let data_dict = {
                                                layer: layer_to_intersect,
                                                polygon: new_polygon,
                                                SRS: correct_bb['@SRS']
                                            }
                                            data.intersections.push(data_dict)

                                        },
                                        error => {
                                            this.$swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: error.response.data.message
                                            })
                                        }
                                    )
                                }
                                else{
                                    console.log('original polygon, switching')
                                    // let new_polygon = []
                                    // for(let coordinate of polygon){
                                    //     new_polygon.push({latitude: coordinate['longitude'], longitude: coordinate['latitude']})
                                    // }
                                    let data_dict = {
                                        layer: layer_to_intersect,
                                        polygon: polygon,
                                        SRS: this.projectionBase
                                    }
                                    data.intersections.push(data_dict)
                                }




                            }
                            this.loading = true;
                            console.log(data)

                            MapsDataService.multipleLayerIntersection(data).then(
                                async response => {
                                    console.log(response.data)
                                    for(let layer_intersection of response.data.layers_intersections){
                                        this.table_data = layer_intersection.intersection_points
                                        this.table_headers = null
                                        this.style = new Style({
                                            fill: new Fill({
                                                color: 'rgba(255, 255, 255, 0.2)'
                                            }),
                                            stroke: new Stroke({
                                                color: '#ffcc33',
                                                width: 3
                                            }),

                                            image: new Circle({
                                                radius: 7,
                                                fill: new Fill({
                                                    color: '#ffcc33'
                                                })
                                            })
                                        });

                                        let geojson = new VectorLayer({
                                            //title:'dfdfd',
                                            //title: '<h5>' + value_crop+' '+ value_param +' '+ value_seas+' '+value_level+'</h5>',
                                            source: new VectorSource({
                                                url: layer_intersection.url,
                                                format: new GeoJSON()
                                            }),
                                            style: this.style,

                                        });

                                        geojson.getSource().on('addfeature', () => {
                                            //alert(geojson.getSource().getExtent());
                                            this.map.getView().fit(
                                                geojson.getSource().getExtent(), {
                                                    duration: 1590,
                                                    size: this.map.getSize()
                                                }
                                            );
                                            this.mapPDF.getView().fit(
                                                geojson.getSource().getExtent(), {
                                                    duration: 1590,
                                                    size: this.mapPDF.getSize()
                                                }
                                            );
                                        });

                                        //overlays.getLayers().push(geojson);
                                        this.map.addLayer(geojson);
                                        this.map.removeInteraction(this.draw1);

                                        this.mapPDF.addLayer(geojson);
                                        this.mapPDF.removeInteraction(this.draw2);

                                        this.array_geojson.push(geojson)

                                        // this.map.on('singleclick', this.highlight);
                                        this.downloadAvailable = true;
                                        this.$toasted.show(this.$t("Maps.Alert"), {
                                            theme: "toasted-primary",
                                            position: "top-right",
                                            duration : 5000
                                        });
                                        // this.$refs['map'].style.height = '71%';
                                        // this.$refs['table_data'].style.height = '29%';
                                        // this.$refs['table_data'].style.padding = '10px';
                                        this.map.updateSize();
                                        this.mapPDF.updateSize();
                                    }
                                    this.loading = false;

                                },
                                error => {
                                    console.log((error.response && error.response.data) || error.message || error.toString())
                                        this.$swal.fire({
                                            icon: 'error',
                                            title: this.$t('Alerts.ErrorMultipleIntersection')
                                        });
                                    this.loading = false;
                                }
                            )
                        //}


                    });


                }


            }
        },
        drawPolygonParcel(){

            if(!this.instersectWithAll){
                this.intersectWithAll = false
                this.$toasted.show(this.$t("Maps.Alert5"), {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 5000
                });
            }

            if(this.showInfoActivated){
                this.map.un('singleclick', this.getInfo);
                this.mapPDF.un('singleclick', this.getInfo);
                this.showInfoActivated = false
                this.buttonShowInfo = this.$t("Maps.GetInfo")
                // document.getElementById("info_btn").setAttribute("class", "btn btn-success btn-sm");
                if (this.popup) {
                    this.popup.hide();
                }
                this.$toasted.show(this.$t("Maps.Alert3"), {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 5000
                });
                this.cursorStyle = 'default'
                if (this.getinfoTooltip) {
                    this.map.removeOverlay(this.getinfoTooltip);
                    this.mapPDF.removeOverlay(this.getinfoTooltip);
                }
            }


            if(!this.drawPolygonActivated){
                this.drawPolygonActivated = true
                let geometryFunction
                this.draw1 = new Draw({
                    source: this.source1,
                    type: 'Polygon',
                    geometryFunction: geometryFunction
                });

                this.draw2 = new Draw({
                    source: this.source1,
                    type: 'Polygon',
                    geometryFunction: geometryFunction
                });
                this.map.addInteraction(this.draw1);
                this.mapPDF.addInteraction(this.draw2);

                this.draw1.on('drawstart', (evt) => {
                    // if (this.vector1) {
                    //     this.vector1.getSource().clear();
                    // }
                    // if (this.array_geojson.length > 0) {
                    //     for(let geojson of this.array_geojson){
                    //         geojson.getSource().clear();
                    //         this.map.removeLayer(geojson);
                    //     }

                    // }

                    //alert('bc');

                });

                this.draw2.on('drawstart', (evt) => {
                    // if (this.vector1) {
                    //     this.vector1.getSource().clear();
                    // }
                    // if (this.array_geojson.length > 0) {
                    //     for(let geojson of this.array_geojson){
                    //         geojson.getSource().clear();
                    //         this.map.removeLayer(geojson);
                    //     }

                    // }

                    //alert('bc');

                });

                this.draw1.on('drawend', async (evt) => {
                    let feature = evt.feature;

                    let coords = feature.getGeometry();
                    
                    

                    //console.log(coords);
                    let format = new WKT();
                    let wkt = format.writeGeometry(coords);
                    console.log('wkt:', wkt)

                    //let layer_name = document.getElementById("layer1");
                    let value_layer = this.selectedLayerTab

                    console.log(wkt)
                    let regExp = /\(([^)]+)\)/;
                    let matches = regExp.exec(wkt)
                    let polygon_coords_str = matches[1].substring(1)
                    let polygon_coords = polygon_coords_str.split(',')
                    let polygon = []
                    console.log('polygon_coords:', polygon_coords)

                    for(let coord_str of polygon_coords){
                        let coord_dict = {}
                        let coord = coord_str.split(' ')
                        coord_dict.latitude = parseFloat(coord[0])
                        coord_dict.longitude = parseFloat(coord[1])
                        polygon.push(coord_dict)
                    }

                    console.log(polygon)
                    this.currentPolygons.push(polygon)
                    let area = getArea(coords, {
                        projection: 'EPSG:4326'
                    });
                    this.totalArea += area



                });

                this.draw2.on('drawend', async (evt) => {
                    let feature = evt.feature;

                    let coords = feature.getGeometry();
                    
                    

                    //console.log(coords);
                    let format = new WKT();
                    let wkt = format.writeGeometry(coords);
                    console.log('wkt:', wkt)

                    //let layer_name = document.getElementById("layer1");
                    let value_layer = this.selectedLayerTab

                    console.log(wkt)
                    let regExp = /\(([^)]+)\)/;
                    let matches = regExp.exec(wkt)
                    let polygon_coords_str = matches[1].substring(1)
                    let polygon_coords = polygon_coords_str.split(',')
                    let polygon = []
                    console.log('polygon_coords:', polygon_coords)

                    for(let coord_str of polygon_coords){
                        let coord_dict = {}
                        let coord = coord_str.split(' ')
                        coord_dict.latitude = parseFloat(coord[0])
                        coord_dict.longitude = parseFloat(coord[1])
                        polygon.push(coord_dict)
                    }

                    console.log(polygon)
                    this.currentPolygons.push(polygon)
                    let area = getArea(coords, {
                        projection: 'EPSG:4326'
                    });
                    this.totalArea += area



                });
                this.selectedMenuOption = "Draw"
            }
            else{

                this.drawPolygonActivated = false

                if(this.draw1){this.map.removeInteraction(this.draw1);}
                if(this.draw2){this.mapPDF.removeInteraction(this.draw2);}
                this.vector1.getSource().clear();
                this.selectedMenuOption = ""
            }



        },
        changeDrawType(){

            this.map.removeInteraction(this.draw1)
            this.mapPDF.removeInteraction(this.draw2)

            if(this.draw){
                this.map.removeInteraction(this.draw);
                this.map.removeOverlay(this.helpTooltip);
                this.map.removeOverlay(this.measureTooltip);
                this.mapPDF.removeInteraction(this.draw);
                this.mapPDF.removeOverlay(this.helpTooltip);
                this.mapPDF.removeOverlay(this.measureTooltip);
            }

            if (this.vectorLayer) {
                this.vectorLayer.getSource().clear();
            }
            if (this.vector1) {
                this.vector1.getSource().clear();
                // $('#table').empty();
            }


            this.showMeasureToolTip = false

            this.add_draw_Interaction();
        },
        async getGeoserverLayersOld(){
            this.loading = true;

            try{
                await MapsDataService.getLayers().then(
                    async response => {
                        console.log(response.data)
                        this.wms_layers = response.data.layers
                        this.loading = false;
                    },
                    error => {
                        console.log((error.response && error.response.data) || error.message || error.toString())
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorIntersection'),
                            });
                            this.loading = false;
                    }
                )
            }
            catch(error){

                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('Alerts.ErrorIntersection'),
                });
                return res.status(504).send({message: error.message});
            }finally{
                this.loading = false;
            }


        },
        async getDataLayers(){
            this.loading = true;
            this.wms_layers = []
            this.user_layers = []
            await MapsDataService.getDataLayersByCountry(this.countrySelected['alpha-3']).then(
                async response => {
                    console.log(response.data)
                    this.data_layers = response.data.data_layers
                    this.selectedDataLayer = response.data.data_layers[0]
                },
                error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorIntersection'),
                        });
                        this.loading = false;
                }
            )
        },
        async getGeoserverLayers(){
            this.loading = true;
            this.wms_layers = []
            this.user_layers = []
            await MapsDataService.getLayers(this.organization_id).then(
                async response => {
                    console.log(response.data)
                    this.wms_layers = response.data.org_layers
                    this.user_layers = response.data.user_layers
                    this.loading = false;
                },
                error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorIntersection'),
                        });
                        this.loading = false;
                }
            )

        //     await MapsDataService.getNCMLayers(this.organization_id, 'admin').then(
        //     Response => {

        //         let layers = Response.data.data
        //         for(let layer of geoserser_layers){
        //             console.log(layer)
        //             console.log(layers.filter(f => 'ncm:' + f.internal_name == layer.Name))
        //             var length = layers.filter(f => 'ncm:' + f.internal_name == layer.Name).length
        //             if(length > 0){
        //                 this.wms_layers.push(layer)
        //             }
        //         }
        //     },
        //     Error => {
        //         console.log(Error)
        //     }

        // )

        // await MapsDataService.getNCMLayers(this.organization_id, 'analyst').then(
        //     Response => {

        //         let layers = Response.data.data
        //         for(let layer of geoserser_layers){
        //             console.log(layers.filter(f => 'ncm:' + f.internal_name == layer.Name))
        //             var length = layers.filter(f => 'ncm:' + f.internal_name == layer.Name).length
        //             if(length > 0){
        //                 this.user_layers.push(layer)
        //             }
        //         }
        //     },
        //     Error => {
        //         console.log(Error)
        //     }
        // )

        // console.log('user layers', this.user_layers)
        },
        getCadastreLayers(){
            this.loading = true;
            
                MapsDataService.getCadastreLayers().then(
                    async response => {
                        console.log(response.data)
                        this.cadastre_layers = response.data.layers
                        this.loading = false;
                    },
                    error => {
                        console.log((error.response && error.response.data) || error.message || error.toString())
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorCadastreLayers')
                            });
                        this.loading = false;
                    }
                )
           
        },
        createBaseMap(){

            this.view = new View({
                projection: this.projectionBase,
                center: this.centerBase,
                zoom: 5,
            });

            this.viewPDF = new View({
                projection: this.projectionBase,
                center: this.centerBase,
                zoom: 5,
            });
            let view_ov = new View({
                projection: 'EPSG:4326',
                center: [19.98, 42.90],
                zoom: 7,
            });
            let base_maps = new Group({
                'title': 'Base maps',
                layers: [
                    new Tile({
                        title: 'Satellite',
                        type: 'base',
                        visible: true,
                        source: new XYZ({
                            attributions: ['Powered by Esri',
                                'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'
                            ],
                            attributionsCollapsible: false,
                            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                            maxZoom: 23
                        })
                    }),
                    new Tile({
                        title: 'OSM',
                        type: 'base',
                        visible: true,
                        source: new OSM()
                    })
                ]
            });


            let base_mapsPDF = new Group({
                'title': 'Base maps',
                layers: [
                    new Tile({
                        title: 'Satellite',
                        type: 'base',
                        visible: true,
                        source: new XYZ({
                            attributions: ['Powered by Esri',
                                'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'
                            ],
                            attributionsCollapsible: false,
                            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                            maxZoom: 23
                        })
                    }),
                    new Tile({
                        title: 'OSM',
                        type: 'base',
                        visible: true,
                        source: new OSM()
                    })
                ]
            });

            this.layerOSM = new Group({
                layers: [
                    new Tile({
                        title: 'OSM',
                        type: 'base',
                        visible: true,
                        source: new OSM()
                    })
                ]
            });

            this.layerSatellite = new Group({
                layers: [
                new Tile({
                        title: 'Satellite',
                        type: 'base',
                        visible: true,
                        source: new XYZ({
                            attributions: ['Powered by Esri',
                                'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'
                            ],
                            attributionsCollapsible: false,
                            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                            maxZoom: 23
                        })
                    }),
                ]
            });

            let OSM_TILE = new Tile({
                source: new OSM(),
                type: 'base',
                title: 'OSM',
            });

            this.overlays = new Group({
                'title': 'Overlays',
                layers: []
            });
            this.overlaysPDF = new Group({
                'title': 'Overlays',
                layers: []
            });
            this.map = new Map({
                target: document.getElementById('map'),
                view: this.view,
                // overlays: [overlay]
            });

            this.mapPDF = new Map({
                target: document.getElementById('mapPDF'),
                view: this.viewPDF,
                // overlays: [overlay]
            });

            this.map.addLayer(base_maps);
            this.map.addLayer(this.overlays);

            this.mapPDF.addLayer(base_mapsPDF);
            this.mapPDF.addLayer(this.overlaysPDF);
            //overlays.getLayers().push(ind_state);
            this.popup = new Popup();
            this.map.addOverlay(this.popup);
            this.mapPDF.addOverlay(this.popup);

            let mouse_position = new MousePosition();
            this.map.addControl(mouse_position);
            this.mapPDF.addControl(mouse_position);
            let slider = new ZoomSlider();
            this.map.addControl(slider);
            this.mapPDF.addControl(slider);

            let zoom_ex = new ZoomToExtent({
                extent: [
                    65.90, 7.48,
                    98.96, 40.30
                ]
            });
            this.map.addControl(zoom_ex);
            this.mapPDF.addControl(zoom_ex);

            let scale_line = new ScaleLine({
                units: 'metric',
                bar: true,
                steps: 6,
                text: true,
                minWidth: 140,
                target: 'scale_bar'
            });
            this.map.addControl(scale_line);
            this.mapPDF.addControl(scale_line);

            this.layerSwitcher = new LayerSwitcher({
                activationMode: 'click',
                startActive: true,
                tipLabel: 'Layers', // Optional label for b-button
                groupSelectStyle: 'children', // Can be 'children' [default], 'group' or 'none'
                collapseTipLabel: 'Collapse layers',
            });
            this.map.addControl(this.layerSwitcher);
            this.mapPDF.addControl(this.layerSwitcher);

            this.layerSwitcher.renderPanel();

            this.geocoder = new Geocoder('nominatim', {
                provider: 'osm',
                lang: 'en',
                placeholder: 'Search for ...',
                limit: 5,
                debug: false,
                autoComplete: true,
                keepOpen: true,
                preventMarker : true
            });
            this.map.addControl(this.geocoder);
            this.mapPDF.addControl(this.geocoder);

            this.geocoder.on('addresschosen', (evt) => {
                let country = evt.address.details.country
                let country_obj = this.countries.filter(f => f.name == country)[0]
                this.countrySelected = country_obj
                var cadastres = this.optionsArray.filter(f => f.name == this.countrySelected.name)
                console.log(cadastres.length)
                if(cadastres.length > 0){
                    this.enableCadastre = true
                }
                console.log(this.countrySelected.name)
            });

            console.log('MAP INITIALIZED!!')


        },

        addInteraction() {

            if (this.measureOption.value == 'select' || this.measureOption.value == 'clear') {

                if (this.draw) {
                    this.map.removeInteraction(this.draw)
                    this.mapPDF.removeInteraction(this.draw)
                };
                if (this.vectorLayer) {
                    this.vectorLayer.getSource().clear();
                }
                if (this.helpTooltip) {
                    this.map.removeOverlay(this.helpTooltip);
                    this.mapPDF.removeOverlay(this.helpTooltip);
                }

                if (this.showMeasureToolTip) {
                    this.showMeasureToolTip = false;
                    // let elem = document.getElementsByClassName("tooltip tooltip-static");
                    // for (let i = elem.length - 1; i >= 0; i--) {
                    //     elem[i].remove();
                    // }
                }
            } else if (this.measureOption.value == 'area' || this.measureOption.value == 'length') {
                let type;
                if (this.measureOption.value == 'area') {
                    type = 'Polygon';
                } else if (this.measureOption.value == 'length') {
                    type = 'LineString';
                }

                this.draw = new Draw({
                    source: this.source,
                    type: type,
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.5)'
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineDash: [10, 10],
                            width: 2
                        }),
                        image: new Circle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.7)'
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.5)'
                            })
                        })
                    })
                });
                this.map.addInteraction(this.draw);
                this.mapPDF.addInteraction(this.draw);
                this.createMeasureTooltip();
                this.createHelpTooltip();

                let pointerMoveHandler = (evt) => {
                    if (evt.dragging) {
                        return;
                    }

                    let helpMsg = 'Click to start drawing';

                    if (this.sketch) {
                        let geom = (this.sketch.getGeometry());
                        if (geom instanceof Polygon) {

                            helpMsg = this.continuePolygonMsg;
                        } else if (geom instanceof LineString) {
                            helpMsg = this.continueLineMsg;
                        }
                    }

                    this.showHelpToolTip = true;
                    this.helpTooltipElement.innerHTML = helpMsg;
                    // this.helpTooltipElement.innerHTML = helpMsg;
                    this.helpTooltip.setPosition(evt.coordinate);

                    // this.helpTooltipElement.classList.remove('hidden');

                };

                this.map.on('pointermove', pointerMoveHandler);

                this.mapPDF.on('pointermove', pointerMoveHandler);
                this.map.getViewport().addEventListener('mouseout', function() {
                    // this.helpTooltipElement.classList.add('hidden');
                    this.showHelpToolTip = false;
                });

                this.mapPDF.getViewport().addEventListener('mouseout', function() {
                    // this.helpTooltipElement.classList.add('hidden');
                    this.showHelpToolTip = false;
                });



                let listener;
                this.draw.on('drawstart',
                    (evt) => {
                        // set sketch


                        //vectorLayer.getSource().clear();

                        this.sketch = evt.feature;

                        let tooltipCoord = evt.coordinate;

                        listener = this.sketch.getGeometry().on('change', (evt) => {
                            let geom = evt.target;

                            let output;
                            if (geom instanceof Polygon) {

                                output = this.formatArea(geom);
                                tooltipCoord = geom.getInteriorPoint().getCoordinates();
                                console.log('output:', output)
                                console.log('tooltipCoord:', tooltipCoord)

                            } else if (geom instanceof LineString) {

                                output = this.formatLength(geom);
                                tooltipCoord = geom.getLastCoordinate();
                            }
                            // this.measureTooltipElement.innerHTML = output;
                            this.showMeasureToolTip = true;
                            this.measureTooltipElement.innerHTML = output;
                            this.measureTooltip.setPosition(tooltipCoord);
                        });
                    }, this);

                    this.draw.on('drawend',
                    // solo borra, hacer un false a showMeasureToolTip
                    () => {
                        // this.measureTooltipElement.className = 'tooltip tooltip-static';
                        this.measureTooltip.setOffset([0, -7]);

                        // unset sketch
                        this.sketch = null;
                        // unset tooltip so that a new one can be created
                        // this.measureTooltipElement = null;
                        this.createMeasureTooltip();
                        new unByKey(listener);
                    }, this);

            }
        },
        createMeasureTooltip() {
            if (this.showMeasureToolTip) {
                this.showMeasureToolTip = false
            }

            this.measureTooltip = new Overlay({
                element: this.measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center'
            });
            this.map.addOverlay(this.measureTooltip);
            this.mapPDF.addOverlay(this.measureTooltip);
        },
        createHelpTooltip() {
            if (this.showHelpToolTip) {
                this.showHelpToolTip = false
            }

            this.helpTooltip = new Overlay({
                element: this.helpTooltipElement,
                offset: [15, 0],
                positioning: 'center-left'
            });
            this.map.addOverlay(this.helpTooltip);
            this.mapPDF.addOverlay(this.helpTooltip);
        },
        createGetinfoTooltip() {
            if (this.showGetinfoTooltip) {
                this.showGetinfoTooltip = false
            }

            this.getinfoTooltip = new Overlay({
                element: this.getinfoTooltipElement,
                offset: [15, 0],
                positioning: 'center-left'
            });
            this.map.addOverlay(this.getinfoTooltip);
            this.mapPDF.addOverlay(this.getinfoTooltip);
        },
        showHideQueryPanel() {
            if (this.$refs['query_tab'].style.visibility == "hidden") {

                // this.$refs['query_panel_btn'].innerHTML = "Hide Query Panel";
                // this.$refs['query_panel_btn'].setAttribute("class", "btn btn-danger btn-sm");
                this.$refs['query_tab'].style.visibility = "visible";
                this.$refs['query_tab'].style.height = "70%";
                this.$refs['query_tab'].style.width = "620px";

                this.$refs['query_tab'].style.right = '1%';
                this.buttonShowQuery = this.$t("Maps.CloseQuery")
                this.map.un('singleclick', this.getInfo);
                this.mapPDF.un('singleclick', this.getInfo);
                this.buttonShowInfo = this.$t("Maps.GetInfo")
                // this.$refs['table_data'].style.left = '30%';
                this.map.updateSize();
                this.mapPDF.updateSize();
            } else {
                // this.$refs['query_panel_btn'].innerHTML = "☰ Open Query Panel";
                // this.$refs['query_panel_btn'].setAttribute("class", "btn btn-success btn-sm");
                this.$refs['query_tab'].style.height = "0%";
                this.$refs['map'].style.height = "100%";
                this.$refs['map'].style.bottom = "0%";
                this.$refs['query_tab'].style.visibility = "hidden";
                // this.$refs['table_data'].style.left = '0%';
                this.buttonShowQuery = this.$t("Maps.OpenQuery")

                this.map.updateSize();
                this.mapPDF.updateSize();
            }
        },
        showHideLegend(){
            if (this.$refs['legend'].style.visibility == "hidden") {
                this.getLegend()
                this.$refs['legend'].style.visibility = "visible";
                this.$refs['legend'].style.width = "15%";
                this.$refs['legend'].style.height = "25%";
                // this.$refs['map'].style.width = "100%";
                // this.$refs['map'].style.right = "15%";

                this.$refs['legend'].style.right = '1%';
                this.buttonShowLegend = this.$t("Maps.HideLegend")

                this.map.updateSize()
                this.mapPDF.updateSize()
            }else{
                this.$refs['legend'].style.width = "0%";
                // this.$refs['map'].style.width = "100%";
                // this.$refs['map'].style.left = "0%";
                this.$refs['legend'].style.visibility = "hidden";
                this.buttonShowLegend = this.$t("Maps.ShowLegend")

                this.map.updateSize();
                this.mapPDF.updateSize();
            }
        },
        openGetInfo() {

            // if(!this.instersectWithAll){
                //     this.intersectWithAll = false
                //     this.$toasted.show(this.$t("Maps.Alert5"), {
                    //         theme: "toasted-primary",
                    //         position: "top-right",
                    //         duration : 5000
                //     });
            // }

            if(this.drawPolygonActivated){
                this.drawPolygonActivated = false

                if(this.draw1){this.map.removeInteraction(this.draw1);}
                if(this.draw1){this.mapPDF.removeInteraction(this.draw1);}
                this.vector1.getSource().clear();
            }

            if (!this.showInfoActivated) {
                this.showInfoActivated = true
                this.buttonShowInfo = this.$t("Maps.CloseInfo")
                // document.getElementById("info_btn").setAttribute("class", "btn btn-danger btn-sm");
                this.map.on('singleclick', this.getInfo);
                this.mapPDF.on('singleclick', this.getInfo);
                this.$toasted.show(this.$t("Maps.Alert2"), {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 5000
                });
                this.cursorStyle = 'pointer'
                this.createGetinfoTooltip();

                let pointerMoveHandler = (evt) => {
                    if (evt.dragging) {
                        return;
                    }

                    let getinfoMsg = 'Click to select feature';

                    // if (this.sketch) {
                    //     let geom = (this.sketch.getGeometry());
                    //     if (geom instanceof Polygon) {

                    //         getinfoMsg = this.continuePolygonMsg;
                    //     } else if (geom instanceof LineString) {
                    //         getinfoMsg = this.continueLineMsg;
                    //     }
                    // }

                    this.showGetinfoTooltip = true;
                    // console.log(this.getinfoTooltip);
                    this.getinfoTooltipElement.innerHTML = getinfoMsg;
                    this.getinfoTooltip.setPosition(evt.coordinate);

                };

                this.map.on('pointermove', pointerMoveHandler);
                this.mapPDF.on('pointermove', pointerMoveHandler);
                this.selectedMenuOption = "Click"

            } else {

                this.map.un('singleclick', this.getInfo);
                this.mapPDF.un('singleclick', this.getInfo);
                this.showInfoActivated = false
                this.buttonShowInfo = this.$t("Maps.GetInfo")
                // document.getElementById("info_btn").setAttribute("class", "btn btn-success btn-sm");
                if (this.popup) {
                    this.popup.hide();
                }
                this.$toasted.show(this.$t("Maps.Alert3"), {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 5000
                });
                this.cursorStyle = 'default'
                if (this.getinfoTooltip) {
                    this.map.removeOverlay(this.getinfoTooltip);
                    this.mapPDF.removeOverlay(this.getinfoTooltip);
                }
                this.selectedMenuOption = ""
            }
        },
        downloadReport(){
            // console.log(this.table_data);
            // let blob = new Blob([Papa.unparse(this.table_data)], { type: 'text/csv;charset=utf-8;' });

            // let link = document.createElement("a");

            // let url = URL.createObjectURL(blob);
            // link.setAttribute("href", url);
            // link.setAttribute("download", 'dataLayers.csv');
            // link.style.visibility = 'hidden';
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
           
            this.generateReport()
        },
        // add wms layer to map on click of button
        async addLayerExternal(){
            this.cadastreActivated = true
            this.loading = true;
            this.removeApplicatedLayers()
            var cadastre = this.optionsArray.filter(f => f.name == this.countrySelected.name)[0]
            MapsDataService.getWMSCadastre(cadastre.id).then(
                async response => {
                    console.log('response addLayerExternal: ', response)
                    console.log('apply catastro layer')
                    console.log('cadastre layers:', this.cadastre_layers)
                    console.log(this.selectedElement)
                    this.selectedElement = this.cadastre_layers[0]
                    let layer_name = this.selectedElement.Name
                    let bounding_box = this.selectedElement.BoundingBox
                    let correct_bb = bounding_box.filter(f => f['@SRS'] == this.projectionBase)[0]
                    console.log(correct_bb)
                    let layer_cadastre = new Image({
                        title: layer_name,
                        typeLayer: 'external',
                        source: new ImageWMS_Source({
                            url: response.data.url,
                            params: {
                                'LAYERS': layer_name,
                                'SRS': correct_bb['@SRS'],
                                'TRANSPARENT': true,
                                'EXCEPTIONS': 'application/vnd.ogc.se_inimage',
                                'VERSION': '1.1.1'
                            },
                            //ratio: 1,
                            //serverType: 'geoserver'
                        }),
                    });
                    let layer_cadastrePDF = new Image({
                        title: layer_name,
                        typeLayer: 'external',
                        source: new ImageWMS_Source({
                            url: response.data.url,
                            params: {
                                'LAYERS': layer_name,
                                'SRS': correct_bb['@SRS'],
                                'TRANSPARENT': true,
                                'EXCEPTIONS': 'application/vnd.ogc.se_inimage',
                                'VERSION': '1.1.1'
                            },
                            //ratio: 1,
                            //serverType: 'geoserver'
                        }),
                    });
                    console.log("THIS IS LAYER_CADASTRE: ", layer_cadastre);
                    console.log('init overlay')
                    this.overlays.getLayers().push(layer_cadastre);
                    this.overlaysPDF.getLayers().push(layer_cadastrePDF);
                    console.log('list all layers')
                    let extent = [parseFloat(correct_bb['@minx']),parseFloat(correct_bb['@miny']),parseFloat(correct_bb['@maxx']),parseFloat(correct_bb['@maxy'])]
                    this.map.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.map.getSize()
                        }
                    );
                    this.mapPDF.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.mapPDF.getSize()
                        }
                    );
                    this.loading = false;
                    this.layerSwitcher.renderPanel();
                    this.modals.catastro = false
                    this.selectedLayerOption = "Catastro"
                    //this.openGetInfo()

                },error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorWMSCadastre')
                        });
                        this.loading = false;
                })


        },
        removeApplicatedLayers(){
            this.overlays.getLayers().getArray().slice().forEach(layer => {

                this.overlays.getLayers().remove(layer);

            });
            this.overlaysPDF.getLayers().getArray().slice().forEach(layer => {

                this.overlaysPDF.getLayers().remove(layer);

            });
        },
        async addLayerGeoserver() {
            this.loading = true;
            this.removeApplicatedLayers()
            await MapsDataService.getWMS().then(
                async response => {
                    console.log(this.selectedElement)
                    let layer_name = this.selectedElement.Name
                    let correct_bb = this.selectedElement.BoundingBox
                    // if(correct_bb['@SRS'] != this.projectionBase){
                    //     correct_bb = this.selectedElement.LatLonBoundingBox
                    // }
                    console.log(correct_bb)
                    //let correct_bb = bounding_box.filter(f => f['@CRS'] == this.projectionBase)[0]
                    // this.legend = response.layerName
                    // this.legend = null
                    let layer_wms = new Image({
                        title: layer_name,
                        typeLayer: 'geoserver',
                        source: new ImageWMS_Source({
                            url: response.data.url,
                            params: {
                                'LAYERS': layer_name,
                                'SRS': correct_bb['@SRS'],
                                'TRANSPARENT': true,
                                'VERSION': '1.1.1'
                            },
                            ratio: 1,
                            serverType: 'geoserver',

                        })

                    });
                    let layer_wmsPDF = new Image({
                        title: layer_name,
                        typeLayer: 'geoserver',
                        source: new ImageWMS_Source({
                            url: response.data.url,
                            params: {
                                'LAYERS': layer_name,
                                'SRS': correct_bb['@SRS'],
                                'TRANSPARENT': true,
                                'VERSION': '1.1.1'
                            },
                            ratio: 1,
                            serverType: 'geoserver',

                        })

                    });

                    let bbox = [parseFloat(correct_bb['@minx']),parseFloat(correct_bb['@miny']),parseFloat(correct_bb['@maxx']),parseFloat(correct_bb['@maxy'])]

                    if(correct_bb['@SRS'] != this.projectionBase){

                        await MapsDataService.getDefinition(correct_bb['@SRS']).then(
                            async response => {
                                console.log(response.data)
                                proj4.defs(correct_bb['@SRS'],response.data.definition);
                                registerOL_Proj(proj4);
                                bbox = transformExtent(bbox, correct_bb['@SRS'], this.projectionBase)
                            },
                            error => {
                                console.log((error.response && error.response.data) || error.message || error.toString())
                                    this.$swal.fire({
                                        icon: 'error',
                                        title: this.$t('Alerts.ErrorDefinition')
                                    });
                            }
                        )

                    }

                    this.overlays.getLayers().push(layer_wms);
                    this.overlaysPDF.getLayers().push(layer_wmsPDF);
                    let extent = bbox
                    console.log(extent)
                    this.map.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.map.getSize()
                        }
                    );
                    this.mapPDF.getView().fit(
                        extent, {
                            duration: 1590,
                            size: this.mapPDF.getSize()
                        }
                    );
                    this.loading = false;
                    this.modals.wms_layers = false
                    this.modals.uploaded_layers = false
                });
            error => {
                console.log((error.response && error.response.data) | error.message || error.toString())
                    this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorWMS')
                        });
                this.loading = false;
            }
            this.layerSwitcher.renderPanel();
            this.layerApplied = true

            this.modals.wms_layers = false;
            this.modals.uploaded_layers = false;
            //this.openGetInfo()
            //this.getLegend();
        },
        getInfo(evt){

            if (this.popup) {
                this.popup.hide();
            }
            if (this.content) {
                this.content = '';
            }

            this.overlays.getLayers().getArray().slice().forEach(layer => {
                console.log(layer.getKeys())
                console.log(layer.get('typeLayer'))
                if(layer.get('typeLayer') == 'geoserver')
                    this.getInfoGeoserver(evt, layer)
                else if(layer.get('typeLayer') == 'external')
                    this.getInfoExternal(evt, layer)


            });
            // this.overlaysPDF.getLayers().getArray().slice().forEach(layer => {
            //     console.log(layer.getKeys())
            //     console.log(layer.get('typeLayer'))
            //     if(layer.get('typeLayer') == 'geoserver')
            //         this.getInfoGeoserver(evt, layer)
            //     else if(layer.get('typeLayer') == 'external')
            //         this.getInfoExternal(evt, layer)


            // });

        },
        usePolygonLayer(){

            if(this.showInfoActivated){
                this.map.un('singleclick', this.getInfo);
                this.mapPDF.un('singleclick', this.getInfo);
                this.showInfoActivated = false
                this.buttonShowInfo = this.$t("Maps.GetInfo")
                // document.getElementById("info_btn").setAttribute("class", "btn btn-success btn-sm");
                if (this.popup) {
                    this.popup.hide();
                }
                this.$toasted.show(this.$t("Maps.Alert3"), {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 5000
                });
                this.cursorStyle = 'default'
                if (this.getinfoTooltip) {
                    this.map.removeOverlay(this.getinfoTooltip);
                    this.mapPDF.removeOverlay(this.getinfoTooltip);
                }
            }

            if(this.drawPolygonActivated){
                this.drawPolygonActivated = false

                if(this.draw1){this.map.removeInteraction(this.draw1);}
                if(this.draw2){this.mapPDF.removeInteraction(this.draw2);}
                this.vector1.getSource().clear();
            }

            if(!this.instersectWithAll){
                this.intersectWithAll = true
                this.$toasted.show(this.$t("Maps.Alert4"), {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 5000
                });
                this.selectedMenuOption = "All"
            }
            else{
                this.intersectWithAll = false
                this.$toasted.show(this.$t("Maps.Alert5"), {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 5000
                });
                this.selectedMenuOption = ""
            }



        },
        async getInfoGeoserver(evt, layer) {

            console.log("Esto es evt: ", evt)
            let coordinate = evt.coordinate;
            let viewResolution = (this.view.getResolution());
            let visibility = layer.getVisible();
            console.log(visibility);
            if (visibility == true) {
                this.loading = true;
                MapsDataService.getWMS().then(
                    async response => {
                        let layer_title = layer.get('title');
                        let projection = layer.get('source').params_.SRS
                        console.log(projection)
                        let wmsSource = new ImageWMS_Source({
                            url: response.data.url,
                            params: {
                                'LAYERS': layer_title,
                                'SRS': projection,
                                'VERSION': '1.1.1'

                            },
                            serverType: 'geoserver',
                            crossOrigin: 'anonymous'
                        });


                        let url = wmsSource.getFeatureInfoUrl(
                            evt.coordinate, viewResolution, projection, {
                                'INFO_FORMAT': 'text/html',
                            }
                        );

                        let url_json = wmsSource.getFeatureInfoUrl(
                            evt.coordinate, viewResolution, projection, {
                                'INFO_FORMAT': 'application/json',
                                'EXCEPTIONS': 'application/vnd.ogc.se_xml',
                                'TYPENAME': layer_title

                            }
                        );

                        let url_parse = new URL(url)
                        let params = url_parse.searchParams;
                        let bbox = params.get('BBOX')
                        console.log(bbox)
                        let bbox4326 = []
                        bbox4326[0] = parseFloat(bbox.split(',')[0])
                        bbox4326[1] = parseFloat(bbox.split(',')[1])
                        bbox4326[2] = parseFloat(bbox.split(',')[2])
                        bbox4326[3] = parseFloat(bbox.split(',')[3])
                        console.log(bbox4326)
                        let bbox3857 = transformExtent(bbox4326, this.projectionBase, projection)
                        console.log(bbox3857);
                        params.set('BBOX',bbox3857)

                        // $.get(url_parse, (data) => {
                        //     this.content += data;
                        //     this.popup.show(evt.coordinate, this.content);
                        //     this.loading = false;
                        // });

                        $.get(url_json, (data) => {
                            if(data.features[0] == undefined){
                                this.loading = false
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('Maps.ClickOut')
                                });
                            }else{

                                console.log(data.features[0].geometry.coordinates)
                                let polygons = data.features[0].geometry.coordinates

                                //let new_polygons = this.resizePolygons(polygons)
                                
                                var new_polygons = []
                                for(var polygon of polygons){
                                    console.log('polygon', polygon)
                                    

                                    for(var coord_array of polygon){
                                        if(coord_array.length > 315){
                                            new_polygons = this.simplifyPolygons(polygons, 0.008)
                                        }
                                        else{
                                            var new_polygon = []
                                            for(var coordinates of coord_array){
                                                var coord_dict = {}
                                                coord_dict.latitude = coordinates[0]
                                                coord_dict.longitude = coordinates[1]
                                                new_polygon.push(coord_dict)
                                            }
                                            new_polygons.push(new_polygon)
                                            console.log(new_polygons)

                                        }
                                    
                                    }
                                    

                                }
                                this.loading = false
                                
                                for(var new_polygon of new_polygons){

                                    
                                    for(var current_polygon of this.currentPolygons){
                                        var repeated_polygon = false
                                        var geom = this.createGeom(current_polygon)
                                        var polygon_obj = new Polygon(geom)
                                        console.log('GEOM',polygon_obj)
                                        var click_coords = transform(evt.coordinate, this.projectionBase, this.projectionBase)
                                        console.log('coordinates',click_coords)
                                        var point_intersects = polygon_obj.intersectsCoordinate(click_coords)
                                        console.log(point_intersects)
                                        repeated_polygon = point_intersects

                                        if(repeated_polygon){
                                            this.currentPolygons.splice(this.currentPolygons.indexOf(current_polygon), 1)
                                            let area = getArea(polygon_obj, {
                                                projection: 'EPSG:4326'
                                            });
                                            this.totalArea -= area
                                            this.overlays.getLayers().getArray().slice().forEach(layer => {
                                                var source = layer.getSource()

                                                if(source instanceof ImageWMS_Source){

                                                    console.log('normal layer')
                                                }
                                                else{
                                                    for(const [key, value] of Object.entries(source.featuresRtree_.items_)){
                                                        console.log(value.value.values_.geometry)
                                                        var polygon_layer = value.value.values_.geometry
                                                        var point_intersects_layer = polygon_layer.intersectsCoordinate(click_coords)
                                                        if(point_intersects_layer){
                                                            this.overlays.getLayers().remove(layer);
                                                            
                                                        }
                                                    }
                                                }
                                            });
                                            this.overlaysPDF.getLayers().getArray().slice().forEach(layer => {
                                                var source = layer.getSource()

                                                if(source instanceof ImageWMS_Source){

                                                    console.log('normal layer')
                                                }
                                                else{
                                                    for(const [key, value] of Object.entries(source.featuresRtree_.items_)){
                                                        console.log(value.value.values_.geometry)
                                                        var polygon_layer = value.value.values_.geometry
                                                        var point_intersects_layer = polygon_layer.intersectsCoordinate(click_coords)
                                                        if(point_intersects_layer){
                                                            this.overlaysPDF.getLayers().remove(layer);
                                                            
                                                        }
                                                    }
                                                }
                                            });
                                            console.log('current polygons', this.currentPolygons)
                                        }
                                    }
                                    

                                    // this.overlays.getLayers().getArray().slice().forEach(layer => {

                                    // console.log(layer)
                                    // var source = layer.getSource()

                                    // if(source instanceof ImageWMS_Source){

                                    //     console.log('normal layer')
                                    // }
                                    // else{
                                    //     for(const [key, value] of Object.entries(source.featuresRtree_.items_)){
                                    //         console.log(value.value.values_.geometry.flatCoordinates)


                                    //         var polygon_compare = []
                                    //         for(var coord of new_polygon){
                                    //             polygon_compare.push(coord.latitude)
                                    //             polygon_compare.push(coord.longitude)
                                    //         }

                                    //         // polygon_compare.pop()
                                    //         // polygon_compare.pop()
                                    //         console.log('comparing')
                                    //         console.log(value.value.values_.geometry.flatCoordinates)
                                    //         console.log(polygon_compare)
                                    //         console.log(JSON.stringify(value.value.values_.geometry.flatCoordinates) === JSON.stringify(polygon_compare))
                                    //         if(JSON.stringify(value.value.values_.geometry.flatCoordinates) === JSON.stringify(polygon_compare)){
                                    //             var i = 0
                                    //             // var first_element = new_polygon[0]
                                    //             // new_polygon.push(first_element)
                                    //             for(var current_polygon of this.currentPolygons){
                                    //                 if(JSON.stringify(current_polygon) === JSON.stringify(new_polygon)){
                                    //                     var geom = this.createGeom(current_polygon)
                                    //                     var polygon_obj = new Polygon(geom)
                                    //                     let area = getArea(polygon_obj, {
                                    //                         projection: 'EPSG:4326'
                                    //                     });
                                    //                     this.totalArea -= area
                                    //                     this.currentPolygons.splice(i, 1);
                                    //                 }
                                    //                 i++
                                    //             }
                                    //             this.overlays.getLayers().remove(layer);
                                    //             repeated_polygon = true
                                    //         }


                                    //     }



                                    // }

                                    // });

                                    if(!repeated_polygon)
                                        this.drawPolygon(new_polygon)


                                    console.log('current polygons: ', this.currentPolygons)


                                }

                            }
                        });


                        // console.log(url);
                    },
                    error => {
                        console.log((error.response && error.response.data) || error.message || error.toString())
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorInfoGeoserver')
                            });
                            this.loading = false;
                    }
                )

            }
            this.loading = false;
        },
        simplifyPolygon(polygon, tolerance){
            var polygon_type = new Polygon(polygon)
            var polygon_simplified = polygon_type.simplify(tolerance)
            console.log(polygon_simplified)
            return polygon_simplified

        },
        simplifyPolygons(polygons, tolerance){
            var new_polygons = []
            for(var polygon of polygons){
                let simplified_polygon = this.simplifyPolygon(polygon, tolerance)
                console.log('polygon', polygon)
                console.log('polygon simplify', simplified_polygon.flatCoordinates)
                var new_polygon = []

                for(var i = 0; i < simplified_polygon.flatCoordinates.length; i = i + 2){
                    var coord_dict = {}
                    coord_dict.latitude = simplified_polygon.flatCoordinates[i]
                    coord_dict.longitude = simplified_polygon.flatCoordinates[i + 1]
                    new_polygon.push(coord_dict)

                    // for(var coordinates of coord_array){
                    //     var coord_dict = {}
                    //     coord_dict.latitude = coordinates[0]
                    //     coord_dict.longitude = coordinates[1]
                    //     new_polygon.push(coord_dict)
                    // }

                }
                new_polygons.push(new_polygon)
                console.log(new_polygons)
                return new_polygons

            }
        },
        resizePolygons(polygons){
            var new_polygons = []
            for(var polygon of polygons){
                let scaled_plygon = this.scalePolygon(polygon, 3.005)
                console.log('polygon', polygon)
                console.log('polygon scaled', scaled_plygon.flatCoordinates)
                var new_polygon = []

                for(var i = 0; i < scaled_plygon.flatCoordinates.length; i = i + 2){
                    var coord_dict = {}
                    coord_dict.latitude = scaled_plygon.flatCoordinates[i]
                    coord_dict.longitude = scaled_plygon.flatCoordinates[i + 1]
                    new_polygon.push(coord_dict)

                    // for(var coordinates of coord_array){
                    //     var coord_dict = {}
                    //     coord_dict.latitude = coordinates[0]
                    //     coord_dict.longitude = coordinates[1]
                    //     new_polygon.push(coord_dict)
                    // }

                }
                new_polygons.push(new_polygon)
                console.log(new_polygons)
                return new_polygons

            }
        },
        async getInfoExternal(evt, layer) {
            console.log("Esto es evt: ", evt)
            let coordinate = evt.coordinate;
            let viewResolution = (this.view.getResolution());

            let visibility = layer.getVisible();
            console.log(visibility);
            if (visibility == true) {
                this.loading = true;
                var cadastre = this.optionsArray.filter(f => f.name == this.countrySelected.name)[0]
                MapsDataService.getWMSCadastre(cadastre.id).then(
                        async response => {
                            console.log(layer)
                            console.log(this.cadastre_layers)
                            let layer_title = layer.get('title');
                            //let projection = layer.get('source').params_.SRS
                            let projection = 'EPSG:3857' // Sustituir por la proyección correspondiente de cada catastro
                            let wmsSource = new ImageWMS_Source({
                                url: response.data.url,
                                params: {
                                    'LAYERS': layer_title,
                                    'SRS': projection,
                                    'VERSION': '1.1.1'
                                },
                                //serverType: 'geoserver',
                                crossOrigin: 'anonymous'
                            });
                            console.log("Esto wa response.data.url en primera llamada:  ", response.data.url);
                            let url = wmsSource.getFeatureInfoUrl(
                                evt.coordinate, viewResolution, projection, {
                                    'INFO_FORMAT': 'text/html'
                                }
                            );

                            let url_parse = new URL(url)
                            let params = url_parse.searchParams;
                            let bbox = params.get('BBOX')
                            console.log(bbox)
                            let bbox4326 = []
                            bbox4326[0] = parseFloat(bbox.split(',')[0])
                            bbox4326[1] = parseFloat(bbox.split(',')[1])
                            bbox4326[2] = parseFloat(bbox.split(',')[2])
                            bbox4326[3] = parseFloat(bbox.split(',')[3])
                            console.log(bbox4326)
                            let bbox3857 = transformExtent(bbox4326, 'EPSG:4326', 'EPSG:3857')
                            console.log(bbox3857);
                            params.set('BBOX',bbox3857)
                            console.log("LLEGO AQUIIIIIIIIIIIIIIIIIIIIIIIIIII");

                            MapsDataService.getInformationExternal(cadastre.id, bbox3857).then(
                                async response => {
                                    console.log(url_parse)
                                    let url_gml = response.data.url

                                    console.log(url_parse.href)
                                    console.log(url_gml)
                                    let ref = ''
                                    await $.get(url_parse, (data) => {
                                        console.log(data)
                                        const parser = new DOMParser();
                                        const htmlDoc = parser.parseFromString(data, 'text/html');
                                        const text = htmlDoc.documentElement.textContent
                                        ref = text.split(':')[1]
                                        console.log(htmlDoc.documentElement.textContent)
                                        // this.content += data;
                                        // this.popup.show(evt.coordinate, this.content);


                                        $.get(url_gml, (data) => {
                                            console.log(data)
                                            let xmlString = new XMLSerializer().serializeToString(data);
                                            console.log(xmlString)
                                            let json_gml = convert.xml2js(xmlString, {compact: true, spaces: 4});
                                            console.log(json_gml)
                                            let parcel_member = undefined
                                            if(json_gml['FeatureCollection']['member'] instanceof Array){
                                                for(let member of json_gml['FeatureCollection']['member']){
                                                    console.log(member)
                                                    console.log(ref)
                                                    if(member['cp:CadastralParcel']['cp:nationalCadastralReference']['_text'] == ref){
                                                        parcel_member = member
                                                    }
                                                }
                                            }

                                            if(!parcel_member)
                                                parcel_member = json_gml['FeatureCollection']['member']
                                                let coord_str = parcel_member['cp:CadastralParcel']['cp:geometry']['gml:MultiSurface']['gml:surfaceMember']['gml:Surface']['gml:patches']['gml:PolygonPatch']['gml:exterior']['gml:LinearRing']['gml:posList']['_text']
                                                console.log(coord_str)
                                                let coord_separate = coord_str.split(' ')
                                                let coord_array = []
                                                let coord = []
                                                let i = 1
                                                for(let semi_coord of coord_separate){
                                                    if(i > 2){
                                                        let new_coord = transform(coord,'EPSG:3857','EPSG:4326' )
                                                        console.log(new_coord)
                                                        coord_array.push(new_coord)
                                                        coord = []
                                                        i = 1
                                                    }
                                                    coord.push(semi_coord)
                                                    i++
                                                }
                                                console.log(coord_array)

                                                var new_polygon = []
                                                for(var coordinates of coord_array){
                                                    var coord_dict = {}
                                                    coord_dict.latitude = coordinates[0]
                                                    coord_dict.longitude = coordinates[1]
                                                    new_polygon.push(coord_dict)
                                                }

                                            
                                    
                                                for(var current_polygon of this.currentPolygons){
                                                    var repeated_polygon = false
                                                    var geom = this.createGeom(current_polygon)
                                                    var polygon_obj = new Polygon(geom)
                                                    console.log('GEOM',polygon_obj)
                                                    var click_coords = transform(evt.coordinate, this.projectionBase, this.projectionBase)
                                                    console.log('coordinates',click_coords)
                                                    var point_intersects = polygon_obj.intersectsCoordinate(click_coords)
                                                    console.log(point_intersects)
                                                    repeated_polygon = point_intersects

                                                    if(repeated_polygon){
                                                        this.currentPolygons.splice(this.currentPolygons.indexOf(current_polygon), 1)
                                                        let area = getArea(polygon_obj, {
                                                            projection: 'EPSG:4326'
                                                        });
                                                        this.totalArea -= area
                                                        this.overlays.getLayers().getArray().slice().forEach(layer => {
                                                            var source = layer.getSource()

                                                            if(source instanceof ImageWMS_Source){

                                                                console.log('normal layer')
                                                            }
                                                            else{
                                                                for(const [key, value] of Object.entries(source.featuresRtree_.items_)){
                                                                    console.log(value.value.values_.geometry)
                                                                    var polygon_layer = value.value.values_.geometry
                                                                    var point_intersects_layer = polygon_layer.intersectsCoordinate(click_coords)
                                                                    if(point_intersects_layer){
                                                                        this.overlays.getLayers().remove(layer);
                                                                        
                                                                    }
                                                                }
                                                            }
                                                        });
                                                        this.overlaysPDF.getLayers().getArray().slice().forEach(layer => {
                                                            var source = layer.getSource()

                                                            if(source instanceof ImageWMS_Source){

                                                                console.log('normal layer')
                                                            }
                                                            else{
                                                                for(const [key, value] of Object.entries(source.featuresRtree_.items_)){
                                                                    console.log(value.value.values_.geometry)
                                                                    var polygon_layer = value.value.values_.geometry
                                                                    var point_intersects_layer = polygon_layer.intersectsCoordinate(click_coords)
                                                                    if(point_intersects_layer){
                                                                        this.overlaysPDF.getLayers().remove(layer);
                                                                        
                                                                    }
                                                                }
                                                            }
                                                        });
                                                        console.log('current polygons', this.currentPolygons)
                                                    }
                                                }


                                                if(!repeated_polygon)
                                                    this.drawPolygon(new_polygon)


                                                console.log('current polygons: ', this.currentPolygons)
                                                
                                                this.loading = false;

                                        });
                                    })
                                },error =>{
                                    console.log((error.response && error.response.data) || error.message || error.toString())
                                        this.$swal.fire({
                                            icon: 'error',
                                            title: this.$t('Alerts.ErrorInfoExternal')
                                        });
                                        this.loading = false;
                                }
                            )
                        },error => {
                            console.log((error.response && error.response.data) || error.message || error.toString())
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('Alerts.ErrorWMSCadastre')
                                });
                                this.loading = false;
                        }
                )}
        },

        epsg4326toEpsg3857(coordinates) {
            let x = pos[0];
            let y = pos[1];
            x = (coordinates[0] * 20037508.34) / 180;
            y =
                Math.log(Math.tan(((90 + coordinates[1]) * Math.PI) / 360)) /
                (Math.PI / 180);
            y = (y * 20037508.34) / 180;
            return [x, y];
        },
        // length function
        formatLength(line) {
            let length = getLength(line, {
                projection: 'EPSG:4326'
            });
            //let length = getLength(line);
            //let length = line.getLength({projection:'EPSG:4326'});

            let output;
            if (length > 100) {
                output = (Math.round(length / 1000 * 100) / 100) +
                    ' ' + 'km';

            } else {
                output = (Math.round(length * 100) / 100) +
                    ' ' + 'm';

            }
            return output;

        },
        // area function
        formatArea(area) {
            // let area = getArea(polygon);
            
            let output;
            if (area > 10000) {
                output = (Math.round(area / 1000000 * 100) / 100) +
                    ' ' + 'km²';
            } else {
                output = (Math.round(area * 100) / 100) +
                    ' ' + 'm²';
            }
            return output;
        },
        createGeom(polygon){
            var geom = [[]]
            for (var coord of polygon){
                var coord_array = []
                coord_array.push(coord.latitude)
                coord_array.push(coord.longitude)
                geom[0].push(coord_array)
            }
            
            return geom
        },
        // clear function
        clearAll() {

            if (this.vector1) {
                this.vector1.getSource().clear();
                //map.removeLayer(geojson);
            }

            if (this.draw1) {
                this.map.removeInteraction(this.draw1);
            }
            if (this.draw2) {
                this.mapPDF.removeInteraction(this.draw2);
            }
            this.$refs['map'].style.height = "100%";
            this.$refs['table_data'].style.height = "0%";
            this.map.updateSize();
            this.mapPDF.updateSize();

            this.legend = [];

            if (this.array_geojson.length > 0){
                for(let geojson of this.array_geojson){
                    geojson.getSource().clear();
                    this.map.removeLayer(geojson);
                    this.mapPDF.removeLayer(geojson);
                    console.log('geojson', geojson)
                }
            }

            for(var polygon of this.currentPolygons){
                var geom = this.createGeom(polygon)
                var polygon_obj = new Polygon(geom)
                let area = getArea(polygon_obj, {
                    projection: 'EPSG:4326'
                });
                this.totalArea -= area

            }
            // if (this.geojson) {
            //     this.geojson.getSource().clear();
            //     this.map.removeLayer(this.geojson);
            // }

            if (this.selectedFeature) {
                this.selectedFeature.setStyle();
                this.selectedFeature = undefined;
            }
            if (this.popup) {
                this.popup.hide();
            }
            // Recoloca mapa al hacer clear
            // this.map.getView().fit([65.90, 7.48, 98.96, 40.30], {
            //     duration: 1590,
            //     size: this.map.getSize()
            // });


            // document.getElementById("query_panel_btn").innerHTML = "☰ Open Query Panel";
            // document.getElementById("query_panel_btn").setAttribute("class", "btn btn-success btn-sm");


            this.downloadAvailable = false;
/*
            this.$refs['query_tab'].style.width = "0%";
            //this.$refs['map'].style.width = "100%";
            //this.$refs['map'].style.left = "0%";
            this.$refs['query_tab'].style.visibility = "hidden";
            this.$refs['table_data'].style.left = "0%";
            // LINEAS PARA EL SACAR MENU DEL MAPA
            this.mapElement.style.top = "112px";
            this.mapElement.style.height = "716px";


            // this.$refs['legend_btn'].innerHTML = "☰ Show Legend";
            this.$refs['legend'].style.width = "0%";
            this.$refs['legend'].style.visibility = "hidden";
            this.$refs['legend'].style.height = "0%";


            this.map.un('singleclick', this.getInfo);
            // this.map.un('singleclick', this.highlight);
            // document.getElementById("info_btn").innerHTML = "☰ Activate GetInfo";
            // document.getElementById("info_btn").setAttribute("class", "btn btn-success btn-sm");
            this.map.updateSize();
*/


            this.overlays.getLayers().getArray().slice().forEach(layer => {

                this.overlays.getLayers().remove(layer);

            });

            this.overlaysPDF.getLayers().getArray().slice().forEach(layer => {

                this.overlaysPDF.getLayers().remove(layer);

            });

            this.layerSwitcher.renderPanel();

            if (this.draw) {
                this.map.removeInteraction(this.draw)
                this.mapPDF.removeInteraction(this.draw)
            };
            if (this.vectorLayer) {
                this.vectorLayer.getSource().clear();
            }
            this.map.removeOverlay(this.helpTooltip);
            this.mapPDF.removeOverlay(this.helpTooltip);

            if (this.showMeasureToolTip) {
                let elem = document.getElementsByClassName("tooltip tooltip-static");
                //$('#measure_tool').empty();

                //alert(elem.length);
                for (let i = elem.length - 1; i >= 0; i--) {

                    elem[i].remove();
                    //alert(elem[i].innerHTML);
                }
            }
            this.layerApplied = false
            this.isDropdownDisabled = false
            this.cadastreActivated = false
            this.selectedMenuOption = null
            this.selectedLayerOption = null
            console.log(this.isDropdownDisabled);
        },
        customScale(){
            let resolution = this.map.getView().get('resolution');
            let resolution2 = this.mapPDF.getView().get('resolution');

            let units = this.map.getView().getProjection().getUnits();
            let units2 = this.mapPDF.getView().getProjection().getUnits();

            let dpi = 25.4 / 0.28;
            let mpu = ol.proj.Units.METERS_PER_UNIT[units];
            let mpu2 = ol.proj.Units.METERS_PER_UNIT[units2];
            //alert(resolution);
            let scale = resolution * mpu * 39.37 * dpi;
            let scale2 = resolution2 * mpu2 * 39.37 * dpi;
            //alert(scale);
            if (scale >= 9500 && scale <= 950000) {
                scale = Math.round(scale / 1000) + "K";
            } else if (scale >= 950000) {
                scale = Math.round(scale / 1000000) + "M";
            } else {
                scale = Math.round(scale);
            }
            document.getElementById('scale_bar1').innerHTML = "Scale = 1 : " + scale;

            this.scale();

            this.map.getView().on('change:resolution', scale);
            this.mapPDF.getView().on('change:resolution', scale);
        },
        getLegend(){
            this.legend = []
            this.overlays.getLayers().getArray().slice().forEach(layer => {

                console.log(layer)
                this.loading = true;
                MapsDataService.getLegend(layer.get('title')).then(
                    async response => {
                        console.log(response.data)
                        this.legend.push({url: response.data.url, title: layer.get('title')})
                        this.loading = false;
                    },
                    error => {
                        console.log((error.response && error.response.data) || error.message || error.toString())
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorLegend')
                            });
                        this.loading = false;
                    }
                )

            });
            this.overlaysPDF.getLayers().getArray().slice().forEach(layer => {

                console.log(layer)
                this.loading = true;
                MapsDataService.getLegend(layer.get('title')).then(
                    async response => {
                        console.log(response.data)
                        this.legend.push({url: response.data.url, title: layer.get('title')})
                        this.loading = false;
                    },
                    error => {
                        console.log((error.response && error.response.data) || error.message || error.toString())
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorLegend')
                            });
                        this.loading = false;
                    }
                )

            });
            // this.legend();
        },
        attributeDropdown(){
            $(function() {
                $("#layer").change(function() {
                    let attributes = document.getElementById("attributes");
                    let length = attributes.options.length;
                    for (i = length - 1; i >= 0; i--) {
                        attributes.options[i] = null;
                    }

                    let value_layer = $(this).val();


                    attributes.options[0] = new Option('Select attributes', "");
                    //  alert(url);

                    $(document).ready(function() {
                        $.ajax({
                            type: "GET",
                            url: "http://localhost:8084/geoserver/wfs?service=WFS&request=DescribeFeatureType&version=1.1.0&typeName=" + value_layer,
                            dataType: "xml",
                            success: function(xml) {

                                let select = $('#attributes');
                                //let title = $(xml).find('xsd\\:complexType').attr('name');
                                //  alert(title);
                                $(xml).find('xsd\\:sequence').each(function() {

                                    $(this).find('xsd\\:element').each(function() {
                                        let value = $(this).attr('name');
                                        //alert(value);
                                        let type = $(this).attr('type');
                                        //alert(type);
                                        if (value != 'geom' && value != 'the_geom') {
                                            select.append("<option class='ddindent' value='" + type + "'>" + value + "</option>");
                                        }
                                    });
                                });
                            }
                        });
                    });
                });
            });
        },
        operatorCombo(){
            $(function() {
                $("#attributes").change(function() {

                    let operator = document.getElementById("operator");
                    let length = operator.options.length;
                    for (i = length - 1; i >= 0; i--) {
                        operator.options[i] = null;
                    }

                    let value_type = $(this).val();
                    // alert(value_type);
                    let value_attribute = $('#attributes option:selected').text();
                    operator.options[0] = new Option('Select operator', "");

                    if (value_type == 'xsd:short' || value_type == 'xsd:int' || value_type == 'xsd:double' || value_type == 'xsd:long') {
                        let operator1 = document.getElementById("operator");
                        operator1.options[1] = new Option('Greater than', '>');
                        operator1.options[2] = new Option('Less than', '<');
                        operator1.options[3] = new Option('Equal to', '=');
                        operator1.options[4] = new Option('Between', 'BETWEEN');
                    } else if (value_type == 'xsd:string') {
                        let operator1 = document.getElementById("operator");
                        operator1.options[1] = new Option('Like', 'ILike');

                    }

                });
            });
        },
        layerDropdownDrawQuery(){
            $(document).ready(function() {
                $.ajax({
                    type: "GET",
                    url: "http://localhost:8084/geoserver/wfs?request=getCapabilities",
                    dataType: "xml",
                    success: function(xml) {
                        let select = $('#layer1');
                        $(xml).find('FeatureType').each(function() {
                            //let title = $(this).find('ows:Operation').attr('name');
                            //alert(title);
                            let name = $(this).find('Name').text();
                            //select.append("<option/><option class='ddheader' value='"+ name +"'>"+title+"</option>");
                            $(this).find('Name').each(function() {
                                let value = $(this).text();
                                select.append("<option class='ddindent' value='" + value + "'>" + value + "</option>");
                            });
                        });
                        //select.children(":first").text("please make a selection").attr("selected",true);
                    }
                });
            });
        },
    }
}
</script>



<style scoped>
 html,
 /* body {
     height: 100%;
     padding: 0;
     margin: 0;
     font-family: arial;

 } */

 #map {
     position: absolute;
     left: 0%;
     width: 100%;
     height: 100%;
 }

 /* #query_tab {
     position: absolute;
     padding: 0.5% 0%;
     left: 0%;
     width: 21%;
     height: 100%;
     background-color: white !important;

 } */

 #query_tab {
     position: absolute;
     padding: 0.5% 0%;
     left: 0%;
     width: 0%;
     height: 100%;
     visibility: hidden;

 }

 #legend {
     z-index: 11;
     padding: 2px 4px;
     border: 1px solid grey;
     position: absolute;
     bottom: 7%;
     height: 0%;
     overflow: scroll;
     width: 0%;
     right: 0%;
     z-index: 999;
     background-color: #ffffff;
     font-weight: bold;
     visibility: hidden;

 }

 #layer {
     position: relative;
     width: 85%;
 }

 #attributes {
     position: relative;
     width: 85%;
 }

 #operator {
     position: relative;
     width: 85%;
 }

 #value {
     position: relative;
     width: 85%;
 }

 #layer1 {
     position: relative;
     width: 85%;
 }

 #draw_type {
     position: relative;
     width: 85%;
 }


 #table_data {
     position: absolute;
     bottom: 0%;
     overflow: scroll;
     left: 0%;
     right: 0%;
     height: 0%;
 }
/*
 #table {
     white-space: nowrap;
     grid-template-areas: "head-fixed""body-scrollable";
 } */

/*
 #table th {
     position: -webkit-sticky;
     position: sticky;
     top: 0px;
     background-color: rgb(122, 209, 100);
 } */

 .buttonRow{
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    position: fixed;
    z-index: 600;
    width: 86%;
    top: 60px;
    /* margin-left: 8%; */
    /* margin-right: 10% !important; */
    /* background-color: #5f6378; */
 }

 .divRow{
    background-color: #5d6176;
    /* opacity: 90%; */
    display: flex;
    position: fixed;
    z-index: 600;
    width: 100%;
    height: 52px;
    top: 60px;
    /* margin-left: 8%;
    margin-right: 10% !important; */
 }

 .menuBar{
    position: fixed;
    z-index: 600;
    top: 70px;
    margin-left: 2%;
    margin-right: 10% !important;
 }

 #wms_layers_btn {

 }

 #query_panel_btn {

 }

 #legend_btn {

 }

 #download_btn {
    position: absolute;
    z-index: 600;
    bottom: 2%;
    right: 1%;
 }


 /* #table_wms_layers {
     white-space: nowrap;
     grid-template-areas: "head-fixed""body-scrollable";
 }

 #table_wms_layers th {
     position: -webkit-sticky;
     position: sticky;
     top: 0px;
     background-color: rgb(122, 209, 100);
 } */


 /* .modal-dialog {
     max-width: 60% !important;
     height: 60% !important;
     top: 18% !important;
 } */

 /*.form-select {
     left: 0%;
     width: 85% !important;

 }*/

 /*.form-control {
     left: 0%;
     width: 85% !important;

 }*/

 #clear_btn {

 }

 #info_btn {

 }

 #measuretype {

 }

 #scale_bar {

     position: absolute;
     z-index: 500;
     bottom: 0%;
     left: 50%;
 }

 #scale_bar1 {
     font-weight: bold;
     position: absolute;
     z-index: 500;
     top: 22.875em;
     left: 0.5em;

 }

 ::v-deep .ol-popup-content {

     max-width: 500px;
 }

 .ol-scale-step-text {
 }

 .ol-scale-text {
     font-weight: bold;
 }

 .layer-switcher.shown {
     max-height: 465px;
 }

 .ol-control button {
     background-color: rgba(40, 40, 40, 0.8) !important;
 }

 .ol-control button:hover {
     background-color: rgba(40, 40, 40, 1) !important;
 }

 .ol-mouse-position {
     top: 95%;
     left: 0.5%;
     position: absolute;
     font-size: 14px;
     font-weight: bold;
 }

 .ol-zoom-extent {
     top: 3.8em;
     left: 0.5em;
     position: absolute;
 }

 .ol-zoomslider {
     top: 5.8em;
     left: 0.5em;
     position: absolute;
     height: 200px;
 }

 .ol-geocoder.gcd-gl-container {
     top: 21.875em;
     left: 0.5em;
     position: absolute;
 }



 .tooltip {
     position: fixed;
     background: rgba(0, 0, 0, 0.5);
     border-radius: 4px;
     color: white;
     padding: 4px 8px;
     opacity: 0.7;
     white-space: nowrap;
     left: 10px;
 }

 .tooltip-measure {
     opacity: 1;
     font-weight: bold;
 }

 .tooltip-static {
     background-color: #ffcc33;
     color: black;
     border: 1px solid white;
 }

 .tooltip-measure:before,
 .tooltip-static:before {
     border-top: 6px solid rgba(0, 0, 0, 0.5);
     border-right: 6px solid transparent;
     border-left: 6px solid transparent;
     content: "";
     position: absolute;
     bottom: -6px;
     margin-left: -7px;
     left: 50%;
 }

 .tooltip-static:before {
     border-top-color: #ffcc33;
 }

 .tab-active{
    background-color: #5f6378 !important;
    color: white !important;
 }
::v-deep .ol-unselectable.ol-control.layer-switcher.layer-switcher-group-select-style-children.layer-switcher-activation-mode-click.activationModeClick.shown {
    margin-top: 100px;
    opacity: 85%;
}

/* ::v-deep .group{
    align-items: center;
} */

/* ::v-deep .group > label{
    margin-bottom: 0px;
} */

::v-deep .layer-switcher li input{
    position: absolute;
    left: 1.2em;
    height: 1em;
    width: 1em;
    font-size: 1em;
    top: 0.3em;
}

::v-deep .dz-preview{
    min-width: 100px !important;
    max-width: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
}

::v-deep .dz-image{
    min-width: 100px !important;
    max-width: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
}


::v-deep .dz-progress{
    visibility: hidden;
}

/* ::v-deep .gcd-gl-btn::after{
    display: none;
} */

::v-deep #gcd-container{
    margin-left: 27px;
    /* margin-left: 27px;
    margin-top: -55px; */
}

::v-deep .gcd-gl-control.ol-control{
    border-radius: 20px;
    background-color: transparent;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
}

::v-deep .gcd-gl-input{
    box-shadow: none;
    border-color: #888888;
}

::v-deep .ol-geocoder .gcd-gl-input:focus {
    box-shadow: inset 0 0 0 1px #888888, inset 0 0 5px #888888;
}

::v-deep .ol-zoom.ol-unselectable.ol-control{
    margin-top: 105px;
    margin-left: 30px;
    /* margin-top: 45px;
    margin-left: 30px; */
}

::v-deep #gcd-button-control{
    border-radius: 20px;
    min-height: 25px;
    min-width: 25px;
    transform: rotate(-45deg)!important;
}

::v-deep .ol-geocoder .gcd-gl-btn:after {
    /* max-width: 10px; */
    /* max-height: 20px; */
    font-size: 19px;
    /* transform: rotate(-45deg)!important; */
    content: "\26B2";
}
::v-deep .ol-zoomslider.ol-unselectable.ol-control{
    margin-left: 30px;
    margin-top: 100px;
    /* margin-left: 30px;
    margin-top: 40px; */
}

::v-deep .ol-zoom-extent.ol-unselectable.ol-control{
    margin-left: 30px;
    margin-top: 100px;
    /* margin-left: 30px;
    margin-top: 40px; */
}

::v-deep .gcd-gl-input{
    border-radius: 20px;
    opacity: 85%;
    padding: 2px;
    min-height: 30px;
    margin-left: 10px;
}


@media (max-width: 1701px) and (max-height: 836px){
    .buttonRow{
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media (max-width: 1520px){
    ::v-deep .ol-unselectable.ol-control.layer-switcher.layer-switcher-group-select-style-children.layer-switcher-activation-mode-click.activationModeClick.shown {
        margin-top: 150px;
    }
}

@media (max-width: 857px){
    ::v-deep .ol-unselectable.ol-control.layer-switcher.layer-switcher-group-select-style-children.layer-switcher-activation-mode-click.activationModeClick.shown {
        margin-top: 200px;

    }
}
@media (max-width: 629px){
    ::v-deep .ol-unselectable.ol-control.layer-switcher.layer-switcher-group-select-style-children.layer-switcher-activation-mode-click.activationModeClick.shown {
        margin-top: 250px;

    }
}

@media (max-width: 991px){
    .divRow{
        margin-top: -10px;
        min-height: 62px;
    }
    .buttonRow{
        margin-top: -10px;
    }
}

@media (max-width: 895px){
    .divRow{
        margin-top: -10px;
        min-height: 108px;
    }
}
@media (max-width: 538px){
    .divRow{
        margin-top: -10px;
        min-height: 168px;
    }
}

.initialCard{
    max-width: 40%;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

::v-deep .ol-popup-content p{
    font-size: 10px !important;
}

/* .cover{
  padding-top: 50px;
  padding-bottom: 50px;
  background: #FF4945;
}

.expand-transition {
  transition: all 5s ease;
}

.expand-enter, .expand-leave {
  height: 0;
  opacity: 0;
} */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-menu button {
  padding: 10px;
  border: none;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #f1f1f1;
}

::v-deep .ol-unselectable.ol-control.layer-switcher.layer-switcher-group-select-style-children.layer-switcher-activation-mode-click.activationModeClick{
    margin-top: 100px!important;
    display: none;
}

.tooltipLayers {
  position: relative;
  cursor: pointer;
}

::v-deep .dropdown-menu{
    max-height: 283px !important;
    overflow: auto !important;
}

/**/

::v-deep .layer-switcher-base-group{
    display: none;
}

/* .tooltipLayers:hover::before {
  content: attr(title);
  position: absolute;
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0.9;
  pointer-events: none;
} */

.selected-option {
    background-color: #cccccc;
  }

.focus-input{
    border: 1px solid #5D6176 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: 0 !important;
    color: #2c2c2c !important;
}

@media (max-width: 923px){
    .initialCard{
        margin-left: 30%;
    }
}

@media (max-width: 632px){
    .initialCard{
        margin-left: 40%;
    }
}

@media (max-width: 563px){
    .initialCard{
        margin-left: 50%;
    }
}
</style>